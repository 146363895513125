import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  RenderTask,
  RenderTaskOptions,
} from "features/draw-call/core/render-task/render-task";
import { Texture } from "features/draw-call/core/texture";
import { svgToImage } from "features/draw-call/ext/svg-to-image";
import { textureFragmentShader300 } from "../shaders/texture-fragment-shader";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { FC, useMemo } from "react";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { mat4, vec3 } from "gl-matrix";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { PI } from "math";
import { modelMatrixVertexShader300 } from "../shaders/model-matrix-vertex-shader";
import { UniformValues } from "features/draw-call/core/types";
import {
  NestedRenderTask,
  NestedRenderTaskOptions,
} from "features/draw-call/core/render-task/nested-render-task";

export interface SVGRendererOptions extends RenderTaskOptions {
  svg: string;
  program: ShaderProgram;
  camera: OrbitCamera;
}

export class SVGRenderer extends RenderTask {
  svg: string;
  camera: OrbitCamera;
  status: "awaiting" | "ready" = "awaiting";
  texture: Texture | null = null;
  modelMatrix;
  _modelMatrix; // resulting model matrix
  constructor(gl: WebGL2RenderingContext, options: SVGRendererOptions) {
    super(gl, options);
    this.svg = options?.svg || "";
    this.camera = options.camera;
    this.modelMatrix = mat4.identity(mat4.create());
    this._modelMatrix = mat4.create();
    this.shaderPrograms.main = options.program;

    // generate a texture from an svg
    // document.fonts.forEach(function (fontFace) {
    // console.log("Font family:", fontFace.family);
    // console.log("Font style:", fontFace.style);
    // console.log("Font weight:", fontFace.weight);
    // });
    svgToImage(this.svg).then(image => {
      this.texture = new Texture(gl, { pixels: image, flip: true });
      this.status = "ready";
    });

    this.geometries.main = new QuadGeometry(gl);
    /* unnecessary
    const m = mat4.create();
    rotationTranslationScaleMatrix(m, [0, PI, 0], [0, 0, 0], [1, 1, 1]);
    this.geometries.main.vertexBuffers.POSITION.applyMat4Position(m);
    this.geometries.main.vertexBuffers.NORMAL.applyMat4Direction(m);
    */
  }
  updateTexture(svg: string) {
    this.status = "awaiting";
    svgToImage(svg).then(image => {
      this.texture = new Texture(this.gl, { pixels: image, flip: true });
      this.status = "ready";
    });

    // this.geometries.main = new QuadGeometry(this.gl);
    /* unnecessary
    const m = mat4.create();
    rotationTranslationScaleMatrix(m, [0, PI, 0], [0, 0, 0], [1, 1, 1]);
    this.geometries.main.vertexBuffers.POSITION.applyMat4Position(m);
    this.geometries.main.vertexBuffers.NORMAL.applyMat4Direction(m);
    */
  }

  updateSVG(newSVG: string) {
    this.svg = newSVG;
    this.updateTexture(newSVG);
  }

  render(u?: UniformValues): void {
    super.render();
    if (!this.texture || !this.shaderPrograms.main) return;
    if (u?.modelMatrix) {
      // multiply the model matrix by the given model matrix
      mat4.multiply(this._modelMatrix, u.modelMatrix as mat4, this.modelMatrix);
    } else mat4.copy(this._modelMatrix, this.modelMatrix);
    // this.gl.depthMask(false);
    this.geometries.main.drawCall(this.shaderPrograms.main, {
      ...u,
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      modelMatrix: this._modelMatrix,
      u_texture: this.texture,
    });
    // this.gl.depthMask(true);
  }
}
