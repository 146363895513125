import React, {
  FC,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { RouteParams } from "features/ui";
import { useNavigate, useLocation } from "react-router-dom";

import { InputSearch } from "../input-search";
import { ProductList } from "./product-list";

import { ReactComponent as HomeIcon } from "../../icons/Home.svg";
import { ReactComponent as UserIcon } from "../../icons/User.svg";
import { ReactComponent as LikeIcon } from "../../icons/Like.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as PromoPriceIcon } from "../../icons/PromoPrice.svg";

interface ProductsPageProps {
  isOpen: boolean;
  onClose: () => void;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const ProductsPage: FC<ProductsPageProps> = ({
  isOpen,
  onClose,
  value,
  onChange,
  // onKeyPress
}) => {
  const { slug, sectorId, clusterId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const onOfLayer = (param: string) => {
    const params = new URLSearchParams(location.search);
    const currentLayers = params.getAll("layer");

    if (currentLayers.includes(param)) {
      const updatedLayers = currentLayers.filter(layer => layer !== param);
      params.delete("layer");
      updatedLayers.forEach(layer => params.append("layer", layer));
    } else {
      params.append("layer", param);
    }

    navigate({ pathname: location.pathname, search: params.toString() });
  };

  return (
    <div
      className="flex flex-col h-screen"
      style={{ background: "transparent" }}
    >
      <div
        className={`flex flex-1 relative transition-transform duration-250 ${
          isOpen ? "-translate-x-full" : "translate-x-0"
        }`}
        style={{ background: "transpaent" }}
      >
        <div
          className="p-4 absolute top-0 left-0 right-0 z-30"
          style={{ background: "transparent" }}
        >
          <InputSearch
            glow={false}
            type={isOpen ? "menu" : "search"}
            value={value}
            onChange={onChange}
            // onKeyPress={onKeyPress}
            onClose={onClose}
            isOpen={isOpen}
          />
        </div>

        <div
          className="w-[70px] mt-[70px] flex flex-col items-center py-4 z-20"
          style={{ background: "transparent" }}
        >
          <button className="mb-6" onClick={()=>navigate('')}>
            <HomeIcon className="w-9 h-9 transition duration-300 ease-in-out hover:scale-125 hover:fill-[#836aed]" />
          </button>
          <button className="mb-6" onClick={()=>navigate('profile')}>
            <UserIcon className="w-9 h-9 transition duration-300 ease-in-out hover:scale-125 hover:fill-[#836aed]" />
          </button>
          <button className="mb-6"  onClick={()=>navigate(`${slug}/favorites`)}>
            <LikeIcon className="w-9 h-9 transition duration-300 ease-in-out hover:scale-125 hover:fill-[#836aed]" />
          </button>
          <button className="mb-6">
            <BinIcon className="w-9 h-9 transition duration-300 ease-in-out hover:scale-125 hover:fill-[#836aed]" />
          </button>

          {clusterId && (
            <button className="mb-6" onClick={() => onOfLayer("PROMO_PRICE")}>
              <PromoPriceIcon className="w-9 h-9 transition duration-300 ease-in-out hover:scale-125 hover:fill-[#836aed]" />
            </button>
          )}
        </div>

        <div className="flex-1 relative" style={{ background: "transparent" }}>
          {/* <div className={classNames(
                        'absolute inset-0 bg-white overflow-y-auto transition-opacity duration-300 bg-transparent',
                        { 'opacity-0 pointer-events-none': !isOpen }
                    )}>
                        {isOpen && (
                            <ProductList />
                        )}
                    </div> */}
        </div>
      </div>
    </div>
  );
};
