import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "../style.css";
import { api } from "./api";

import { ReactComponent as IrbiIcon } from "features/ui/icons/vertical-irbi.svg";

const TIMEOUT = 10000; // 10 seconds timeout

const fetchWithTimeout = (url, options, timeout = TIMEOUT) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), timeout)
    ),
  ]);
};

export const setAuthenticatedCookie = (value) => {
  const cookieValue = value ? 'true' : 'false';
  const expires = 'expires=' + new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toUTCString(); // 30 days
  document.cookie = `isAuthenticated=${cookieValue}; ${expires}; path=/`;
}

export const getAuthenticatedCookie = () => {
  const cookieValue = document.cookie.match(/isAuthenticated=([^;]*)/);
  return cookieValue ? cookieValue[1] === 'true' : null;
}

// fuction open modal window
export const openAuthModal = (slug, navigate) => {
  const userConfirmed = window.confirm("You are not authenticated. Would you like to log in?");
  
  if (userConfirmed) {
    setAuthenticatedCookie(false);
    navigate("/auth");
  } else {
    if (window.location.pathname.includes("favorites")) {
      navigate(`/${slug}`);
    }
  }
};

export const LoginForm = ({ onSwitch, setIsAuthenticated, showNotification }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    
    const redirectTo = new URLSearchParams(location.search).get('redirectTo') || '/';
  
    try {
      const response = await fetchWithTimeout("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
  
      const data = await response.json();
  
      if (response.status === 200 || data.authenticated) {
        showNotification("Successfully logged in!");
        setTimeout(() => {
          setAuthenticatedCookie(true);
          setIsAuthenticated(true);
          navigate(redirectTo);  // Redirect to specified URL
        }, 1000);
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      setError(error.message || "Server error or slow response");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md p-8 space-y-14 bg-white shadow-lg rounded-lg transform transition-transform duration-500">
      <h2 className="text-2xl font-bold text-center">LOGIN</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        {error && (
          <div className="absolute top-[80px] text-red-500 text-sm text-center">
            {error}
          </div>
        )}
        <div>
          <label htmlFor="username" className="sr-only">
            Username
          </label>
          <input
            type="text"
            id="username"
            placeholder="Username"
            className="w-full px-3 py-2 border rounded-lg"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Password"
            className="w-full px-3 py-2 border rounded-lg"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-between items-center">
          <a href="#" className="text-sm text-blue-600">
            Forgot password?
          </a>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-white bg-[#826AED] hover:bg-[#CEEC98] rounded-lg disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? "Logging in..." : "LOGIN"}
        </button>
      </form>
      <div className="text-center">
        <span>Don't have an account?</span>{" "}
        <button
          onClick={onSwitch}
          className="text-blue-600 hover:underline focus:outline-none"
        >
          Register
        </button>
      </div>
    </div>
  );
};

// RegisterForm component
const RegisterForm = ({ onSwitch, setIsAuthenticated, showNotification }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRegister = async e => {
    e.preventDefault();
    if (password === passwordConf) {
      setError("");
      setIsLoading(true);
      
      const redirectTo = new URLSearchParams(location.search).get('redirectTo') || '/';
  
      try {
        const response = await fetchWithTimeout("/api/auth/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, email, password }),
        });
  
        const data = await response.json();
  
        if (response.status === 200 || data.message === "User registered successfully") {
          showNotification("Successfully registered!");
          setTimeout(() => {
            setAuthenticatedCookie(true);
            setIsAuthenticated(true);
            navigate(redirectTo);  // Redirect to specified URL
          }, 1000);
        } else {
          setError("Registration failed");
        }
      } catch (error) {
        setError(error.message || "Server error or slow response");
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("Passwords don't match");
    }
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handlePasswordConfChange = e => {
    setPasswordConf(e.target.value);
  };

  return (
    <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-lg transform transition-transform duration-500">
      <h2 className="text-2xl font-bold text-center">REGISTER</h2>
      <form className="space-y-4" onSubmit={handleRegister}>
        {error && (
          <div className="absolute top-[64px] text-red-500 text-sm text-center">
            {error}
          </div>
        )}
        <div>
          <label htmlFor="username" className="sr-only">
            Username
          </label>
          <input
            type="text"
            id="username"
            placeholder="Username"
            className="w-full px-3 py-2 border rounded-lg"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            className="w-full px-3 py-2 border rounded-lg"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Password"
            className="w-full px-3 py-2 border rounded-lg"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div>
          <label htmlFor="confirm-password" className="sr-only">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirm-password"
            placeholder="Confirm Password"
            className="w-full px-3 py-2 border rounded-lg"
            value={passwordConf}
            onChange={handlePasswordConfChange}
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-white bg-[#826AED] hover:bg-[#CEEC98] rounded-lg"
          disabled={isLoading}
        >
          {isLoading ? "Registering..." : "REGISTER"}
        </button>
      </form>
      <div className="text-center">
        <span>Already have an account?</span>{" "}
        <button
          onClick={onSwitch}
          className="text-blue-600 hover:underline focus:outline-none"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export const Mobile = ({ isLogin, switchForm, setIsAuthenticated, showNotification }) => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-[#826AED] relative">
      <div className="w-full max-w-md md:max-w-4xl h-[480px] md:h-auto bg-purple-600 rounded-lg flex md:flex-row shadow-md">
        <div
          className={`absolute bottom-[-25%] w-full h-full bg-[#826AED] flex justify-center items-center transition-transform duration-500 
              ${isLogin ? "right-0" : "left-0"} transform ${isLogin ? "translate-y-0" : "-translate-y-[520px]"
            }`}
        >
          <div className="relative bg-[#826AED] flex justify-center items-center bottom-[-130px]">
            <IrbiIcon className="w-[400px] h-[200px]" />
          </div>
        </div>

        <div
          className={`absolute w-full h-full bg-transpatent transform transition-transform duration-500 ${isLogin ? "translate-y-0" : "-translate-y-full"
            }`}
        >
          <LoginForm
            onSwitch={switchForm}
            setIsAuthenticated={setIsAuthenticated}
            showNotification={showNotification}
          />
        </div>

        <div
          className={`absolute bottom-0 w-full transform transition-transform duration-500 ${isLogin ? "translate-y-full" : "translate-y-0"
            }`}
        >
          <RegisterForm
            onSwitch={switchForm}
            setIsAuthenticated={setIsAuthenticated}
            showNotification={showNotification}
          />
        </div>
      </div>
    </div>
  );
};

export const Desktop = ({ isLogin, switchForm, setIsAuthenticated, showNotification }) => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="relative w-full max-w-4xl h-[420px] bg-[#826AED] rounded-lg overflow-hidden shadow-md">
        <div
          className={`absolute top-0 ${isLogin ? "right-10" : "left-[-200px]"
            } transform transition-transform duration-500 ${isLogin ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <IrbiIcon />
        </div>

        <div
          className={`absolute top-0 left-0 w-1/2 h-full bg-white transform transition-transform duration-500 ${isLogin ? "translate-x-0" : "-translate-x-full"
            }`}
        >
          <LoginForm
            onSwitch={switchForm}
            setIsAuthenticated={setIsAuthenticated}
            showNotification={showNotification}
          />
        </div>

        <div
          className={`absolute top-0 right-0 w-1/2 h-full bg-white transform transition-transform duration-500 ${isLogin ? "translate-x-full" : "translate-x-0"
            }`}
        >
          <RegisterForm
            onSwitch={switchForm}
            setIsAuthenticated={setIsAuthenticated}
            showNotification={showNotification}
          />
        </div>
      </div>
    </div>
  );
};

const Auth = ({ setIsAuthenticated }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [notification, setNotification] = useState("");

  const switchForm = () => {
    setIsLogin(!isLogin);
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  return (
    <>
    <div>
      {isMobile ? (
        <Mobile
          isLogin={isLogin}
          switchForm={switchForm}
          setIsAuthenticated={(value) => {
            setIsAuthenticated(value);
            if (value) showNotification("Successful login");
          }}
          showNotification={showNotification}
        />
      ) : (
        <Desktop
          isLogin={isLogin}
          switchForm={switchForm}
          setIsAuthenticated={(value) => {
            setIsAuthenticated(value);
            if (value) showNotification("Successful login");
          }}
          showNotification={showNotification}
        />
      )}
      {notification && (
        <div className="absolute top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50">
          <h1 className="absolute text-teal-400 text-3xl font-bold top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-900 bg-opacity-50 p-4 rounded">
          {notification}
          </h1>
        </div>
      )}
    </div>
    </>
  );
};

export default Auth;
