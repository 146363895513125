import { GLContext } from "./gl-utils";
import { OutputFramebuffer } from "./types";

// bind a framebuffer to draw to
export function bindOutput(gl: GLContext, output?: OutputFramebuffer | null) {
  if (output) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, output.framebuffer);
    gl.viewport(0, 0, output.width, output.height);
  } else {
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  }
}

// or you can call bindOutput(gl, null)
export function bindScreen(gl: GLContext) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
}
