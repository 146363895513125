import { GLContext } from "features/draw-call/core/gl-utils";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import {
  AttributeLocationCollection,
  OutputFramebuffer,
  UniformValue,
  UniformValues,
  VertexBufferCollection,
} from "features/draw-call/core/types";

// a collection of vertex buffers is called a geometry
export class BufferGeometry {
  gl: GLContext;
  initialized: boolean = false;
  updated: boolean = false;
  vertexBuffers: VertexBufferCollection = {};
  constructor(gl: GLContext) {
    this.gl = gl;
  }
  createVertexBuffers() {
    for (let key in this.vertexBuffers) {
      this.vertexBuffers[key].create();
    }
    this.initialized = true;
  }
  // update attribute buffers
  updateVertexBuffers() {
    if (!this.initialized) this.createVertexBuffers();
    for (let key in this.vertexBuffers) {
      this.vertexBuffers[key].update();
    }

    this.updated = true;
  }
  // enable attributes
  enableAttributes(locations: AttributeLocationCollection) {
    for (let key in locations) {
      if (this.vertexBuffers[key] !== undefined) {
        this.vertexBuffers[key].enableAttribute(locations[key]);
      }
    }
  }
  // free attribute buffers
  freeVertexBuffers() {
    for (let key in this.vertexBuffers) {
      this.vertexBuffers[key].free();
    }
    this.initialized = false;
    this.updated = false;
  }
  // draw
  draw() {}
  // enable attributes and draw
  drawAttributes(locations: AttributeLocationCollection) {
    this.enableAttributes(locations);
    this.draw();
  }
  // draw using a shader program
  drawCall(shaderProgram: ShaderProgram, uniforms?: UniformValues) {
    shaderProgram.prepare(uniforms);
    this.drawAttributes(shaderProgram.attributeLocations);
  }
}
