import { GLTFPostprocessed } from "@loaders.gl/gltf";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import { primitiveToBuffers } from "features/draw-call/ext/glb-primitive";
import { useMemo } from "react";

export const useMemoElementsGeometry = (
  gl: GLContext,
  model: GLTFPostprocessed
) =>
  useMemo(
    () =>
      new ElementsBufferGeometry(
        gl,
        primitiveToBuffers(gl, model.meshes[0].primitives[0])
      ),
    []
  );
