import { GLContext } from "features/draw-call/core/gl-utils";
import { Texture } from "features/draw-call/core/texture";
import { svgDataURL, svgToImage } from "features/draw-call/ext/svg-to-image";

export class DynamicTextureLoader {
  textures: Map<string, Texture> = new Map();
  images: Map<string, HTMLImageElement> = new Map();
  dataURLs: Map<string, string> = new Map();
  pending: Map<string, Promise<HTMLImageElement>> = new Map();
  gl: GLContext;
  constructor(gl: GLContext, fallback?: HTMLImageElement) {
    this.gl = gl;
    if (fallback) {
      this.images.set("fallback", fallback);
    }
  }
  // get a texture from the cache synchronously
  getTextureSync(name: string, fallback: boolean = false): Texture | undefined {
    return (
      this.textures.get(name) ||
      (fallback ? this.textures.get("fallback") : undefined)
    );
  }
  // create a loading promise and store it to the pending map
  loadImage(url: string, name?: string): Promise<HTMLImageElement> {
    const handle = name || url;
    const images = this.images;
    // return early if the image is already loaded
    const image = images.get(handle);
    if (image) {
      return new Promise(resolve => resolve(image));
    }
    const pending = this.pending;
    // return the pending promise if it exists
    const existing = pending.get(handle);
    if (existing) return existing;
    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        pending.delete(handle);
        images.set(handle, image);
        resolve(image);
      };
      image.onerror = () => {
        pending.delete(handle);
        // if there is a fallback image, use it
        const fallback = images.get("fallback");
        console.error(
          `Failed to load image "${url}". Attempting to use fallback`
        );
        if (fallback) {
          images.set(handle, fallback);
          resolve(fallback);
          return;
        }
        reject(new Error(`Failed to load image "${handle}"`));
      };
      image.src = url;
    });
    pending.set(handle, promise);
    return promise;
  }

  // load texture from a remote url
  loadURL(url: string): Promise<Texture> {
    const { textures, gl, pending } = this;
    const tex = textures.get(url);
    // if texture already loaded
    // return it in a microtask
    if (tex) return new Promise<Texture>(resolve => resolve(tex));
    return this.loadImage(url).then(image => {
      const texture = new Texture(gl, { pixels: image, flip: false });
      textures.set(url, texture);
      return texture;
    });
  }
  // load locally generated svg, which can be accessed by name for convenience
  loadSVG(name: string, svg?: string): Promise<Texture> {
    const { textures, gl, pending } = this;
    const tex = textures.get(name);
    if (tex) return new Promise<Texture>(resolve => resolve(tex));
    // throw if there is nothing to cache
    if (!svg)
      throw new Error("Id not found in cache but source svg was not provided");
    let dataUrl = this.dataURLs.get(name);
    if (!dataUrl) {
      dataUrl = svgDataURL(svg);
      this.dataURLs.set(name, dataUrl);
    }
    return this.loadImage(dataUrl, name).then(image => {
      const texture = new Texture(gl, { pixels: image, flip: false });
      textures.set(name, texture);
      return texture;
    });
  }
}
