import { useFetch } from "hooks/fetch";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Loading } from "../ui/loading";
import { RouteParams } from "features/ui";
import { useParams, useSearchParams } from "react-router-dom";
import {
  AppState,
  NavigationState,
  StateEvents,
  StateManager,
  globalState,
  layersModes,
} from "./state-manager";
import Modal from 'features/ui/components/auth/modal';
import { useRerender } from "hooks/rerender";

export interface Favorite {
  asin: string;
  image_id: string;
  price: number;
  title: string;
  x: number;
  y: number;
  h: number;
  l: number;
  w: number;
}

export interface Box {
  id: string;
  x: number;
  y: number;
  h: number;
  l: number;
  w: number;
  image_id?: string;
  title?: string;
  price?: number;
  rating?: number;
}

export interface Cluster {
  c: number;
  x: number;
  y: number;
  r: number;
  image: string;
  text: string;
  ids: Box[];
}

export interface Sector {
  sector: string;
  text: string;
  a: number;
  aw: number;
  image: string;
  clusters: Cluster[];
  videos: string[]
  // clusters: string[];
}

export interface SearchDataItem {
  article: string;
  catalog: string;
  coords: [number, number];
  image_id: string;
  sector: number;
  cluster: number;
  score: number;
  title: string;
  type: string;
  price: number;
}

export const appContext = createContext<AppState>({} as AppState);

interface AppContextProviderProps {
  children?: ReactNode;
}
export const AppDataProvider: FC<AppContextProviderProps> = ({ children }) => {
  // const [data, setData] = useState<Sector[]>([]);
  // const [loading, setLoading] = useState(true);
  const { slug, sectorId, clusterId } = useParams<RouteParams>();

  // if (!slug) {
  //   return null;
  // }
  const all = useFetch(`/static-data/${slug}/coords.json`);
  // const all = useFetch(`public/data/coords_blouses.json`);
  const { data } = all;

  // Fetch favorites
  const favorites = useMemo(async () => {
    if (!slug) return null;

    try {
      const response = await fetch(`/api/favorites?scene=${slug}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        mode: "cors",
      });

      if (response.ok) {
        const data = await response.json();
        globalState.setState("favorites", data);
        return data;
      } else if (response.status === 401) {
        // setAuthenticatedCookie(false);
        // setTimeout(() => { navigate("/auth") }, 3000);
        openModal();
        console.error("Unauthorized");
      } else {
        console.error("Failed to fetch favorites");
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
    }

    return null;
  }, [slug]);

  // Отслеживаем изменения в localStorage для избранного
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "favorites") {
        const updatedFavorites = JSON.parse(event.newValue || "[]");
        globalState.setState("favorites", updatedFavorites);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Update global state when favorites change
  // useEffect(() => {
  //   favorites.then(data => {
  //     if (data) {
  //       globalState.setState("favorites", data);
  //     }
  //   });
  // }, [favorites]);


  // convert clusters to arrays
  const d: Sector[] = useMemo(() => {
    if (!data) return;
    console.log(data);
    const sectors = data.scene;
    for (const sector of sectors) {
      const c = sector.cluster;
      if (!c) continue;
      const r = [];
      for (const ci of Object.keys(c)) {
        r.push(c[ci]);
      }
      sector.clusters = r;
      sector.a = Number(sector.a);
      sector.aw = Number(sector.aw);
    }
    globalState.setSectors(sectors);
    return sectors;
  }, [data]);

  // pass data to global state
  const [searchParams] = useSearchParams();
  const product = searchParams.get("product") ?? null;
  const layersModes = searchParams.get("layer")?.split(",") ?? [];
  useEffect(() => {
    globalState.setState("sceneId", slug || "");
  }, [slug]);
  useEffect(() => {
    if (sectorId === "favorites") {
      globalState.setState("sectorId", "favorites");
      globalState.setState("clusterId", null);
    } else {
      const id = Number(sectorId);
      globalState.setState("sectorId", isNaN(id) ? null : id);
    }
  }, [sectorId]);
  useEffect(() => {
    const id = Number(clusterId);
    globalState.setState("clusterId", isNaN(id) ? null : id);
  }, [clusterId]);
  useEffect(() => {
    globalState.setState("productId", product);
  }, [product]);
  useEffect(() => {
    globalState.setState("layersModes", layersModes as layersModes);
  }, [layersModes]);
  useEffect(() => {
    globalState.setState("searchQuery", searchParams.get("search") ?? null);
  }, [window.location.search]);

  const [isModalAuth, setModalAuth] = useState<boolean>(false);

  const openModal = () => setModalAuth(true);
  const closeModal = () =>  window.location.pathname = `/${slug}`
  const onConfirm = () => window.location.pathname = "/auth";

  if (!d && slug) return <Loading />;
  return (
    <appContext.Provider value={globalState}>
      {children}
    
      {window.location.pathname.includes("favorites") && 
        <Modal isOpen={isModalAuth} onClose={closeModal} onConfirm={onConfirm}>
          <h2 className="text-lg font-bold mb-4">Authorization required</h2>
          <p className="mb-6">
            You are not authorized. Do you want to go to the login page?
          </p>
        </Modal>
      }
    </appContext.Provider>
  );
};

// use these hooks to access the global state
// and re-render components when the state changes
export const useAppState = () => {
  // useRerenderOnStateEvent("navigation");
  // useRerenderOnStateEvent("viewModeChange");
  // useRerenderOnStateEvent("sectorsChange");
  return useContext(appContext);
};
export const useNavigationState = () => {
  // useRerenderOnStateEvent("navigation");
  return useContext(appContext).state;
};
export const useViewMode = () => {
  // useRerenderOnStateEvent("viewModeChange");
  return useContext(appContext).state.viewMode;
};
export const useLayersModes = () => {
  return useContext(appContext).state.layersModes;
};
export const useSectors = () => {
  // useRerenderOnStateEvent("sectorsChange");
  return useContext(appContext).sectors;
};
export const useSearchData = () => {
  // return useContext(appContext).searchData;
  return globalState.state.searchData;
};

export const useFavorites = () => {
  // useRerenderOnStateEvent("favoritesChange");
  return useContext(appContext).state.favorites;
};

export const useRerenderOnStateEvent = (e: keyof StateEvents) => {
  const renderer = useRerender();
  useEffect(() => {
    const unbind = globalState.on(e, () => {
      renderer();
    });
    return unbind;
  }, []);
};
