import { FC, useMemo } from "react";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderProgram,
  useSharedShaderPrograms,
} from "../scene-context";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { RichProductsRenderTask } from "../render-tasks/rich-products";
import { useCluster } from "./sectors";
import { useAppState } from "features/global-state/app-context";
import { useTextureCache } from "features/resource-loader";

export const RichProductsComponent: FC = () => {
  const roundedQuad = useSharedGeometry("roundedQuad");
  const shaderPrograms = useSharedShaderPrograms([
    "textureRenderer",
    "uvDebug",
  ]);
  const { gl, rl } = useDC();
  const camera = useCamera();
  const clusterContext = useCluster();
  const stateManager = useAppState();
  const textureCache = useTextureCache();
  const rt = useMemo(
    () =>
      new RichProductsRenderTask(gl, {
        geometries: { roundedQuad },
        shaderPrograms,
        camera,
        clusterContext,
        stateManager,
        textureCache,
        rl,
      }),
    []
  );
  useFrame(rl => {
    rt.render();
  });
  return null;
};
