export const sectorHoverFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D u_texture;
uniform float animationState;

void main() {
    float color = smoothstep(0.0, 0.1, v_uv.x) * smoothstep(1.0, 0.9, v_uv.x);
    color *= smoothstep(0.0, 0.01, v_uv.y) * smoothstep(1.0, 0.999, v_uv.y);
    float as = animationState;
    if (1.0 - v_uv.y > animationState) {
        color = 0.0;
        as = 0.0;
    }
    // small ground ring
    // if (v_uv.y > 0.98) {
    //     color = 1.0;
    //     as = 1.;
    // }
    FragColor = vec4(1., 1., 1., color);
}
`;
