import {
  World,
  Vec3,
  Body,
  Box,
  Trimesh,
  Ray,
  RaycastResult,
  AABB as CannonAABB,
} from "cannon-es";
import { vec3 } from "gl-matrix";
import { createNanoEvents } from "nanoevents";
import { PI } from "math";

export const gravity = new Vec3(0, 0, 0);

export interface AABB {
  position: vec3;
  scale: vec3;
}

export interface SectorShape {
  a: number;  // starting angle
  aw: number;  // angular width
  innerRadius: number;
  outerRadius: number;
}

// arbitrary identifying user data
export interface Handle {
  id: string;
  type: string;
}

interface Events {
  change: () => void;
  hover: (hovered: Handle) => void;
  unhover: () => void;
}

function polarToCartesian(radius: number, angle: number): Vec3 {
  return new Vec3(
    radius * Math.cos(angle),
    0,
    radius * Math.sin(angle)
  );
}

export class PhysicsModule {
  world: World = new World({ gravity });
  handles: Map<Body, Handle> = new Map();
  bodies: Map<Handle, Body> = new Map();
  emitter = createNanoEvents<Events>();
  on<E extends keyof Events>(event: E, callback: Events[E]): () => void {
    return this.emitter.on(event, callback);
  }
  addAABB(aabb: AABB, handle: Handle) {
    // common variables
    const world = this.world;
    const handles = this.handles;
    const bodies = this.bodies;

    const body = new Body({
      mass: 0,
      position: new Vec3(aabb.position[0], aabb.position[1], aabb.position[2]),
      shape: new Box(
        new Vec3(aabb.scale[0] / 2, aabb.scale[1] / 2, aabb.scale[2] / 2)
      ),
    });
    world.addBody(body);
    handles.set(body, handle);
    bodies.set(handle, body);
    console.log("physics world change");
    this.emitter.emit("change");
  }

  addSectorShape(sectorShape: SectorShape, handle: Handle) {
    const { a, aw, innerRadius, outerRadius } = sectorShape;
    const segmentCount = 20; // Adjust for more or less detail
    const angleStep = aw / segmentCount;

    const vertices: number[] = [];
    const indices: number[] = [];

    // Generate vertices
    for (let i = 0; i <= segmentCount; i++) {
      const angle = a + i * angleStep;
      const innerPoint = polarToCartesian(innerRadius, angle);
      const outerPoint = polarToCartesian(outerRadius, angle);
      
      vertices.push(innerPoint.x, innerPoint.y, innerPoint.z);
      vertices.push(outerPoint.x, outerPoint.y, outerPoint.z);
    }

    // Generate indices for triangles
    for (let i = 0; i < segmentCount; i++) {
      const baseIndex = i * 2;
      indices.push(
        baseIndex, baseIndex + 1, baseIndex + 2,
        baseIndex + 1, baseIndex + 3, baseIndex + 2
      );
    }

    const trimesh = new Trimesh(vertices, indices);
    const body = new Body({
      mass: 0,
      shape: trimesh,
      position: new Vec3(0, 0.2, 0), // Slight elevation to match SectorHover
    });

    this.world.addBody(body);
    this.handles.set(body, handle);
    this.bodies.set(handle, body);
    
    console.log("physics world change - added sector shape");
    this.emitter.emit("change");
  }
  // add ray to the physics world for visualization
  addRayAABB(ray: Ray) {
    const aabb = new CannonAABB();
    const world = this.world;
    ray.getAABB(aabb);
    // Step 1: Get the dimensions of the AABB
    const halfExtents = new Vec3(
      (aabb.upperBound.x - aabb.lowerBound.x) / 2,
      (aabb.upperBound.y - aabb.lowerBound.y) / 2,
      (aabb.upperBound.z - aabb.lowerBound.z) / 2
    );

    // Step 2: Calculate the center of the AABB
    const center = new Vec3(
      (aabb.upperBound.x + aabb.lowerBound.x) / 2,
      (aabb.upperBound.y + aabb.lowerBound.y) / 2,
      (aabb.upperBound.z + aabb.lowerBound.z) / 2
    );

    // Step 3: Create a Box shape using the dimensions
    const shape = new Box(halfExtents);

    // Step 4: Create a Body with the Box shape, positioned at the center of the AABB
    const body = new Body({
      mass: 0, // Set to 0 for a static body, or to a positive value for a dynamic body
      shape: shape,
      position: center,
    });

    // Add the body to your Cannon.js world
    world.addBody(body);
    this.emitter.emit("change");
  }
  castRay(from: vec3, to: vec3) {
    const world = this.world;
    const ray = new Ray(
      new Vec3(from[0], from[1], from[2]),
      new Vec3(to[0], to[1], to[2])
    );
    const result = new RaycastResult();
    ray.intersectWorld(world, { mode: Ray.ANY, result });

    // use this to debug raycasting
    // this.addRayAABB(ray);

    // console.log(result);
    if (result.hasHit && result.body) {
      return this.handles.get(result.body);
    } else return undefined;
  }
  removeAABB(handle: Handle) {
    const bodies = this.bodies;
    const handles = this.handles;
    const world = this.world;
    const body = bodies.get(handle);
    if (body) {
      // remove body if exists
      bodies.delete(handle);
      handles.delete(body);
      world.removeBody(body);
    }
  }
  clear() {
    if (!this.world) return;
    // common variables
    const handles = this.handles;
    const bodies = this.bodies;

    handles.clear();
    bodies.clear();
    // recreate the world to get rid of all added aabb
    this.world = new World({ gravity });
  }
  checkIfExists(key: 'type' | 'id', value: string): boolean {
    return Array.from(this.handles.values()).some(handle => handle[key] === value);
  }
}

// export default physics module
export const physics = new PhysicsModule();
