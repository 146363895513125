export const basicFragmentShader: string = `
    precision highp float;

    uniform vec4 color;

    void main() {
        gl_FragColor = color;
    }
  `;

export const basicFragmentShader300: string = `#version 300 es
    precision highp float;
    
    uniform vec4 color;

    out vec4 FragColor;

    void main() {
        FragColor = color;
    }
`;
