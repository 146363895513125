import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { useDC } from "features/draw-call/tsx/canvas";
import { useTexture } from "features/resource-loader";
import { useMemo } from "react";
import { clusterRingFragmentShader300 } from "../shaders/cluster-ring-fragment-shader";
import {
  clusterThumbnailsFragmentShader300,
  loadingThumbnailsFragmentShader300,
} from "../shaders/cluster-thumbnails-fragment-shader";
import { debugVertexShader } from "../shaders/debug-vertex-shader";
import { depthFragmentShader } from "../shaders/depth-fragment-shader";
import {
  instancedVertexShader,
  instancedUvVertexShader300,
  instancedVertexShader300,
} from "../shaders/instanced-vertex-shader";
import { modelMatrixVertexShader300 } from "../shaders/model-matrix-vertex-shader";
import { penumbraFragmentShader } from "../shaders/penumbra-fragment-shader";
import { sectorHoverFragmentShader300 } from "../shaders/sector-hover-fragment-shader";
import { shadowFragmentShader } from "../shaders/shadow-fragment-shader";
import { textureFragmentShader300 } from "../shaders/texture-fragment-shader";
import { uvDebugFragmentShader300 } from "../shaders/uv-debug-fragment-shader";
import { vertexShader } from "../shaders/vertex-shader";
import { captionFragmentShader300, loadingCaptionFragmentShader300 } from "../shaders/caption-fragment-shader";

export const useCreateSharedShaders = () => {
  const { gl } = useDC();
  const matcap = useTexture("matcap-extra-white.png");
  const ring0 = useTexture("ring0.jpg");
  const loading = useTexture("spinner.png");
  const loadingTrNeg = useTexture("spinner-tr-neg.png");
  return useMemo(
    () => ({
      depth: new ShaderProgram(gl, {
        fragmentShader: depthFragmentShader,
        vertexShader,
      }),
      instancedDepth: new ShaderProgram(gl, {
        fragmentShader: depthFragmentShader,
        vertexShader: instancedVertexShader,
      }),
      shadowRenderer: new ShaderProgram(gl, {
        fragmentShader: shadowFragmentShader,
        vertexShader,
        textures: {
          matcap,
        },
      }),
      instancedShadows: new ShaderProgram(gl, {
        fragmentShader: shadowFragmentShader,
        vertexShader: instancedVertexShader,
        textures: {
          matcap,
        },
      }),
      penumbra: new ShaderProgram(gl, {
        fragmentShader: penumbraFragmentShader,
        vertexShader: debugVertexShader,
      }),
      textureRenderer: new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: textureFragmentShader300,
      }),
      uvDebug: new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: uvDebugFragmentShader300,
      }),
      sectorHover: new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: sectorHoverFragmentShader300,
      }),
      instancedUvDebug: new ShaderProgram(gl, {
        vertexShader: instancedUvVertexShader300,
        fragmentShader: uvDebugFragmentShader300,
      }),
      instancedClusterThumbnails: new ShaderProgram(gl, {
        vertexShader: instancedUvVertexShader300,
        fragmentShader: clusterThumbnailsFragmentShader300,
      }),
      loadingClusterThumbnails: new ShaderProgram(gl, {
        vertexShader: instancedVertexShader300,
        fragmentShader: loadingThumbnailsFragmentShader300,
        textures: { u_texture: loading },
      }),
      loadingSectorCaption: new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: loadingCaptionFragmentShader300,
        textures: { u_texture: loadingTrNeg },
      }),
      clusterRing: new ShaderProgram(gl, {
        vertexShader: instancedVertexShader300,
        fragmentShader: clusterRingFragmentShader300,
        textures: { u_texture: ring0 },
      }),
      caption: new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: captionFragmentShader300,
      }),
    }),
    []
  );
};
