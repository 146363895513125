import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { FC, useEffect, useMemo } from "react";
import { ClusterBoxes } from "../render-tasks/cluster";
import {
  useModel,
  useTexture,
  useTextureCache,
} from "features/resource-loader";
import { useControls } from "leva";
import { hextogl } from "utils/hextogl";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderPrograms,
} from "../scene-context";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { useCluster } from "./sectors";
import { useAppState } from "features/global-state/app-context";
import { FrustumCulled } from "../render-tasks/frustum-culled";
import { vec3 } from "gl-matrix";
import { CAMERA_OFFSET } from "config";

export const ClusterComponent: FC = ({}) => {
  const { cluster, sectorIndex, clusterIndex } = useCluster();
  const { gl, rl } = useDC();
  const camera = useCamera();
  const boxGeometry = useSharedGeometry("roundedBox") as ElementsBufferGeometry;
  const wallsGeometry = useSharedGeometry("boxWalls") as ElementsBufferGeometry;
  const programs = useSharedShaderPrograms([
    "depth",
    "instancedDepth",
    "shadowRenderer",
    "instancedShadows",
    "penumbra",
  ]);
  const textureCache = useTextureCache();
  const appState = useAppState();
  const aabb = useMemo(
    () => ({
      position: vec3.fromValues(cluster.x, CAMERA_OFFSET * 0.5, cluster.y),
      scale: vec3.fromValues(cluster.r, CAMERA_OFFSET, cluster.r),
    }),
    []
  );
  const fabric = useMemo(
    () =>
      new FrustumCulled(gl, {
        camera,
        aabb: aabb,
        children: [
          new ClusterBoxes(gl, {
            boxGeometry,
            wallsGeometry,
            camera,
            cluster,
            programs,
            rl,
            textureCache,
            clusterIndex,
            sectorIndex,
            appState,
          }),
        ],
      }),
    []
  );
  useEffect(() => {}, []);

  /*const { minLightIntensity, maxLightIntensity, shadowIntensity, shadowColor } =
    useControls({
      minLightIntensity: { min: 0, max: 1, value: 0.76 },
      maxLightIntensity: { min: 0, max: 1, value: 1 },
      shadowIntensity: { min: 0, max: 1, value: 1 },

      shadowColor: "#d8d8d8",
    });
  fabric.minLightIntensity = minLightIntensity;
  fabric.maxLightIntensity = maxLightIntensity;
  fabric.shadowColor = hextogl(shadowColor);
  fabric.shadowIntensity = shadowIntensity;*/
  useFrame(rl => {
    fabric.render();
  }, 0);
  return null;
};
