import React, { useState, useEffect } from "react";
import { RouteParams } from "features/ui";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { globalState } from "features/global-state/state-manager";
import useSearch from '../../../hooks/search';
import { useFetch } from "hooks/fetch";

import { ReactComponent as ImageIcon } from '../icons/ImageIcon.svg';

interface ImageSearchProps {
  onClose: () => void
}

const ImageSearch = ({ onClose }: ImageSearchProps) => {
  const { slug, sectorId, clusterId } = useParams<RouteParams>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: categories } = useFetch("/data/categories.json");

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      setSearchParams({ search: "img" });
      handleSearch(); // Sent image immediately after selection
    }
  }, [selectedFile]);

  // Sent image on server
  const handleSearch = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      //catalog=${slug}&
      const response = await fetch(`/api/search/img?limit=20`, {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          'Accept': 'application/json',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      globalState.setSearchData(data);

      onClose();

      const findCatalog = () => {
        if (!categories || !data || data.length === 0) return;
        const firstSearch = data[0]
        const catalogName = firstSearch.catalog;
        const categoryKey = Object.keys(categories).find(
          key => categories[key].setName === catalogName
        );
      
        if (categoryKey) {
          navigate(`/${categoryKey}/${firstSearch.sector}/${firstSearch.cluster}/?product=${firstSearch.article}`);
        } else {
          console.error("Category not found for catalog:", catalogName);
        }
      };
      findCatalog();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Open file sistem or camera
  const handleImageSearch = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setError(null); // remove err if file selected
    }
  };

  // //!hook
  // const { handleSearch } = useSearch();

  // const handleImageSearch = async () => {
  //   if (!selectedFile) {
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('image', selectedFile);

  //   await handleSearch(`/api/search/img?limit=20`, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //     },
  //     body: formData,
  //   }, onClose);
  // };

  return (
    <>
      <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
        <span className="w-[40px] h-[40px] mr-[10px]">
          <ImageIcon className="transition duration-300 ease-in-out hover:scale-125" />
        </span>
      </label>
      <input
        id="image-upload"
        type="file"
        accept="image/*"
        capture="environment"
        style={{ display: 'none' }}
        onChange={handleImageSearch}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </>
  );
};

export default ImageSearch;
