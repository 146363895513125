import { BufferGeometry } from "features/draw-call/core/geometry/buffer-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  OutputFramebuffer,
  VertexBufferCollection,
} from "features/draw-call/core/types";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

export interface ArrayBufferGeometryOptions {
  vertexBuffers: VertexBufferCollection;
  count: number;
}

// a geometry class for use with gl.drawArrays()
export class ArrayBufferGeometry extends BufferGeometry {
  count: number = 0;
  constructor(gl: GLContext, options?: ArrayBufferGeometryOptions) {
    super(gl);
    if (options) {
      Object.assign(this, options);
    }
  }
  draw(): void {
    const gl = this.gl;
    gl.drawArrays(gl.TRIANGLES, 0, this.count);
  }
}
