import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { mat4 } from "gl-matrix";
import { useControls } from "leva";
import { FC, useEffect, useMemo } from "react";
import {
  GROUND_SIZE,
  groundMeshFragmentShader2,
  groundMeshFragmentShader300,
} from "../shaders/ground-mesh-fragment-shader";
import { vertexShader, vertexShader300 } from "../shaders/vertex-shader";
import { basicFragmentShader300 } from "../shaders/basic-fragment-shader";
import { groundFragmentShader } from "../shaders/ground-fragment-shader";
import { hextogl } from "utils/hextogl";
import { useCamera } from "../scene-context";

export interface GroundOptions {}

const _tmp0 = mat4.create();

class GroundMesh extends RenderTask {
  camera: OrbitCamera;
  fadeWidth: number;
  constructor(gl: WebGL2RenderingContext, options: any) {
    super(gl);
    this.camera = options.camera;
    this.fadeWidth = options.fadeWidth || 0.1;

    const groundMesh = new QuadGeometry(gl);
    const groundMeshT = rotationTranslationScaleMatrix(
      mat4.create(),
      [-Math.PI / 2, 0, 0],
      [0, 0, 0],
      [GROUND_SIZE, GROUND_SIZE, 1]
    );
    groundMesh.vertexBuffers.POSITION.applyMat4Position(groundMeshT);
    groundMesh.vertexBuffers.NORMAL.applyMat4Direction(groundMeshT);

    this.shaderPrograms.mesh = new ShaderProgram(gl, {
      fragmentShader: groundMeshFragmentShader2,
      vertexShader: vertexShader300,
    });
    this.shaderPrograms.plane = new ShaderProgram(gl, {
      fragmentShader: groundFragmentShader,
      vertexShader: vertexShader300,
    });

    this.geometries.groundMesh = groundMesh;
  }
  render(): void {
    super.render();
    const gl = this.gl;
    mat4.multiply(_tmp0, this.camera.projectionMatrix, this.camera.viewMatrix);
    mat4.invert(_tmp0, _tmp0);
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      inverseViewProjectionMatrix: _tmp0,
      px: [1 / window.innerWidth, 1 / window.innerHeight],
      modelMatrix: mat4.create(),
      lightProjectionMatrix: mat4.create(),
      lightViewMatrix: mat4.create(),
      fadeWidth: this.fadeWidth,
      transparency: 1 - this.camera.transitionValue,
    };
    gl.depthMask(false);
    this.geometries.groundMesh.drawCall(this.shaderPrograms.plane, uniforms);
    // gl.depthMask(true);
    this.geometries.groundMesh.drawCall(this.shaderPrograms.mesh, uniforms);
    gl.depthMask(true);
  }
}

export const GroundMeshScene: FC<GroundOptions> = ({}) => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const renderTask = useMemo(() => new GroundMesh(gl, { camera }), []);
  useEffect(() => {}, []);

  useFrame(rl => {
    renderTask.render();
  });
  return null;
};
