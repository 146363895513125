import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate, useLocation } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onConfirm: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!isOpen) return null;

    const handleLoginClick = () => {
        if (onConfirm) {
            onConfirm();
        }
        
        navigate(`/auth?redirectTo=${encodeURIComponent(location.pathname + location.search)}`);
    };

    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
        console.error('Modal root not found');
        return null;
    }

    if (modalRoot.hasChildNodes()) {
        console.warn('Modal is already open');
        return null;
    }

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-md p-6 relative">
                <button
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
                <div className="flex justify-end space-x-4">
                    <button
                        className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        onClick={handleLoginClick}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>,
        modalRoot
    );
};

export default Modal;