interface AuthResponse {
  data: {
    authenticated: boolean;
    token?: string;
  };
}
//TODO: Use API
class ApiService {
  private baseUrl = 'api/auth';
  private mockDelay = 1000;

  async authenticate(username: string, password: string): Promise<AuthResponse> {
    const response = await fetch(`${this.baseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Invalid credentials');
    }

    return await response.json();
  }

  async register(username: string, email: string, password: string): Promise<AuthResponse> {
    const response = await fetch(`${this.baseUrl}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        email,
        password,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Registration failed');
    }

    return await response.json();
  }

  // Updated logout method to use fetch
  async logout(): Promise<void> {
    const response = await fetch(`${this.baseUrl}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Include token if needed
        // 'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Logout failed');
    }

    return;
  }
}

export const api = new ApiService();

//!1
//   /auth/login
// {
//   "username": "string",
//   "password": "string"
// }

// {
//   "detail": [
//     {
//       "loc": [
//         "string",
//         0
//       ],
//       "msg": "string",
//       "type": "string"
//     }
//   ]
// }

//!2
// /auth/logout
// "string"

//!3
// /auth/register
// {
//   "username": "string",
//   "password": "string",
//   "email": "string"
// }

//!4
// /auth/confirm-email
// {
//   "token": "string"
// }

// /auth/me

// /categories

