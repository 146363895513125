import { Canvas } from "features/draw-call/tsx/canvas";
import { BrowserRouter as useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { ResourceLoader } from "features/resource-loader";
import { mat4, vec3 } from "gl-matrix";
import { Scene } from "./scene";
import { SceneProvider } from "./scene-context";
import { AppDataProvider } from "features/global-state/app-context";
import { SceneNavigator } from "./components/scene-navigator";
import { HoverManager } from "./components/hover-manager";
import { CameraAnimator } from "./components/camera-animator";
import { useMemo } from "react";
import { SCENE_NEAR } from "config";

export const Fabric = ({}) => {
  const { width, height } = useWindowSize();
  const searchParams = new URLSearchParams(window.location.search);
  const position = useMemo(
    () =>
      vec3.fromValues(
        ...((searchParams.get("p")?.split(",").map(Number) as [
          number,
          number,
          number
        ]) || [0, SCENE_NEAR, 0])
      ),
    [window.location.search]
  );
  const target = useMemo(
    () =>
      vec3.fromValues(
        ...((searchParams.get("t")?.split(",").map(Number) as [
          number,
          number,
          number
        ]) || [0, 0, 0])
      ),
    [window.location.search]
  );

  return (
    <div className="absolute z-0 bg-black">
      <Canvas width={width} height={height}>
        <ResourceLoader>
          <AppDataProvider>
            <SceneProvider position={position} target={target}>
              <Scene />
              <SceneNavigator />
              <HoverManager />
              <CameraAnimator />
            </SceneProvider>
          </AppDataProvider>
        </ResourceLoader>
      </Canvas>
    </div>
  );
};
