import React from "react";
import logo from "./logo.svg";
import "./index.css";
import { Fabric } from "./features/fabric";
import { Ui } from "features/ui";
import { ACCENT_COLOR } from "config";

function App() {
  return (
    <>
      <style>
        {`
        .accent-bg { background-color: ${ACCENT_COLOR}; }
        .accent-text { color: ${ACCENT_COLOR}; }
        `}
      </style>
      <Ui />
    </>
  );
}

export default App;
