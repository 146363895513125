import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { FC, useEffect, useRef } from "react";
import { useCamera } from "../scene-context";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppState, useSectors } from "features/global-state/app-context";
import { vec3 } from "gl-matrix";
import { sectorCoords } from "math/sector-coords";
import { lerp, PI } from "math";
import { ViewMode } from "features/global-state/state-manager";
import {
  CAMERA_ANIMATION_ANGULAR_SPEED,
  CAMERA_ANIMATION_SPEED,
  CLUSTER_FAR,
  CLUSTER_NEAR,
  FAVORITES_RADIUS,
  MODE_2D_DEFAULT,
  MODE_2D_FAR,
  MODE_2D_NEAR,
  SCENE_DEFAULT,
  SCENE_NEAR,
  SECTOR_NEAR,
} from "config";

export const CameraAnimator: FC = () => {
  const camera = useCamera();
  const appState = useAppState();
  const sectors = useSectors();
  const target = useRef(vec3.create());
  const radius = useRef(0);
  const done = useRef(true);
  const { rl } = useDC();
  const startTime = useRef(0);

  const extractedIndex = useRef(0);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const updateCamera = () => {
    done.current = false;
    startTime.current = rl.time;

    const viewMode = appState.state.viewMode;
    const searchQuery = searchParams.get("search");

    if (viewMode === "SCENE") {
      vec3.set(target.current, 0, 0, 0);
      radius.current = SCENE_DEFAULT;
    } else if (viewMode === "FAVORITES") {
      // vec3.set(target.current, 0, 1, 0);
      // radius.current = FAVORITES_RADIUS * CLUSTER_FAR;
      vec3.set(target.current, 0, 5, 13.6);
      radius.current = CLUSTER_NEAR * 2;
    } else if (
      viewMode === "SECTOR" &&
      typeof appState.state.sectorId === "number"
    ) {
      const coords = sectorCoords(sectors[appState.state.sectorId]);
      vec3.set(target.current, coords.center.x, 0, coords.center.y);
      radius.current = coords.radius * SECTOR_NEAR;
    } else if (
      viewMode === "CLUSTER" &&
      typeof appState.state.clusterId === "number" &&
      typeof appState.state.sectorId === "number"
    ) {
      const cluster =
        sectors[appState.state.sectorId].clusters[appState.state.clusterId];
      // do not set camera on product clicks as it is unnecessary
      const productId = appState.state.productId;
      const product = cluster.ids.find(p => p.id === productId);
      const x = product ? product.x : cluster.x;
      const y = product ? product.y : cluster.y;
      vec3.set(target.current, x, 1, y);
      radius.current = CLUSTER_NEAR * 2 + 4;
    } else if (viewMode === "SEARCH") {
      if (appState.state.searchData?.length) {
        if (searchQuery) {
          const parts = searchQuery.split("-");
          extractedIndex.current = +parts[1] || 0;
        }
        const firstResult = appState.state.searchData[extractedIndex.current];
        const cluster =
          sectors[firstResult.sector].clusters[firstResult.cluster];
        if (firstResult?.coords) {
          vec3.set(
            target.current,
            firstResult.coords[0],
            1,
            firstResult.coords[1]
          );
        } else {
          vec3.set(target.current, cluster.x, 1, cluster.y);
        }
        radius.current = cluster.r * CLUSTER_FAR;
      }
    }
  };

  const setCamera = () => {
    done.current = true;
    vec3.copy(camera.target, target.current);
    camera.radius = radius.current;
    let currentAngleY: number = PI / 12;
    let currentAngleX: number = camera.controlledAngleX
      ? camera.angleX
      : PI / 24 - PI / 2;
    if (camera.radius <= MODE_2D_FAR) {
      currentAngleY = PI / 2 - 0.001;
      currentAngleX = -PI / 2;
      camera.controlledAngleX = false;
    }
    camera.angleX = currentAngleX;
    camera.angleY = currentAngleY;
    camera.update();
  };

  // Listen for viewMode changes
  useEffect(() => {
    return appState.on("viewModeChange", () => {
      updateCamera();
    });
  }, [sectors, appState.state.searchData]);

  // Listen for URL and search parameter changes
  useEffect(() => {
    // updateCamera();
  }, [location.pathname, searchParams]);

  // Initial
  useEffect(() => {
    updateCamera();
    setCamera();
  }, []);

  // Stop animation on wheel
  useEffect(() => {
    const u0 = camera.on("wheel", () => {
      // stop radius animation if it's been running for more than 250ms
      //! may cause jerking
      if (rl.time - startTime.current > 500) {
        radius.current = camera.radius;
        done.current = true;
      }
    });
    const u1 = camera.on("pointerdown", () => {
      console.log("pointerdown");
      // stop radius animation if it's been running for more than 250ms
      //! may cause jerking
      if (rl.time - startTime.current > 250) {
        radius.current = camera.radius;
        done.current = true;
      }
    });
    return () => {
      u0();
      u1();
    };
  }, []);

  const calculateInterpolationFactor = (
    distance: number,
    minDistance: number,
    maxDistance: number
  ) => {
    // Calculate the scale factor to normalize the distance
    const normalizedDistance = Math.min(Math.max(distance / minDistance, 0), 1);
    // Apply an exponential increase in the coefficient as the distance increases.
    const factor =
      Math.pow(normalizedDistance, 2) * (maxDistance - minDistance) +
      minDistance;
    return factor;
  };

  useFrame(rl => {
    // use delta in seconds
    const delta = rl.delta * 0.001;

    // update camera angles to transition to 2d mode based on distance
    const distance = camera.radius;
    let currentAngleY: number = PI / 12;
    let currentAngleX: number = camera.controlledAngleX
      ? camera.angleX
      : PI / 12 - PI / 2;
    if (distance <= MODE_2D_FAR) {
      currentAngleY = PI / 2 - 0.01;
      currentAngleX = -PI / 2;
      camera.controlledAngleX = false;
    }
    camera.angleY = lerp(
      camera.angleY,
      currentAngleY,
      delta * CAMERA_ANIMATION_SPEED * CAMERA_ANIMATION_ANGULAR_SPEED
    );
    camera.angleX = lerp(
      camera.angleX,
      currentAngleX,
      delta * CAMERA_ANIMATION_SPEED * CAMERA_ANIMATION_ANGULAR_SPEED
    );
    camera.update();
    if (!done.current) {
      vec3.lerp(
        camera.target,
        camera.target,
        target.current,
        delta * CAMERA_ANIMATION_SPEED
      );
      camera.radius = lerp(
        camera.radius,
        radius.current,
        delta * CAMERA_ANIMATION_SPEED
      );
      // camera.angleX = lerp(camera.angleX, angle.current, delta * speed);
      if (vec3.distance(camera.target, target.current) < 0.01) {
        done.current = true;
        camera.radius = radius.current;
        vec3.copy(camera.target, target.current);
        camera.update();
      }
    }
  });
  return null;
};
