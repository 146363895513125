import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { vec3 } from "gl-matrix";
import { FC, useMemo } from "react";
import { ApplyTransforms } from "../render-tasks/apply-transforms";
import { FaceCamera } from "../render-tasks/face-camera";
import {
  useCamera,
  useSceneContext,
  useSharedGeometry,
  useSharedShaderProgram,
  useSharedShaderPrograms,
} from "../scene-context";
import { Cluster, useAppState } from "features/global-state/app-context";
import {
  CLUSTER_CAPTION_HEIGHT,
  CLUSTER_CAPTION_SCALE,
  FADE_DURATION,
} from "config";
import { AppStateEmerge } from "../render-tasks/app-state-emerge";
import { ViewMode } from "features/global-state/state-manager";
import { CaptionRenderTask } from "../render-tasks/caption";
import {
  usePhysics,
  useTextureCache,
} from "features/resource-loader";
import { AnimateOnHover } from "../render-tasks/animate-on-hover";
import { Handle } from "features/physics";

export const Caption: FC<{
  x: number;
  y: number;
  text: string;
  viewMode?: ViewMode | ViewMode[];
  clusterId?: number;
  sectorId?: number;
  handle: Handle;
  scale?: number;
  imageURL: string;
}> = ({
  x,
  y,
  text,
  viewMode,
  clusterId,
  sectorId,
  handle,
  scale,
  imageURL,
}) => {
  const camera = useCamera();
  const calculateDistance = (cameraPos: vec3, sectorPos: vec3) => {
    return vec3.distance(cameraPos, sectorPos);
  };
  const distanceFromCamera = calculateDistance(
    [camera.position[1], camera.position[2], 0], 
    [x, y, 0]); // Calculate distance
  const scaleFactor = Math.max(1, distanceFromCamera/300);
  const translation = useMemo(
    () => vec3.fromValues(x, CLUSTER_CAPTION_HEIGHT, y),
    []
  );
  const _scale = useMemo(
    () =>
      vec3.fromValues(
        (scale || CLUSTER_CAPTION_SCALE)*scaleFactor,
        (scale || CLUSTER_CAPTION_SCALE)*scaleFactor,
        (scale || CLUSTER_CAPTION_SCALE)*scaleFactor
      ),
    []
  );
  const { gl, rl } = useDC();
  const stateManager = useAppState();
  const textureCache = useTextureCache();
  const quad = useSharedGeometry("quad");
  const shaderPrograms = useSharedShaderPrograms(["caption", "loadingSectorCaption"]);
  const physics = usePhysics();
  const renderTask = useMemo(
    () =>
      new AppStateEmerge(gl, {
        rl,
        duration: FADE_DURATION,
        stateManager,
        viewMode,
        clusterId,
        sectorId,
        children: [
          new ApplyTransforms(gl, {
            translation,
            children: [
              new FaceCamera(gl, {
                camera,
                children: [
                  new ApplyTransforms(gl, {
                    scale: _scale,
                    children: [
                      new AnimateOnHover(gl, {
                        duration: 250,
                        handle,
                        rl,
                        physics,
                        children: [
                          new CaptionRenderTask(gl, {
                            camera,
                            textureCache,
                            text,
                            imageURL,
                            geometries: { quad },
                            shaderPrograms,
                            rl,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    []
  );
  useFrame(rl => {
    renderTask.render();
  });
  return null;
};
