export const adRingFragmentShader300: string = `#version 300 es
  precision highp float;
  uniform sampler2D normalMap;
  uniform sampler2D matcap;
  uniform vec4 color0;

  in vec2 v_uv;
  in vec3 v_lightDirection;
  in vec3 v_viewPosition;

  out vec4 FragColor;

  void main() {
    vec2 st = v_uv;
    st = vec2(v_uv.y, 1.0 - v_uv.x);  // Rotate texture coordinates by 90 degrees
    st.x = fract(st.x * 16.0);         // Frequent repetition of textures along the X axis
    vec3 normal = normalize(texture(normalMap, st).xyz * 2.0 - 1.0);
    float lambertian = dot(normal, v_lightDirection);
    vec4 diffuse = vec4(color0.rgb * lambertian, color0.a);

    vec3 viewDir = normalize( v_viewPosition );
    vec3 matx = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
    vec3 maty = cross( viewDir, matx );
    vec2 matcapUV = vec2( dot( matx, normal ), dot( maty, normal ) ) * 0.495 + 0.5;

    vec4 matcapColor = texture(matcap, matcapUV) * 1.0; //0.9

    // // Add border-radius effect
    // float borderRadius = 0.05; // Adjust this value to change the radius
    // vec2 center = vec2(0.5, 0.5);
    // float dist = length(v_uv - center);
    // float alpha = smoothstep(0.7 - borderRadius, 0.7, dist);

    // FragColor = mix(matcapColor, vec4(0.0), alpha);
    FragColor = matcapColor;
  }
`;
