import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { Texture } from "features/draw-call/core/texture";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { useResource, useTexture } from "features/resource-loader";
import { useMemo, useEffect, FC, useState, useRef, useCallback } from "react";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderProgram,
} from "../scene-context";
import { mat4, vec3, quat } from "gl-matrix";
import { BufferGeometry } from "features/draw-call/core/geometry/buffer-geometry";
import { UniformValues } from "features/draw-call/core/types";
import { modelMatrixVertexShader300 } from "../shaders/model-matrix-vertex-shader";
import { adRingFragmentShader300 } from "../shaders/ad-ring-fragment-shader";
import { physics } from "features/physics";

class AdsSliderRenderTask extends RenderTask {
  id: string;
  camera: OrbitCamera;
  items: Array<{ url: string; contentType: "video" | "image", path?: string }>;
  size: vec3;
  translation: vec3;
  rotation: vec3;

  sizeSlide: vec3;
  translationSlide: vec3;
  rotationSlide: vec3;

  titleTexture: Texture;
  titleGeometry: BufferGeometry;
  matcap: Texture;

  margin: number;
  textures: Texture[];
  frameTexture: Texture;
  edgeMatrix: Texture;
  sharedGeometry: BufferGeometry;
  slideGeometry: BufferGeometry;
  arrowTexture: Texture;
  program: ShaderProgram;
  programSlider: ShaderProgram;
  programEdge: ShaderProgram;
  itemMatrices: mat4[];

  private sliderType: 1 | 2 | 3;
  private sizeRatios: number[];
  private sliderModelMatrix: mat4;
  private slideModelMatrix: mat4;
  private titleMatrix: mat4;
  private leftArrowMatrix: mat4;
  private rightArrowMatrix: mat4;
  private sliderOffset: number = 0;
  private targetOffset: number = 0;
  private isAnimating: boolean = false;
  private animationProgress: number = 0;
  private animationStartTime: number | null = null;
  private animationDuration: number = 300; // ms
  private animationId: number | null = null;

  private cachedMatrix: mat4 = mat4.create();
  private cachedVector: vec3 = vec3.create();

  private frameLeft: number;
  private frameRight: number;
  private halfWidth: number;

  private lastVideoUpdateTime: number = 0;

  constructor(
    gl: WebGL2RenderingContext,
    options: {
      id: string;
      camera: OrbitCamera;
      items: Array<{ url: string; contentType: "video" | "image", path?: string }>;
      size: vec3;
      translation: vec3;
      rotation: vec3;

      sizeSlide: vec3;
      translationSlide: vec3;
      rotationSlide: vec3;

      titleTexture: Texture;
      titleGeometry: BufferGeometry;
      matcap: Texture;

      sliderType: 1 | 2 | 3;
      sizeRatios: number[];

      margin: number;
      textures: Texture[];
      frameTexture: Texture;
      edgeMatrix: Texture;
      sharedGeometry: BufferGeometry;
      slideGeometry: BufferGeometry;
      arrowTexture: Texture;
      program: ShaderProgram;
      programSlider: ShaderProgram;
      programEdge: ShaderProgram;
    }
  ) {
    super(gl);
    // Object.assign(this, options);
    this.id = options.id;
    this.camera = options.camera;
    this.programSlider = options.programSlider;
    this.programEdge = options.programEdge;
    this.geometries.main = options.sharedGeometry;
    this.items = options.items;
    this.size = options.size;
    this.translation = options.translation;
    this.rotation = options.rotation || vec3.create();

    this.sizeSlide = options.sizeSlide;
    this.translationSlide = options.translationSlide;
    this.rotationSlide = options.rotationSlide;

    this.titleTexture = options.titleTexture;
    this.titleGeometry = options.titleGeometry;
    this.matcap = options.matcap;

    this.margin = options.margin;
    this.textures = options.textures;
    this.frameTexture = options.frameTexture;
    this.edgeMatrix = options.edgeMatrix;
    this.sharedGeometry = options.sharedGeometry;
    this.slideGeometry = options.slideGeometry;
    this.arrowTexture = options.arrowTexture;
    this.program = options.program;
    this.frameLeft = 0;
    this.frameRight = 0;
    this.halfWidth = 0;

    this.sliderModelMatrix = mat4.create();
    mat4.fromRotationTranslationScale(
      this.sliderModelMatrix,
      quat.fromEuler(
        quat.create(),
        this.rotation[0],
        this.rotation[1],
        this.rotation[2]
      ),
      this.translation,
      [1, 1, 1]
    );

    this.slideModelMatrix = mat4.create();
    //TODO mat4.copy(this.slideModelMatrix, this.sliderModelMatrix);
    mat4.fromRotationTranslationScale(
      this.slideModelMatrix,
      quat.fromEuler(
        quat.create(),
        this.rotationSlide[0],
        this.rotationSlide[1],
        this.rotationSlide[2]
      ),
      this.translationSlide,
      [1, 1, 1]
    );

    this.titleMatrix = mat4.create();
    mat4.copy(this.titleMatrix, this.sliderModelMatrix);

    this.leftArrowMatrix = mat4.create();
    mat4.copy(this.leftArrowMatrix, this.sliderModelMatrix);
    this.rightArrowMatrix = mat4.create();
    mat4.copy(this.rightArrowMatrix, this.sliderModelMatrix);

    this.sliderType = options.sliderType;
    this.sizeRatios = options.sizeRatios;

    this.itemMatrices = this.calculateItemMatrices();
    this.updateCachedValues();
    // this.addSliderArrowBoxes();
  }

  private updateCachedValues() {
    const totalRatio = this.sizeRatios.reduce((a, b) => a + b, 0);
    this.frameLeft = this.translationSlide[0] - this.sizeSlide[0] / 2;
    this.frameRight = this.translationSlide[0] + this.sizeSlide[0] / 2;
    this.halfWidth =
      (this.sizeSlide[0] *
        (this.sizeRatios[Math.floor(this.sliderType / 2)] / totalRatio)) /
      2;
  }

  private radius: number = 300; // Circle radius

  private calculateItemMatrices(): mat4[] {
    const totalRatio = this.sizeRatios.reduce((a, b) => a + b, 0);
    const totalWidth = this.sizeSlide[0];
    const slideWidth = totalWidth / this.sliderType;
    const angleStep = (slideWidth + this.margin) / Math.abs(this.radius);
    const offset = ((this.sliderType - 1) * angleStep) / 2; // calculate the offset so that the slides are centered

    return this.items.map((_, index) => {
      mat4.identity(this.cachedMatrix);
      const slideWidth =
        totalWidth * (this.sizeRatios[index % this.sliderType] / totalRatio) +
        25; //!

      const angular =
        index * angleStep - offset + this.sliderOffset / this.radius;
      const offsetX = this.radius * Math.sin(angular); //!
      const offsetZ = -this.radius * (1 - Math.cos(angular));

      vec3.set(this.cachedVector, offsetX, 0, offsetZ);
      mat4.translate(this.cachedMatrix, this.cachedMatrix, this.cachedVector);
      mat4.rotateY(this.cachedMatrix, this.cachedMatrix, angular + Math.PI);
      mat4.scale(this.cachedMatrix, this.cachedMatrix, [
        slideWidth / 1.6,
        this.sizeSlide[1] / 1.6,
        this.sizeSlide[2],
      ]);
      mat4.multiply(
        this.cachedMatrix,
        this.slideModelMatrix,
        this.cachedMatrix
      );
      return mat4.clone(this.cachedMatrix);
    });
  }

  //line
  //   calculateItemMatrices(): mat4[] {
  //     const totalRatio = this.sizeRatios.reduce((a, b) => a + b, 0);
  //     const totalWidth = this.sizeSlide[0];
  //     const slideWidth = totalWidth / this.sliderType; // Single slide size

  //     return this.items.map((_, index) => {
  //         mat4.identity(this.cachedMatrix);

  //         // Calculate the displacement of each slide along the X axis
  //         const offsetX = (index % this.sliderType) * (slideWidth + this.margin) - (totalWidth - slideWidth) / 2;

  //         vec3.set(this.cachedVector,
  //             offsetX + (Math.floor(index / this.sliderType) * totalWidth) + this.sliderOffset,
  //             0,
  //             0 // Remove the Z offset so that the slides are on the same plane
  //         );

  //         mat4.translate(this.cachedMatrix, this.cachedMatrix, this.cachedVector);

  //         mat4.scale(this.cachedMatrix, this.cachedMatrix, [slideWidth / 2, this.sizeSlide[1] / 2, this.sizeSlide[2]]);
  //         mat4.multiply(this.cachedMatrix, this.slideModelMatrix, this.cachedMatrix);

  //         return mat4.clone(this.cachedMatrix);
  //     });
  // }

  slide(direction: 1 | -1) {
    if (this.isAnimating) return;
    const slideWidth = this.sizeSlide[0] / this.sliderType + this.margin;
    const maxRightOffset = -slideWidth * (this.items.length - this.sliderType);
    const maxLeftOffset = 0;
    const potentialOffset =
      this.sliderOffset - direction * slideWidth * this.sliderType; // insert onto the rotary slide

    // Check if the potential offset is within the specified limits
    if (potentialOffset < maxRightOffset || potentialOffset > maxLeftOffset) {
      return; // If it fails, then we don’t start the animation
    }

    this.isAnimating = true;
    this.animationProgress = 0;
    this.targetOffset = potentialOffset;
    this.animationStartTime = null;

    const animate = (timestamp: number) => {
      if (this.animationStartTime === null) this.animationStartTime = timestamp;
      const elapsedTime = timestamp - this.animationStartTime;
      this.animationProgress = Math.min(
        elapsedTime / this.animationDuration,
        1
      );

      if (this.animationProgress < 1) {
        this.updateSliderOffset();
        this.itemMatrices = this.calculateItemMatrices();
        this.animationId = requestAnimationFrame(animate);
      } else {
        this.finishAnimation();
      }
    };

    this.animationId = requestAnimationFrame(animate);
  }

  private finishAnimation() {
    this.isAnimating = false;
    this.sliderOffset = this.targetOffset;
    this.itemMatrices = this.calculateItemMatrices();
    this.updatePhysicsBodies();
    this.animationId = null;
  }

  private updateSliderOffset() {
    this.sliderOffset =
      this.sliderOffset +
      (this.targetOffset - this.sliderOffset) * this.animationProgress;
  }

  private getVisibleRange(): { start: number; end: number } {
    const slideWidth = this.sizeSlide[0];
    const centerIndex =
      Math.floor(-this.sliderOffset / slideWidth) * this.sliderType;

    return {
      start: Math.max(0, centerIndex),
      end: Math.min(
        this.items.length - 1,
        centerIndex + this.sliderType * 2 - 1
      ),
    };
  }

  private calculateTransparency(matrix: mat4): number {
    const position = vec3.fromValues(matrix[12], matrix[13], matrix[14]);
    const distance = vec3.distance(position, this.translationSlide);
    const maxDistance = this.sizeSlide[0] / 2.59; //!

    if (distance > maxDistance) {
      return 1.0;
    } else {
      return 0.0;
    }
  }

  private updatePhysicsBodies() {
    // Delete all existing physics bodies
    const existingHandles = Array.from(physics.handles.values());
    existingHandles.forEach(handle => {
      if (handle && handle.id && typeof handle.id === 'string' &&
        handle.id.includes(this.id) && handle.type === 'slide') {
        physics.removeAABB(handle);
      }
    });

    // Getting the visible range
    const visibleRange = this.getVisibleRange();

    // Create new physics bodies only for visible slides
    for (let i = visibleRange.start; i <= visibleRange.end; i++) {
      const matrix = this.itemMatrices[i];
      const position = vec3.fromValues(matrix[12], matrix[13], matrix[14]);

      // Checking transparency
      const transparency = this.calculateTransparency(matrix);
      if (!transparency) {
        const slideId = `${this.id}:${i}:${this.items[i].contentType}:${this.items[i]?.path || this.items[i].url}`;
        physics.addAABB(
          {
            position,
            scale: vec3.fromValues(
              this.sizeSlide[0] / 4,
              this.sizeSlide[1] / 2,
              this.sliderType === 1 ? 50 : 1
            )
          },
          { id: slideId, type: 'slide' }
        );
      }
    }
  }

  render(u?: UniformValues): void {
    super.render();

    // Recalculate the slide matrices at each render frame
    // this.itemMatrices = this.calculateItemMatrices();

    const currentTime = performance.now();
    if (currentTime - this.lastVideoUpdateTime > 1000 / 30) {
      // 30 fps for video
      this.textures.forEach((texture, index) => {
        if (this.items[index].contentType === "video" && texture && 'updated' in texture) {
          texture.updated = false;
        }
      });
      this.lastVideoUpdateTime = currentTime;
    }

    const frustumPlanes = this.camera.getFrustumPlanes();

    // Render frame
    const frameMatrix = mat4.create();
    mat4.scale(frameMatrix, frameMatrix, this.size);
    mat4.multiply(frameMatrix, this.sliderModelMatrix, frameMatrix);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);
    this.sharedGeometry.drawCall(this.programSlider, {
      ...u,
      modelMatrix: frameMatrix,
      u_texture: this.frameTexture,
      transparency: 0,
    });
    // gl.cullFace(gl.BACK);
    // this.sharedGeometry.drawCall(this.programSlider, {
    //     ...u,
    //     modelMatrix: frameMatrix,
    //     u_texture: this.frameTexture,
    //     transparency: 0,
    //   });
    this.gl.disable(this.gl.CULL_FACE);

    // Render edge
    this.gl.depthMask(false);
    // this.gl.enable(this.gl.BLEND);
    // this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);
    const edgeMatrix = mat4.create();
    mat4.scale(edgeMatrix, edgeMatrix, {...this.size,
      [0]: this.size[0] + 2 + this.sliderType,
      [1]: this.size[1] + 3.6,
      [2]: this.size[2] + 1 + this.sliderType
    });
    mat4.multiply(edgeMatrix, this.sliderModelMatrix, edgeMatrix);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);
    this.sharedGeometry.drawCall(this.programEdge, {
      ...u,
      modelMatrix: edgeMatrix,
      u_texture: this.edgeMatrix,
      transparency: 0,
    });
    this.gl.disable(this.gl.CULL_FACE);
    // this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.depthMask(true);

    // Render arrows
    const arrowSize = 10;
    const arrowOffset = this.sliderType === 1 ? 0 : 0.2; // distance from frame edge

    // Left arrow
    const leftArrowMatrix = mat4.create();
    mat4.fromRotationTranslationScale(
      this.leftArrowMatrix,
      quat.fromEuler(
        quat.create(),
        this.rotationSlide[0],
        this.rotationSlide[1] - 180,
        this.rotationSlide[2]
      ),
      this.translationSlide,
      [1, 1, 1]
    );
    mat4.scale(leftArrowMatrix, leftArrowMatrix, [arrowSize, arrowSize, 1]);
    mat4.translate(leftArrowMatrix, leftArrowMatrix, [
      -this.size[0] / 4 - arrowOffset + (this.sliderType === 1 ? 0.4 : 0),
      0.7,
      this.size[2] / 4 + 2,
    ]);
    mat4.multiply(leftArrowMatrix, this.leftArrowMatrix, leftArrowMatrix);

    this.slideGeometry.drawCall(this.program, {
      ...u,
      modelMatrix: leftArrowMatrix,
      u_texture: this.arrowTexture,
      transparency: 0,
    });

    const leftArrowPosition = vec3.fromValues(
      leftArrowMatrix[12],
      leftArrowMatrix[13],
      leftArrowMatrix[14]
    );

    const letfId = this.id + "l";
    if (
      Array.from(physics.handles.values()).some(
        existingHandle => existingHandle.id === letfId
      )
    ) {
      // console.log(`handle.id = ${letfId} already exists`);
    } else {
      physics.addAABB(
        {
          position: leftArrowPosition,
          scale: vec3.fromValues(arrowSize, arrowSize, arrowSize),
        },
        { id: letfId, type: "slider" }
      );

      // Add click event for left arrow
      this.camera.on("click", event => {
        const hendle = physics.castRay(this.camera.position, event);
        if (hendle?.id === letfId) {
          this.slide(-1);
        }
      });
    }

    // Right arrow
    const rightArrowMatrix = mat4.create();
    mat4.fromRotationTranslationScale(
      this.rightArrowMatrix,
      quat.fromEuler(
        quat.create(),
        this.rotationSlide[0],
        this.rotationSlide[1],
        this.rotationSlide[2]
      ),
      this.translationSlide,
      [1, 1, 1]
    );
    mat4.scale(rightArrowMatrix, rightArrowMatrix, [arrowSize, arrowSize, 1]);
    mat4.translate(rightArrowMatrix, rightArrowMatrix, [
      -this.size[0] / 4 - arrowOffset,
      0.7,
      -this.size[2] / 4 - 2,
    ]);
    mat4.multiply(rightArrowMatrix, this.rightArrowMatrix, rightArrowMatrix);

    this.slideGeometry.drawCall(this.program, {
      ...u,
      modelMatrix: rightArrowMatrix,
      u_texture: this.arrowTexture,
      transparency: 0,
    });

    const rightArrowPosition = vec3.fromValues(
      rightArrowMatrix[12],
      rightArrowMatrix[13],
      rightArrowMatrix[14]
    );

    const rightId = this.id + "r";
    if (
      Array.from(physics.handles.values()).some(
        existingHandle => existingHandle.id === rightId
      )
    ) {
      // console.log(`handle.id = ${rightId} already exists`);
    } else {
      physics.addAABB(
        {
          position: rightArrowPosition,
          scale: vec3.fromValues(arrowSize, arrowSize, arrowSize),
        },
        { id: rightId, type: "slider" }
      );

      // Add click event for right arrow
      this.camera.on("click", event => {
        const hendle = physics.castRay(this.camera.position, event);
        if (hendle?.id === rightId) {
          this.slide(1);
        }
      });
    }

    // Render items
    this.gl.depthMask(false);
    const visibleRange = this.getVisibleRange();
    for (let i = visibleRange.start; i <= visibleRange.end; i++) {
      const matrix = this.itemMatrices[i];
      const position = vec3.fromValues(matrix[12], matrix[13], matrix[14]);

      const transparency = this.calculateTransparency(matrix);

      if (this.isInFrustum(frustumPlanes, position, this.halfWidth) && !transparency) {
        this.slideGeometry.drawCall(this.program, {
          ...u,
          modelMatrix: matrix,
          u_texture: this.textures[i],
          transparency,
        })

        // Interactive block
        const slideId = `${this.id}:${i}:${this.items[i].contentType}:${this.items[i]?.path || this.items[i].url}`;
        const handle = { id: slideId, type: 'slide' };

        // Checking whether such a body already exists
        const existingBody = Array.from(physics.handles.values())
          .find(h => h.id === slideId && h.type === 'slide');

        if (!existingBody) {
          // Create a new body only if it does not exist yet
          physics.addAABB(
            {
              position,
              scale: vec3.fromValues(
                this.sizeSlide[0] / 4,
                this.sizeSlide[1] / 2,
                this.sliderType === 1 ? 50 : 1
              )
            },
            handle
          );

          // this.camera.on("click", event => {
          //   const handle = physics.castRay(this.camera.position, event);

          //   if (handle?.type === "slide" && handle.id) {
          //     const slideIdParts = handle.id.split(':');
          //     const contentType = slideIdParts[2];
          //     const url = slideIdParts[3];
  
          //     if (contentType === "video") {
          //       // window.location.search = `/video-player?url=${encodeURIComponent(url)}`;
          //       console.log("open modal", slideIdParts)
          //     } else if (contentType === "image") {
          //       // window.location.search = `/image-viewer?url=${encodeURIComponent(url)}`;
          //       console.log("open product", slideIdParts)
          //     }
          //   }
          // });
        }
        //Interactive block end
      }
    }
    this.gl.depthMask(true);

    // Render title
    const titleMatrix = mat4.create();
    mat4.fromRotationTranslationScale(
      this.titleMatrix,
      quat.fromEuler(
        quat.create(),
        this.rotationSlide[0],
        this.rotationSlide[1] + 180,
        this.rotationSlide[2]
      ),
      this.translationSlide,
      [1, 1, 1]
    );
    mat4.scale(titleMatrix, titleMatrix, [60, 15, 0]);
    mat4.translate(titleMatrix, titleMatrix, [0, 2.2, 0.1]);
    mat4.multiply(titleMatrix, this.titleMatrix, titleMatrix);

    this.titleGeometry.drawCall(this.program, {
      ...u,
      modelMatrix: titleMatrix,
      u_texture: this.titleTexture,
      transparency: 0,
    });
  }

  // free() {
  //   // Удаляем все physics bodies для слайдов
  //   const existingHandles = Array.from(physics.handles.values());
  //   existingHandles.forEach(handle => {
  //     if (handle && handle.id && typeof handle.id === 'string' &&
  //       handle.id.includes(this.id)) {
  //       physics.removeAABB(handle);
  //     }
  //   });

  //   if (this.animationId) {
  //     cancelAnimationFrame(this.animationId);
  //   }

  //   // remove arrows
  //   // const leftId = this.id + "l";
  //   // const rightId = this.id + "r";

  //   // const existingHandles = Array.from(physics.handles.values());

  //   // const leftArrow = existingHandles.find(h => h.id === leftId && h.type === 'slider');
  //   // const rightArrow = existingHandles.find(h => h.id === rightId && h.type === 'slider');

  //   // if (leftArrow) physics.removeAABB(leftArrow);
  //   // if (rightArrow) physics.removeAABB(rightArrow);
  // }
}

interface AdsSliderProps {
  id: string;
  sliderType: 1 | 2 | 3;
  sizeRatios: number[];
  sliderItems: { url: string; contentType: "video" | "image", path?: string }[];

  translation: vec3;
  rotation: vec3;

  translationSlide: vec3;
  rotationSlide: vec3;

  titleTexture: string;
}

export const AdsSlider: FC<AdsSliderProps> = ({
  translation,
  rotation,
  sliderItems,
  translationSlide,
  rotationSlide,
  titleTexture,
  sliderType,
  id,
  sizeRatios,
}) => {
  const size = (sliderType: 1 | 2 | 3) => {
    switch (sliderType) {
      case 1:
        return vec3.fromValues(25, 30, 25);
      case 2:
        return vec3.fromValues(30, 30, 30);
      case 3:
        return vec3.fromValues(36, 30, 36);
      default:
        return vec3.fromValues(30, 30, 30);
    }
  };

  const sizeSlide = (sliderType: 1 | 2 | 3) => {
    switch (sliderType) {
      case 1:
        return vec3.fromValues(120, 60, 1);
      case 2:
        return vec3.fromValues(140, 60, 1);
      case 3:
        return vec3.fromValues(180, 60, 1);
      default:
        return vec3.fromValues(140, 60, 1);
    }
  };

  const renderTaskRef = useRef<AdsSliderRenderTask | null>(null);

  const { gl } = useDC();
  const camera = useCamera();
  //   const frameTexture = useTexture("border0.png");
  const frameTexture = useTexture("ring1.jpg");
  const edgeMatrix = useTexture("border0.png");
  const programEdge = useSharedShaderProgram("textureRenderer");
  const matcap = useTexture("matcap-extra-white.png");
  const titleTextureResource = useTexture(titleTexture);
  const titleGeometry = useSharedGeometry("roundedQuad");
  const sharedGeometry = useSharedGeometry("screenP");
  const slideGeometry = useSharedGeometry("roundedQuad");
  const arrowTexture = useTexture("arrow.png");
  const program = useSharedShaderProgram("textureRenderer");
  const programSlider = useMemo(
    () =>
      new ShaderProgram(gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: adRingFragmentShader300,
        textures: { normalMap: frameTexture, matcap: matcap },
      }),
    [gl, frameTexture, matcap]
  );

  const resources = sliderItems.map(item =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useResource(item.url, item.contentType)
  );

  const textures = useMemo(() => {
    return resources.map(resource => resource as Texture);
  }, [resources]);

  const handleSlide = useCallback((direction: 1 | -1) => {
    if (renderTaskRef.current) {
      renderTaskRef.current.slide(direction);
    }
  }, []);

  const memoizedRenderTask = useMemo(
    () =>
      new AdsSliderRenderTask(gl, {
        camera,
        items: sliderItems,
        size: size(sliderType),
        translation: translation,
        rotation: rotation,
        sizeSlide: sizeSlide(sliderType),
        translationSlide: translationSlide,
        rotationSlide: rotationSlide,
        margin: 0,
        textures,
        titleTexture: titleTextureResource,
        titleGeometry,
        frameTexture,
        edgeMatrix,
        sharedGeometry,
        slideGeometry,
        arrowTexture,
        program,
        programSlider,
        programEdge,
        matcap,
        id,
        sliderType,
        sizeRatios,
      }),
    [
      gl,
      camera,
      sharedGeometry,
      slideGeometry,
      arrowTexture,
      program,
      textures,
      frameTexture,
      edgeMatrix,
      titleTextureResource,
      titleGeometry,
      id,
      sliderType,
      sizeRatios,
    ]
  );

  useEffect(() => {
    renderTaskRef.current = memoizedRenderTask;
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.free();
        renderTaskRef.current = null;
      }
    };
  }, [memoizedRenderTask]);

  useFrame(rl => {
    if (renderTaskRef.current) {
      renderTaskRef.current.render({
        projectionMatrix: camera.projectionMatrix,
        viewMatrix: camera.viewMatrix,
      });
    }
  });

  return null;
};
