import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

interface SideModalProps {
  position: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const SideModal: FC<SideModalProps> = ({ position, isOpen, onClose, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    const params = new URLSearchParams(location.search);
    params.delete('product');
    navigate({ pathname: location.pathname, search: params.toString() });
    onClose();
  };

  return (
    <div
      className={classNames(
        `fixed top-0 ${position}-0 h-full bg-white shadow-lg transition-transform transform z-40`,
        {
          '-translate-x-full': !isOpen && position==="left",
          'translate-x-full': !isOpen && position==="right",
          'translate-x-0': isOpen,
        },
        position === "right" ? 'w-1/3' : 'w-full',
        position === "right" ? 'sm:w-1/3' : 'sm:w-full',
        position === "right" ? 'md:w-1/4' : 'md:w-1/2',
        position === "right" ? 'lg:w-1/6' : 'lg:w-1/3',
        position === "right" ? 'xl:w-1/6' : 'xl:w-1/3',
        position === "right" ? '2xl:w-1/8' : '2xl:w-1/4',
      )}
    >
      
      {children}
      
      <button
        className="absolute top-2 right-2 bg-gray-200 rounded p-1"
        onClick={handleClose}
      >
        Close
      </button>
    </div>
  );
};