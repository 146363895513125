import { GLContext } from "features/draw-call/core/gl-utils";
import {
  NestedRenderTaskOptions,
  NestedRenderTask,
} from "features/draw-call/core/render-task/nested-render-task";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { mat4 } from "gl-matrix";

export interface FaceCameraOptions extends NestedRenderTaskOptions {
  camera: OrbitCamera;
}

export class FaceCamera extends NestedRenderTask {
  camera: OrbitCamera;
  modelMatrix: mat4;
  constructor(gl: GLContext, options: FaceCameraOptions) {
    super(gl, options);
    this.camera = options.camera;
    this.modelMatrix = mat4.identity(mat4.create());
  }
  render(u?: UniformValues): void {
    super.render();
    // face the camera
    mat4.copy(this.modelMatrix, this.camera.viewMatrix);
    // remove translation
    this.modelMatrix[12] = this.modelMatrix[13] = this.modelMatrix[14] = 0;
    // invert rotation
    mat4.invert(this.modelMatrix, this.modelMatrix);
    // done facing camera, apply the model matrix
    if (u?.modelMatrix) {
      mat4.multiply(this.modelMatrix, u.modelMatrix as mat4, this.modelMatrix);
    }
    this.renderChildren({ ...u, modelMatrix: this.modelMatrix });
  }
}
