import { GLTFPostprocessed } from "@loaders.gl/gltf";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import {
  FramebufferTextureCollection,
  GeometryCollection,
  ShaderProgramCollection,
  TextureCollection,
} from "features/draw-call/core/types";
import { primitiveToBuffers } from "features/draw-call/ext/glb-primitive";
import { clamp, PI, rad } from "features/draw-call/ext/math";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { useModel, useTexture } from "features/resource-loader";
import { mat4, vec3 } from "gl-matrix";
import { useFetch } from "hooks/fetch";
import { RouteParams } from "features/ui";
import { useParams } from "react-router-dom";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  memo,
} from "react";
import { depthFragmentShader } from "./shaders/depth-fragment-shader";
import { vertexShader, vertexShader300 } from "./shaders/vertex-shader";
import {
  instancedUvVertexShader300,
  instancedVertexShader,
  instancedVertexShader300,
} from "./shaders/instanced-vertex-shader";
import { shadowFragmentShader } from "./shaders/shadow-fragment-shader";
import { penumbraFragmentShader } from "./shaders/penumbra-fragment-shader";
import { debugVertexShader } from "./shaders/debug-vertex-shader";
import { modelMatrixVertexShader300 } from "./shaders/model-matrix-vertex-shader";
import { textureFragmentShader300 } from "./shaders/texture-fragment-shader";
import { clusterRingFragmentShader300 } from "./shaders/cluster-ring-fragment-shader";
import { uvDebugFragmentShader300 } from "./shaders/uv-debug-fragment-shader";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clusterThumbnailsFragmentShader300,
  loadingThumbnailsFragmentShader300,
} from "./shaders/cluster-thumbnails-fragment-shader";
import { sectorHoverFragmentShader300 } from "./shaders/sector-hover-fragment-shader";
import { useCreateSharedGeometries } from "./scene-context-hooks/geometries";
import {
  useCreateSharedFBT,
  useCreateSharedTextures,
} from "./scene-context-hooks/textures";
import { useCreateSharedShaders } from "./scene-context-hooks/shaders";
import { Loading } from "features/ui/loading";
import { Texture } from "features/draw-call/core/texture";
import { lerp } from "math";
import { CAMERA_FOV, CAMERA_NEAR, CAMERA_FAR } from "config";

// var target = DC.ext.vec3.fromValues(10, 0, 10);
// var target = vec3.fromValues(0, 0, 0);
// var position = vec3.fromValues(0, 0, -180);
// DC.ext.mat4.lookAt(u_view, [0, 6, 0], [2.2 * 5, 0, 2.2 * 5], [0, 1, 0]);

// DRY
const createProjectionMatrix = (m: mat4) => {
  mat4.perspective(
    m,
    CAMERA_FOV,
    window.innerWidth / window.innerHeight,
    CAMERA_NEAR,
    CAMERA_FAR
  );
};
export interface SceneContext {
  camera: OrbitCamera;
  sharedGeometries: GeometryCollection;
  sharedTextures: Map<string, Texture>; // we want this to be a map to show that shared textures can be freely set during runtime
  sharedFBT: FramebufferTextureCollection;
  sharedShaderPrograms: ShaderProgramCollection;
}

export const sceneContext = createContext<SceneContext>({} as SceneContext);

interface SceneContextProviderProps {
  children?: ReactNode;
  position: vec3;
  target: vec3;
}

// Custom debounce function
const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<number | undefined>();

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
};

export const SceneProvider: FC<SceneContextProviderProps> = ({
  children,
  position,
  target,
}) => {
  const { rl, gl, width, height, canvas } = useDC();
  const navigate = useNavigate();
  const location = useLocation();
  const projectionMatrix: mat4 = useMemo(() => {
    const m = mat4.create();
    createProjectionMatrix(m);
    return m;
  }, []);
  const camera = useMemo(
    () =>
      new OrbitCamera({
        position,
        target,
        projectionMatrix: mat4.clone(projectionMatrix),
        el: canvas,
        rl,
      }),
    []
  );

  const cameraPositionRef = useRef<vec3>(vec3.clone(position));
  const cameraTargetRef = useRef<vec3>(vec3.clone(target));

  useEffect(() => {
    // Set initial camera position and target from props
    camera.position = vec3.clone(position);
    camera.target = vec3.clone(target);
  }, [camera, position, target]);

  const debouncedUpdateUrl = useDebounce(
    (newPosition: vec3, newTarget: vec3, search: string) => {
      const params = new URLSearchParams(search);
      params.set("p", `${newPosition[0]},${newPosition[1]},${newPosition[2]}`);
      params.set("t", `${newTarget[0]},${newTarget[1]},${newTarget[2]}`);
      navigate({ search: params.toString() }, { replace: true });
    },
    250
  ); // Adjust the debounce delay as needed

  useFrame(() => {
    camera.update();
    const newPosition = vec3.clone(camera.position);
    const newTarget = vec3.clone(camera.target);

    if (
      !vec3.equals(newPosition, cameraPositionRef.current) ||
      !vec3.equals(newTarget, cameraTargetRef.current)
    ) {
      cameraPositionRef.current = newPosition;
      cameraTargetRef.current = newTarget;
      // debouncedUpdateUrl(newPosition, newTarget, window.location.search); //! url set OFF
    }
  });
  const sharedGeometries = useCreateSharedGeometries();
  const sharedTextures = useCreateSharedTextures();
  const sharedFBT = useCreateSharedFBT();
  const sharedShaderPrograms = useCreateSharedShaders();

  // 2d camera
  const orthoProjection = useMemo(() => mat4.create(), []);
  useEffect(() => {
    return camera.on("update", camera => {
      const { position, angleY } = camera;
      if (!camera.transitionValue) {
        mat4.copy(camera.projectionMatrix, projectionMatrix);
        return;
      }
      const h = position[1];
      const r = h * Math.tan(CAMERA_FOV / 2);
      camera.orthoR = r;
      mat4.ortho(
        orthoProjection,
        (-r * width) / height,
        (r * width) / height,
        -r,
        r,
        CAMERA_NEAR,
        CAMERA_FAR
      );
      for (let i = 0; i < 16; i++) {
        // mix projection matrix between perspective and ortho using angleY as a factor
        camera.projectionMatrix[i] = lerp(
          projectionMatrix[i],
          orthoProjection[i],
          Math.pow(camera.transitionValue, 0.125)
        );
      }
    });
  }, [width, height]);

  useEffect(() => {
    // update projection matrix when window size changes
    createProjectionMatrix(projectionMatrix);
  }, [width, height]);
  const { slug } = useParams<RouteParams>();
  if (!sharedTextures && slug) return <Loading />;
  return (
    <sceneContext.Provider
      value={{
        camera,
        sharedGeometries,
        sharedTextures,
        sharedFBT,
        sharedShaderPrograms,
      }}
    >
      {children}
    </sceneContext.Provider>
  );
};

// hook to use this context
export const useSceneContext = () => useContext(sceneContext);
export const useCamera = () => useContext(sceneContext).camera;
export const useSharedGeometry = (s: string) => {
  const g = useContext(sceneContext).sharedGeometries[s];
  if (!g) throw new Error(`geometry ${s} not found`);
  return g;
};
export const useSharedShaderProgram = (s: string) => {
  const p = useContext(sceneContext).sharedShaderPrograms[s];
  if (!p) throw new Error(`shader program ${s} not found`);
  return p;
};
export const useSharedShaderPrograms = (a?: string[]) => {
  const programs = useContext(sceneContext).sharedShaderPrograms;
  if (!a) return programs;
  const r: ShaderProgramCollection = {};
  a.forEach(s => {
    if (!programs[s]) throw new Error(`shader program ${s} not found`);
    r[s] = programs[s];
  });
  return r;
};
