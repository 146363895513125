export const debugVertexShader: string = `
    precision highp float;
    attribute vec3 POSITION;
    attribute vec3 NORMAL;
    attribute vec2 TEXCOORD_0;
    varying vec2 v_uv;
    varying vec3 v_normal;

    void main() {
        v_uv = TEXCOORD_0;
        v_normal = NORMAL;
        vec4 pos = vec4(POSITION, 1.0);
        gl_Position = pos;
    }
  `;
