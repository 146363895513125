import { useState, useEffect } from "react";
// import { useFetch, useAPI } from "hooks/fetch";
import { FC, ChangeEvent, KeyboardEvent } from "react";
import { RouteParams } from "features/ui";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { globalState } from "features/global-state/state-manager";
import {
  useSearchData,
  SearchDataItem,
} from "features/global-state/app-context";
import "./style.css";
import ImageSearch from "./img-search";
import useSearch from "../../../hooks/search";
import classNames from "classnames";

interface InputSearchProps {
  isOpen: boolean;
  glow: boolean;
  type: "search" | "menu";
  value: string;
  onClose: () => void | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
}

interface Category {
  id: number;
  name: string;
  setName: string;
}

export const InputSearch: FC<InputSearchProps> = ({
  glow,
  type,
  value,
  onChange,
  // onKeyPress,
  onClose,
  isOpen,
}) => {
  const { slug, sectorId, clusterId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("api/categories");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Convert the object to an array of categories
        const categoryArray = Object.values(data).map((category: any) => ({
          id: category.id,
          name: category.name,
          setName: category.setName, // Include setName in the mapping
        }));
        setCategories(categoryArray); // Set the categories state
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const { handleSearch } = useSearch();

  const handleClick = () => {
    if (type === "menu") {
      onClose();
    } else {
      // if(slug){
      //   setSearchParams({ search: value });
      // }
      performSearch();
      // handleSearch();
    }
  };

  const successfulSearch = () => {
    onClose();
    // onKeyPress():(event: KeyboardEvent<HTMLInputElement>) => void;
  };

  const performSearch = async () => {
    if (value) {
      await handleSearch(
        `/api/search/txt?context=${value}&limit=40`,
        {
          method: "POST",
          // mode: 'no-cors',
          headers: {
            Accept: "application/json",
          },
        },
        successfulSearch
      );
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") performSearch();
  };

  // const handleSearch = async () => {
  //   try {
  //     const response = await fetch(`/api/search/txt?context=${slug}&limit=40`, {
  //       method: 'POST',
  //       // mode: 'no-cors',
  //       headers: {
  //         'Accept': 'application/json',
  //       }
  //     });
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     globalState.setSearchData(data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };
  console.warn("InputSearchProps: isOpen, glow", isOpen, glow);
  return (
    <div
      className={classNames(
        `border-2 border-gray-400 absolute inline-flex items-center bg-white rounded-full shadow-lg transition-all duration-250`,
        {
          "w-[23rem] md:w-[32rem]": !glow,
          "right-[-50px]": isOpen && !glow,
          "left-1 right-[-39rem]": !isOpen && !glow,
          "w-[60rem] max-w-[90vw]": glow,
        }
      )}
    >
      {glow && <div className="glow"></div>}
      <select
        className="bg-[#A8A8A8] max-w-[100px] h-full py-4 px-4 rounded-l-full text-white appearance-none border-none focus:outline-none"
        style={{
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
        }}
      >
        <option value="all">All</option>
        {categories.length > 0 ? (
          categories.map(category => (
            <option key={category.id} value={category.setName}>
              {category.name}
            </option>
          ))
        ) : (
          <option value="loading" disabled>Loading...</option>
        )}
      </select>
      <div className="w-full bg-gray-100 p-2">
        <input
          type="text"
          className="py-2 w-full h-full px-4 text-gray-700 focus:outline-none bg-gray-100"
          placeholder="Enter your search term"
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
      <div className="h-full flex bg-gray-100 p-[6px]">
        <span className="w-[40px] h-[40px] bg-gray-100">
          <ImageSearch onClose={onClose} />
        </span>
      </div>
      <button
        className="h-full py-2 px-4 rounded-r-full flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:scale-105 accent-bg"
        onClick={handleClick}
      >
        <i className="fas fa-search text-white">
          {type === "search" ? (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="16.000000pt"
              height="27.000000pt"
              viewBox="0 0 1280.000000 1270.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
              </metadata>
              <g
                transform="translate(0.000000,1270.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path
                  d="M4895 12689 c-1613 -102 -3112 -968 -4012 -2319 -694 -1043 -991
                    -2273 -847 -3520 185 -1607 1121 -3058 2509 -3887 677 -405 1361 -632 2180
                    -723 156 -18 716 -24 895 -11 870 67 1729 349 2424 798 l69 45 1516 -1515
                    c834 -833 1532 -1523 1551 -1534 52 -27 193 -25 285 6 100 33 247 111 336 178
                    123 93 205 171 493 469 286 294 357 383 431 540 74 155 94 317 51 404 -10 19
                    -696 714 -1544 1562 -1479 1480 -1526 1529 -1513 1550 262 420 497 991 620
                    1508 151 636 181 1333 85 1995 -180 1243 -840 2421 -1814 3237 -1041 872
                    -2355 1303 -3715 1217z m590 -1739 c1099 -82 2065 -645 2673 -1560 310 -466
                    492 -968 564 -1560 17 -140 17 -602 -1 -745 -72 -597 -264 -1119 -588 -1595
                    -427 -626 -1048 -1102 -1753 -1342 -369 -125 -670 -178 -1070 -185 -271 -5
                    -440 7 -672 47 -1565 273 -2755 1574 -2889 3158 -15 179 -6 582 16 742 55 398
                    155 729 330 1085 183 374 396 670 695 964 706 696 1707 1064 2695 991z"
                />
              </g>
            </svg>
          ) : (
            // '☰'
            <svg
              width="16.000000pt"
              height="27.000000pt"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7M4 18h16"
              ></path>
            </svg>
          )}
        </i>
      </button>
    </div>
  );
};
