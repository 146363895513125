import {
  GLTFAccessor,
  GLTFBufferView,
  GLTFLoader,
  GLTFMesh,
  GLTFMeshPrimitive,
  GLTFWithBuffers,
  postProcessGLTF,
} from "@loaders.gl/gltf";
import { load } from "@loaders.gl/core";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  BufferCollection,
  IndexedBufferCollection,
} from "features/draw-call/core/types";
import {
  GLTFExternalBuffer,
  GLTFPostprocessed,
} from "@loaders.gl/gltf/dist/lib/types/gltf-types";

// load and postprocess glb
export async function loadGLB(url: string): Promise<GLTFPostprocessed> {
  const data = await load(url, GLTFLoader);
  // console.log(data);
  return postProcessGLTF(data);
}
