import { vertexShader, vertexShader300 } from "./vertex-shader";

export const instancedVertexShader: string = vertexShader.replace(
  "vec4 pos = vec4(POSITION, 1.0);",
  "vec4 pos = vec4(POSITION * INSTANCE_SCALE + INSTANCE_POSITION, 1.0);"
);

export const instancedVertexShader300: string = vertexShader300.replace(
  "vec4 pos = vec4(POSITION, 1.0);",
  "vec4 pos = vec4(POSITION * INSTANCE_SCALE + INSTANCE_POSITION, 1.0);"
);

export const instancedUvVertexShader300: string =
  instancedVertexShader300.replace(
    "v_uv = TEXCOORD_0;",
    "v_uv = TEXCOORD_0 / N + INSTANCE_UV;"
  );
