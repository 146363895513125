import { Sector } from "features/global-state/app-context";

export function sectorCoords(sector: Sector) {
  const min = { x: Infinity, y: Infinity };
  const max = { x: -Infinity, y: -Infinity };
  for (const cluster of sector.clusters) {
    min.x = Math.min(min.x, cluster.x - cluster.r);
    min.y = Math.min(min.y, cluster.y - cluster.r);
    max.x = Math.max(max.x, cluster.x + cluster.r);
    max.y = Math.max(max.y, cluster.y + cluster.r);
  }
  const width = max.x - min.x;
  const height = max.y - min.y;
  const center = { x: min.x + width / 2, y: min.y + height / 2 };
  const radius = Math.max(width, height) / 2;
  return { center, width, height, radius };
}
