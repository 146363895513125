import {
  GLTFMeshPrimitive,
  GLTFMeshPrimitivePostprocessed,
  GLTFWithBuffers,
  postProcessGLTF,
} from "@loaders.gl/gltf";
import { BufferGeometry } from "features/draw-call/core/geometry/buffer-geometry";
import {
  ElementsBufferGeometry,
  ElementsBufferGeometryOptions,
} from "features/draw-call/core/geometry/elements-buffer-geometry";
import { InstancedElements } from "features/draw-call/core/geometry/instanced-elements";
import { GLContext } from "features/draw-call/core/gl-utils";
import { VertexBufferCollection } from "features/draw-call/core/types";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

// construct vertex buffers from glb primitive data
export function primitiveToBuffers(
  gl: GLContext,
  primitive: GLTFMeshPrimitivePostprocessed
): ElementsBufferGeometryOptions {
  const vertexBuffers: VertexBufferCollection = {};
  for (let key in primitive.attributes) {
    const a = primitive.attributes[key];
    vertexBuffers[key] = new VertexBuffer(gl, {
      data: a.value,
      size: a.components,
      type: a.componentType,
      offset: a.byteOffset,
    });
  }
  const indexBuffer = new VertexBuffer(gl, {
    data: primitive.indices!.value,
    size: 1,
    type: primitive.indices!.componentType,
    offset: primitive.indices!.byteOffset,
    target: gl.ELEMENT_ARRAY_BUFFER,
  });
  return { vertexBuffers, indexBuffer, count: primitive.indices!.count };
}
