import { vec3, vec4 } from "gl-matrix";
import { bindOutput } from "features/draw-call/core/output";
import { GLContext } from "../gl-utils";
import {
  GeometryCollection,
  OutputFramebuffer,
  ShaderProgramCollection,
  UniformValue,
  UniformValues,
} from "../types";
import { perfmon } from "features/perfmon";

export interface RenderTaskOptions {
  geometries?: GeometryCollection;
  shaderPrograms?: ShaderProgramCollection;
  output?: OutputFramebuffer;
}

// a render task is a collection of logically connected geometries and shader programs
// one might meed more than one shader or geometry to draw something
export class RenderTask {
  created: boolean = false;
  updated: boolean = false;
  geometries: GeometryCollection = {};
  shaderPrograms: ShaderProgramCollection = {};
  output?: OutputFramebuffer;
  gl: GLContext;
  constructor(gl: GLContext, options?: RenderTaskOptions) {
    this.gl = gl;
    Object.assign(this, options || {});
  }
  create() {
    perfmon.start("createVertexBuffers");
    for (let key in this.geometries) {
      this.geometries[key].createVertexBuffers();
    }
    perfmon.stop("createVertexBuffers");
    for (let key in this.shaderPrograms) {
      this.shaderPrograms[key].compile();
    }
    this.created = true;
  }
  free() {
    for (let key in this.geometries) {
      this.geometries[key].freeVertexBuffers();
    }
    for (let key in this.shaderPrograms) {
      this.shaderPrograms[key].free();
    }
    this.created = false;
    this.updated = false;
  }
  render(x?: UniformValues) {
    if (!this.created) this.create();
    bindOutput(this.gl, this.output);
  }
  // bind output framebuffer
  bindOutput(output?: OutputFramebuffer) {
    bindOutput(this.gl, output || this.output);
  }

  isInFrustum(planes: vec4[], position: vec3, radius: number): boolean {
    for (let i = 0; i < 6; i++) {
      const distance = planes[i][0] * position[0] + planes[i][1] * position[1] + planes[i][2] * position[2] + planes[i][3];
      if (distance < -radius) {
        return false;
      }
    }
    return true;
  }
}
