import {
  AttributeLocationCollection,
  ImageCollection,
  TextureCollection,
  UniformLocationCollection,
  UniformTypes,
  UniformValues,
} from "../types";
import {
  GLContext,
  TexturePixels,
  createProgram,
  createShader,
  createTexture,
  getAttribLocations,
  getUniformLocations,
} from "../gl-utils";
import { TYPE_SETTERS } from "features/draw-call/core/constants";
import { perfmon } from "features/perfmon";
import { Texture } from "../texture";

export interface ShaderProgramOptions {
  vertexShader: string;
  fragmentShader: string;
  textures?: TextureCollection;
}

// this class controls one shader program and its uniforms, including textures
export class ShaderProgram {
  gl: GLContext;
  protected _program?: WebGLProgram;
  protected _vertexShader?: WebGLShader;
  protected _fragmentShader?: WebGLShader;
  protected _uniformLocations?: UniformLocationCollection;
  protected _uniformNames: string[] = [];
  protected _textureNames: string[] = [];
  protected _matrixUniforms: UniformTypes = {};
  protected _textureUniforms: UniformTypes = {};
  protected _attributeNames: string[] = [];
  attributeLocations: AttributeLocationCollection = {};
  vertexShader: string = "";
  fragmentShader: string = "";
  textures: TextureCollection = {};
  uniforms: UniformTypes = {};
  compiled = false;

  constructor(gl: GLContext, options?: ShaderProgramOptions) {
    this.gl = gl;
    Object.assign(this, options || {});

    this._attributeNames = [];
  }
  // compile the shaders and link the program
  compile() {
    if (this.compiled) return;
    perfmon.start("compilingShaders");
    this._vertexShader = createShader(
      this.gl,
      this.gl.VERTEX_SHADER,
      this.vertexShader
    )!;
    this._fragmentShader = createShader(
      this.gl,
      this.gl.FRAGMENT_SHADER,
      this.fragmentShader
    )!;
    this._program = createProgram(
      this.gl,
      this._vertexShader,
      this._fragmentShader
    )!;
    perfmon.stop("compilingShaders");

    this.updateTextureNames();

    // get attribute names and locations
    perfmon.start("gatheringAttributes");
    const gl = this.gl;
    const program = this._program;
    const numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
    for (let i = 0; i < numAttributes; i++) {
      const info = gl.getActiveAttrib(program, i)!;
      const attributeName = info.name;
      this._attributeNames.push(attributeName);
    }
    this.attributeLocations = getAttribLocations(
      this.gl,
      this._program,
      ...this._attributeNames
    );
    perfmon.stop("gatheringAttributes");

    // get uniform names and locations
    perfmon.start("gatheringUniforms");
    const numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
    this.uniforms = {};
    for (let i = 0; i < numUniforms; i++) {
      const info = gl.getActiveUniform(program, i)!;
      const uniformName = info.name;
      const uniformType = info.type;
      // console.log("Uniform name:", uniformName, "Type:", uniformType);
      const ts = TYPE_SETTERS[uniformType];
      if (typeof ts !== "undefined") {
        this._uniformNames.push(uniformName);
        this.uniforms[uniformName] = ts;
      } else {
        // console.log(info.name, info.type);
        // throw new Error(`Unrecognized uniform type ${uniformType}`);
        // let's assume it's a texture instead for now
        // TODO: properly recognize all possible types
      }
    }
    // matrix type uniforms and textures have special treatment
    this._matrixUniforms = {};
    this._uniformNames
      .filter(name => this.uniforms[name].startsWith("Matrix"))
      .forEach(name => {
        this._matrixUniforms[name] = this.uniforms[name];
      });
    this._textureUniforms = {};
    this._uniformNames
      .filter(name => this.uniforms[name] === "Texture")
      .forEach(name => {
        this._textureUniforms[name] = this.uniforms[name];
      });
    // obtain uniform locations
    this._uniformLocations = getUniformLocations(
      this.gl,
      this._program,
      ...this._uniformNames
    );

    perfmon.stop("gatheringUniforms");

    this.compiled = true;
  }
  // add constructor-defined textures to a special array
  updateTextureNames() {
    this._textureNames = Object.keys(this.textures);
  }
  // set one texture uniform
  setTextureUniform(i: number, name: string, texture: Texture) {
    if (!texture.updated) texture.update();
    this.gl.activeTexture(this.gl.TEXTURE0 + i);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture.texture);
    this.gl.uniform1i(this._uniformLocations![name]!, i);
  }
  // set uniforms for textures in this.textures
  setPredefinedTextureUniforms() {
    for (let i = 0; i < this._textureNames.length; i++) {
      const name = this._textureNames[i];
      // console.log("Setting texture", name, this._textureUniformLocations);
      const texture = this.textures[name];
      this.setTextureUniform(i, name, texture);
    }
  }
  // set uniform values
  setUniforms(x?: UniformValues) {
    if (!x) return;
    let tcounter = 0;
    for (let i = 0; i < this._uniformNames.length; i++) {
      const name = this._uniformNames[i];
      const type = this.uniforms[name];
      const location = this._uniformLocations![name];
      const value = x[name];
      // continue if no value was provided
      if (typeof value === "undefined") continue;
      if (this._textureUniforms[name]) {
        // set texture uniforms
        // first slots are reserved for predefined textures
        this.setTextureUniform(
          this._textureNames.length + tcounter,
          name,
          value as Texture
        );
        tcounter++;
      } else if (this._matrixUniforms[name]) {
        // @ts-expect-error
        this.gl[`uniform${type}`](location!, false, value as Float32Array);
      } else {
        // @ts-expect-error
        this.gl[`uniform${type}`](location!, value);
      }
    }
  }
  // prepare the render
  prepare(x?: UniformValues) {
    if (!this.compiled) this.compile();
    this.gl.useProgram(this._program!);
    this.setPredefinedTextureUniforms();
    this.setUniforms(x);
  }
  // free gpu resources
  free() {
    if (this._program) this.gl.deleteProgram(this._program);
    if (this._vertexShader) this.gl.deleteShader(this._vertexShader);
    if (this._fragmentShader) this.gl.deleteShader(this._fragmentShader);
    if (this.attributeLocations) {
      for (let key in this.attributeLocations) {
        this.gl.disableVertexAttribArray(this.attributeLocations[key]);
      }
    }
    this.compiled = false;
  }

  public getProgram(): WebGLProgram {
    if (!this._program) {
      throw new Error("Shader program is not compiled yet.");
    }
    return this._program;
  }
}
