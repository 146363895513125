import { vec3 } from "gl-matrix"
import { AdsSlider } from './slider'
// import { useSectors } from "features/global-state/app-context";
// import { useEffect } from "react";
// import { useParams } from "react-router-dom";

export const Sliders = () => {
    // const sectors = useSectors();
    // const {slug, sectorId} = useParams();
    
    const sliderItems = [
        { url: "images/test-ads-0.jpg", contentType: "image" as const, path: "B0C9DH1VX6" },
        // { url: "RQunyTO0-ZM__05_05_1.1", contentType: "video" as const },
        { url: "videos/CNBC-stream.mp4", contentType: "video" as const, path: "M5a_z6wCAyw" },
        { url: "images/test-ads-2.jpg", contentType: "image" as const, path: "B0CKND4NMF" },
        { url: "videos/stream-1.mp4", contentType: "video" as const, path: "n_Xvee-GyZY" },
        { url: "images/test-ads-1.jpg", contentType: "image" as const, path: "B0CZ9QRYKX" },
        { url: "videos/stream-2.mp4", contentType: "video" as const, path: "7X1lVQ0mFLo" }, 
        { url: "images/test-ads-2.jpg", contentType: "image" as const, path: "B0C9DH1VX6" },
    ];

    const slidersBlogers = [
        { url: "videos/prev/youtube/video_youtube/gjDmb2Awn9U__05_05_1.1.webm", contentType: "video" as const, path: "gjDmb2Awn9U" },
        { url: "videos/prev/youtube/9ewOSGfVqOM__05_05_1.1.webm", contentType: "video" as const, path: "9ewOSGfVqOM" },
        { url: "videos/prev/youtube/9pMOSETgGB8__05_05_1.1.webm", contentType: "video" as const, path: "9pMOSETgGB8" },
        { url: "videos/prev/youtube/9QxuoeMkX78__05_05_1.1.webm", contentType: "video" as const, path: "9QxuoeMkX78" },
        { url: "videos/prev/youtube/3SHRqdMVc8k__05_05_1.1.webm", contentType: "video" as const, path: "3SHRqdMVc8k" },
        { url: "videos/prev/youtube/2qgbhsS6p34__05_05_1.1.webm", contentType: "video" as const, path: "2qgbhsS6p34" },
        { url: "videos/prev/youtube/9WFWV_n04KU__05_05_1.1.webm", contentType: "video" as const, path: "9WFWV_n04KU" },
        { url: "videos/prev/youtube/9na_Npb1kLU__05_05_1.1.webm", contentType: "video" as const, path: "9na_Npb1kLU" }, 
    ];

    // https://www.youtube.com/watch?v=iGGtTaVgFqE
    // http://100.123.239.23/videos/prev/youtube/7X1lVQ0mFLo__05_05_1.1.webm
    // https://www.youtube.com/watch?v=7X1lVQ0mFLo
    //! videos/prev/yotube/video_yotube

    const slidersSales = [
        { url: "images/test-ads-2.jpg", contentType: "image" as const, path: "B0CKND4NMF" },
        { url: "images/test-ads-6.jpg", contentType: "image" as const, path: "B09K7TRBWG" },
        { url: "images/test-ads-1.jpg", contentType: "image" as const, path: "B0CZ9QRYKX" },
        { url: "images/test-ads-0.jpg", contentType: "image" as const, path: "B0C9DH1VX6" },
    ];

    const slidersFashion = [
        { url: "videos/prev/youtube/video_youtube/Kf5mcnnT_hk__05_05_1.1.webm", contentType: "video" as const, path: "Kf5mcnnT_hk" },
        { url: "videos/prev/youtube/video_youtube/NtG7xnVwQ8c__05_05_1.1.webm", contentType: "video" as const, path: "NtG7xnVwQ8c" },
        { url: "videos/prev/youtube/video_youtube/gfarK2fTGE4__05_05_1.1.webm", contentType: "video" as const, path: "gfarK2fTGE4" }, 
    ];

    // const slidesBloggers = sectors && sectorId ? sectors[+sectorId]?.videos.map((videoUrl:string) => ({
    //     url: videoUrl, 
    //     contentType: "youtube" as const
    // })) : [];

    // const isValidYouTubeUrl = (url: string) => {
    //     const match = url.match(/v=([^&]+)/);
    //     return match ? `https://www.youtube.com/embed/${match[1]}` : null;
    // };

    return(
        <>
            <AdsSlider
                id={"slider-1"}
                sliderType={2} sizeRatios={[50, 50]}
                sliderItems={slidersBlogers}

                translation = {vec3.fromValues(180, 37, 252)}
                rotation = {vec3.fromValues(0, -75, 0)}
                
                translationSlide = {vec3.fromValues(186, 30, 257)}
                rotationSlide = {vec3.fromValues(0, 49, 0)}
                
                titleTexture="images/slider-title/bloggers.png"
            />
            <AdsSlider
                id={"slider-2"}
                sliderType={3} sizeRatios={[25, 50, 25]}
                sliderItems={sliderItems}

                translation = {vec3.fromValues(5, 37, 328)}
                rotation = {vec3.fromValues(0, -123, 0)}

                translationSlide = {vec3.fromValues(5, 30, 340)}
                rotationSlide = {vec3.fromValues(0, 1.5, 0)}

                titleTexture="images/slider-title/sponsors.png"
            />

            <AdsSlider
                id={"slider-3"}
                sliderType={2} sizeRatios={[50, 50]}
                sliderItems={slidersSales}

                translation = {vec3.fromValues(-170, 37, 260)}
                rotation = {vec3.fromValues(0, -171, 0)}

                translationSlide = {vec3.fromValues(-174, 30, 266)}
                rotationSlide = {vec3.fromValues(0, -46, 0)}

                titleTexture="images/slider-title/sales.png"
            />

            <AdsSlider
                id={"slider-4"}
                sliderType={1} sizeRatios={[100]}
                sliderItems={slidersFashion}
                
                translation = {vec3.fromValues(-250, 37, 100)} //-238, 37, 110
                rotation = {vec3.fromValues(0, 160, 0)} //, 148, 0

                translationSlide = {vec3.fromValues(-254, 30, 100)} //-240, 30, 111
                rotationSlide = {vec3.fromValues(0, -75, 0)} //0, -87, 0

                titleTexture="images/slider-title/fashion.png"
            />
        </>
    )
}