import { ArrayBufferGeometry } from "./array-buffer-geometry";

import { VertexBuffer } from "features/draw-call/core/vertex-buffer";
import { manyCopiesOfArray } from "./array-transforms";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  defaultTriangleNormals,
  defaultTriangleTexCoords,
  defaultTriangleVertices,
} from "./triangle-geometry";

// this class generates multiple quads
// useful for building cube arrays and particles
export class TriangleArrayGeometry extends ArrayBufferGeometry {
  constructor(gl: GLContext, count: number = 1) {
    super(gl, {
      vertexBuffers: {
        POSITION: new VertexBuffer(gl, {
          data: manyCopiesOfArray(defaultTriangleVertices, count),
          size: 3,
        }),
        NORMAL: new VertexBuffer(gl, {
          data: manyCopiesOfArray(defaultTriangleNormals, count),
          size: 3,
        }),
        TEXCOORD_0: new VertexBuffer(gl, {
          data: manyCopiesOfArray(defaultTriangleTexCoords, count),
          size: 2,
        }),
      },
      count: 3 * count,
    });
  }
}
