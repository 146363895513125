export const groundFragmentShader: string = `#version 300 es
    precision highp float;

    in vec2 v_uv;
    uniform float fadeWidth;

    out vec4 FragColor;

    void main() {
        vec2 center = vec2(0.5, 0.5);
        float distanceFromCenter = distance(v_uv, center);
        float alpha = smoothstep(0.3 - fadeWidth, 0.4, distanceFromCenter);
        vec3 color = vec3(0.13, 0.13, 0.14);
        FragColor = vec4(color, alpha);
    }  
`;