import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { mat4, vec3 } from "gl-matrix";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useAppState } from "features/global-state/app-context";
import { modelMatrixVertexShader300 } from "../shaders/advertising-banner-vertex-shader";
import { ABannerFragmentShader300 } from "../shaders/advertising-banner-fragment-shader";
import { useCamera, useSharedGeometry } from "../scene-context";
import { BufferGeometry } from "features/draw-call/core/geometry/buffer-geometry";
import { Texture } from "features/draw-call/core/texture";
import { useTexture } from "features/resource-loader";
import { UniformValues } from "features/draw-call/core/types";
import { physics } from "features/physics";

interface AdvertisingPictureOptions {
  sharedGeometry: BufferGeometry;
  texture: Texture;
  camera: OrbitCamera;
  rotation?: vec3;
  translation?: vec3;
  scale?: vec3;
  active?: boolean;
  id: string;
}

class AdvertisingPictureRenderer extends RenderTask {
  camera: OrbitCamera;
  texture: Texture;
  modelMatrix: mat4;
  _modelMatrix: mat4;
  rotation: vec3;
  translation: vec3;
  scale: vec3;
  active: boolean;
  alpha: number;
  startTime: number;
  id: string;

  constructor(gl: WebGL2RenderingContext, options: AdvertisingPictureOptions) {
    super(gl);
    this.camera = options.camera;
    this.texture = options.texture;
    this.rotation = options.rotation || vec3.create();
    this.translation = options.translation || vec3.create();
    this.scale = options.scale || vec3.create();
    this.active = options.active || false;
    this.alpha = 0;
    this.startTime = performance.now();
    this.id = options.id;

    this.shaderPrograms.main = new ShaderProgram(gl, {
      vertexShader: modelMatrixVertexShader300,
      fragmentShader: ABannerFragmentShader300,
      textures: { u_texture: this.texture },
    });
    this.geometries.main = options.sharedGeometry;

    this.modelMatrix = rotationTranslationScaleMatrix(
      mat4.create(),
      this.rotation,
      this.translation,
      this.scale
    );
    this._modelMatrix = mat4.clone(this.modelMatrix);
    
    this.initializeShaderAndGeometry(options);
    this.updateModelMatrix();
    this.addPhysicsBody();
  }

  // addPhysicsBody() {
  //   // Check if the animation is starting
  //   const isBannerPresent = physics.checkIfExists("type", "banner");
  //   const type = isBannerPresent ? 'favorites' : 'banner';

  //   physics.addAABB(
  //     {
  //       position: this.translation,
  //       scale: vec3.fromValues(50, 50, 50)
  //     },
  //     { id: type === 'banner' ? this.id : 'favorites', type }
  //   );
  // }
  addPhysicsBody() {
    physics.removeAABB({ id: 'favorites', type: 'favorites' });

    physics.addAABB(
      {
        position: this.translation,
        scale: vec3.fromValues(50, 50, 50)
      },
      { id: this.id, type: 'banner' }
    );
  }

  removePhysicsBody() {
    // Удаляем banner
    physics.removeAABB({ id: this.id, type: 'banner' });

    // Возвращаем favorites
    physics.addAABB(
      {
        position: this.translation,
        scale: vec3.fromValues(50, 50, 50)
      },
      { id: 'favorites', type: 'favorites' }
    );
  }



  private initializeShaderAndGeometry(options: AdvertisingPictureOptions) {
    if (!this.gl) {
      console.error('WebGL context is not available');
      return;
    }

    try {
      this.shaderPrograms.main = new ShaderProgram(this.gl, {
        vertexShader: modelMatrixVertexShader300,
        fragmentShader: ABannerFragmentShader300,
        textures: { u_texture: this.texture },
      });
      this.geometries.main = options.sharedGeometry;
    } catch (error) {
      console.error('Error initializing shader or geometry:', error);
    }
  }

  private updateModelMatrix() {
    console.log('Updating model matrix with scale:', this.scale);
    this.modelMatrix = rotationTranslationScaleMatrix(
      mat4.create(),
      this.rotation,
      this.translation,
      this.scale
    );
    this._modelMatrix = mat4.clone(this.modelMatrix);
  }

  updateProperties(options: Partial<AdvertisingPictureOptions>) {
    let needsUpdate = false;

    if (options.scale && !vec3.equals(this.scale, options.scale)) {
      this.scale = options.scale;
      needsUpdate = true;
    }
    if (options.rotation && !vec3.equals(this.rotation, options.rotation)) {
      this.rotation = options.rotation;
      needsUpdate = true;
    }
    if (options.translation && !vec3.equals(this.translation, options.translation)) {
      this.translation = options.translation;
      needsUpdate = true;
    }

    if (needsUpdate) {
      this.updateModelMatrix();
      this.updatePhysicsBody();
    }
  }

  private updatePhysicsBody() {
    physics.removeAABB({ id: this.id, type: 'banner' });
    this.addPhysicsBody();
  }

  setAlpha(alpha: number): void {
    this.alpha = alpha;
  }

  render(u?: UniformValues): void {
    super.render();
    const gl = this.gl;
    const currentTime = (performance.now() - this.startTime) / 1000;

    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      modelMatrix: this._modelMatrix,
      u_alpha: this.alpha,
      u_time: currentTime,
    };
    gl.depthMask(false);
    gl.depthFunc(gl.LESS);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.depthFunc(gl.LEQUAL);
    gl.depthMask(true);
  }

  // removePhysicsBody() {
  //   physics.removeAABB({ id: this.id, type: 'banner' });
  // }


  // free() {
  //   physics.removeAABB({ id: this.id, type: 'banner' });
  // }
}

interface AdvertisingComponentProps {
  rounded?: boolean;
  url: string;
  rotation?: vec3;
  translation?: vec3;
  scale?: vec3;
  active?: boolean;
  delay: number;
  appearance: number;
  pause: number;
  attenuation: number;
  maxAlpha: number;
}

export const AdvertisingComponent: FC<AdvertisingComponentProps> = ({
  rounded,
  url,
  rotation,
  translation,
  scale,
  active,
  delay,
  appearance,
  pause,
  attenuation,
  maxAlpha,
}) => {
  const { gl } = useDC();
  const camera = useCamera();
  const sharedGeometry = useSharedGeometry(rounded ? "roundedQuad" : "quad");
  const texture = useTexture(url);
  const bannerId = useMemo(() => `banner-${Math.random().toString(36).substr(2, 9)}`, []);
  const animationFrameRef = useRef<number>();
  const renderTaskRef = useRef<AdvertisingPictureRenderer | null>(null);
  const physicsAddedRef = useRef<boolean>(false);
  const isAnimationComplete = useRef<boolean>(false);
  const cleanupTimeoutRef = useRef<NodeJS.Timeout>();
  const [, forceUpdate] = useState({});


  useEffect(() => {
    const initialDelay = setTimeout(() => {
      if (!renderTaskRef.current) {
        const renderTask = new AdvertisingPictureRenderer(gl, {
          sharedGeometry,
          texture,
          camera,
          rotation,
          translation,
          scale,
          active,
          id: bannerId,
        });
        renderTaskRef.current = renderTask;
      }

      let startTime: number | null = null;
      
      const cleanup = () => {
        if (renderTaskRef.current && physicsAddedRef.current) {
          renderTaskRef.current.removePhysicsBody();
          physicsAddedRef.current = false;
          // Принудительный ререндер после удаления физического тела
          forceUpdate({});
        }
        if (renderTaskRef.current) {
          renderTaskRef.current = null;
        }
      };
    
      const animate = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
    
        if (!renderTaskRef.current) return;
    
        if (elapsed >= delay && !physicsAddedRef.current) {
          renderTaskRef.current.addPhysicsBody();
          physicsAddedRef.current = true;
        }
    
        const totalDuration = delay + appearance + pause + attenuation;
        
        if (elapsed < delay) {
          renderTaskRef.current.setAlpha(0);
        } else if (elapsed < delay + appearance) {
          const progress = (elapsed - delay) / appearance;
          renderTaskRef.current.setAlpha(Math.min(progress * maxAlpha, maxAlpha));
        } else if (elapsed < delay + appearance + pause) {
          renderTaskRef.current.setAlpha(maxAlpha);
        } else if (elapsed < totalDuration) {
          const progress = (elapsed - (delay + appearance + pause)) / attenuation;
          renderTaskRef.current.setAlpha(Math.max(maxAlpha * (1 - progress), 0));
        } else if (!isAnimationComplete.current) {
          isAnimationComplete.current = true;
          renderTaskRef.current.setAlpha(0);
          
          // Немедленно вызываем cleanup после завершения анимации
          cleanup();
          return;
        }
    
        animationFrameRef.current = requestAnimationFrame(animate);
      };
    
      animationFrameRef.current = requestAnimationFrame(animate);
    }, 2000);
  
    return () => {
      clearTimeout(initialDelay);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (renderTaskRef.current && physicsAddedRef.current) {
        renderTaskRef.current.removePhysicsBody();
        physicsAddedRef.current = false;
      }
      renderTaskRef.current = null;
    };
  }, [gl, sharedGeometry, texture, camera, bannerId, delay, appearance, pause, attenuation, maxAlpha]);

  useFrame(() => {
    if (renderTaskRef.current && !isAnimationComplete.current) {
      renderTaskRef.current.render();
    }
  });

  return null;
};


interface BannerProps {
  url: string;
}

export const AdvertisingBanner: FC<BannerProps> = ({ url }) => {

  return (
    <AdvertisingComponent
      url={url}
      rounded={true}
      scale={[15, 15, 0]}
      rotation={[Math.PI / 2, 0, 0]}
      translation={[0, 0.3, 0]}
      active={true}
      delay={0}
      appearance={4000}
      pause={3000}
      attenuation={4000}
      maxAlpha={0.3}
    />
  );
};