// import React, { useState, useEffect } from "react";
// import { ProductModal } from "../product-modal";
// import { ProductSlider } from "./product-slider";
// import { ReactComponent as LikeIcon } from "../../icons/like-mini.svg";
// import { ReactComponent as StarIcon } from "../../icons/star-3.svg";
// import { LoadingHTML } from "features/ui/loading";
// import { useParams, useSearchParams } from "react-router-dom";

// interface Color {
//   url: string;
//   color: string;
// }

// interface Size {
//   size: string;
//   price: number;
// }

// interface Product {
//   name: string;
//   price: number;
//   sales: number;
//   store: string;
//   rating: number;
//   reviews: number;
//   colors: Color[];
//   sizes: Size[];
//   images: string[];
//   details: string;
//   reviewsText: string;
// }

// export const ProductCard: React.FC = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [liked, setLiked] = useState(false);
//   const [currentColor, setCurrentColor] = useState("white");
//   const [selectedSize, setSelectedSize] = useState("");
//   const [activeTab, setActiveTab] = useState("details");
//   const [product, setProduct] = useState<Product | null>(null);
//   // const [searchParams] = useSearchParams();
//   // const productArcticle = searchParams.get("product") ?? null;
//   function getRandomInt(max:number) {
//     return Math.floor(Math.random() * max);
//   }

//   useEffect(() => {
//     const fetchProduct = async () => {
//       const url = new URL(window.location.href);
//       const params = new URLSearchParams(url.search);
//       const productId = params.get("product");

//       if (productId) {
//         const response = await fetch("/data/products.json");
//         const data: Product[] = await response.json();
//         // setProduct(data[+productId]);
//         // setProduct( data.find(data => data["id"] === productArcticle ) );
//         setProduct(data[getRandomInt(20)]);
//       } else {
//         console.error("Product ID is missing in the URL");
//         // Handle the case where productId is null, e.g., show an error message or redirect
//       }
//     };

//     fetchProduct();
//   }, [window.location.search]);

//   if (!product) {
//     return <LoadingHTML />;
//   }

//   const handleLikeClick = () => {
//     setLiked(!liked);
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <div className="h-screen bg-white rounded-lg shadow-md overflow-y-auto w-full">
//         <ProductSlider images={product.images} />

//         <div className="p-4 h-1/2 flex flex-col justify-between">
//           <div className="relative w-full mt-4 text-center">
//             <div className="flex w-full justify-end mt-4">
//               <span className="accent-bg text-white rounded-full px-3 py-1">
//                 +{product.sales} SALES
//               </span>
//             </div>

//             <div className="flex w-full items-center justify-between space-x-4">
//               <p className="text-gray-500">Visit the {product.store}</p>

//               <div className="flex items-center justify-center">
//                 <StarIcon className="text-yellow-500 w-12 h-12" />
//                 <span className="text-xl font-[600]"> {product.rating}</span>
//                 <span className="ml-2 text-gray-500">{product.reviews}</span>
//               </div>
//             </div>

//             <h2 className="text-lg text-left font-[600] pb-4">
//               {product.name}
//             </h2>

//             <div className="flex items-center justify-between space-x-4">
//               <div className="text-4xl font-[700]">
//                 ${product.price.toFixed(2)}
//               </div>
//               <button
//                 className="px-4 py-2 accent-bg text-white rounded-full flex items-center hover:opacity-75"
//                 onClick={handleOpenModal}
//               >
//                 <LikeIcon className="mr-2" />
//                 Go to web site
//               </button>
//             </div>

//             <button
//               onClick={handleLikeClick}
//               className={`absolute top-[-80px] right-2 transform translate-y-1/2 p-2 rounded-full
//                 ${liked ? "bg-red-500" : "bg-white"}
//                 w-12 h-12 flex items-center justify-center
//                 border border-gray-300
//                 `}
//             >
//               <LikeIcon />
//             </button>
//           </div>

//           <div className="mt-4">
//             <h3 className="text-lg font-semibold pb-2">
//               <span className="font-[600]">Select Color:</span> {currentColor}
//             </h3>
//             <div className="mt-2 flex space-x-2 overflow-x-auto">
//               {product.colors.map((color, index) => (
//                 <button
//                   key={index}
//                   onClick={() => setCurrentColor(color.color)}
//                   className={`border rounded overflow-hidden ${
//                     currentColor === color.color
//                       ? "accent-border"
//                       : "border-gray-300"
//                   }`}
//                   style={{ width: "50px", height: "66px" }}
//                 >
//                   <img
//                     src={color.url}
//                     alt={`color ${color.color}`}
//                     className="w-full h-full object-cover"
//                   />
//                 </button>
//               ))}
//             </div>
//           </div>
//           <div className="mt-4">
//             <h3 className="text-lg font-[600] pb-2">Size</h3>
//             <div className="mt-2 grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
//               {product.sizes.map(size => (
//                 <button
//                   key={size.size}
//                   className={`px-4 py-1 border rounded ${
//                     selectedSize === size.size
//                       ? "accent-border"
//                       : "border-gray-300"
//                   }`}
//                   onClick={() => setSelectedSize(size.size)}
//                 >
//                   {size.size} ${size.price}
//                 </button>
//               ))}
//             </div>
//           </div>

//           <div className="mt-4">
//             <div className="flex space-x-4">
//               <button
//                 className={`relative py-2 pr-4 text-left font-[600] ${
//                   activeTab === "details" ? "text-gray-900" : "text-gray-400"
//                 }`}
//                 onClick={() => setActiveTab("details")}
//               >
//                 Details
//                 {activeTab === "details" && (
//                   <span className="absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-500"></span>
//                 )}
//               </button>
//               <button
//                 className={`relative py-2 text-left font-[600] ${
//                   activeTab === "reviews" ? "text-gray-900" : "text-gray-400"
//                 }`}
//                 onClick={() => setActiveTab("reviews")}
//               >
//                 Reviews
//                 {activeTab === "reviews" && (
//                   <span className="absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-500"></span>
//                 )}
//               </button>
//             </div>
//             {activeTab === "details" && (
//               <div className="mt-4">
//                 <p className="mt-2 text-gray-500">{product.details}</p>
//               </div>
//             )}
//             {activeTab === "reviews" && (
//               <div className="mt-4">
//                 <p className="mt-2 text-gray-500">{product.reviewsText}</p>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <ProductModal isOpen={isModalOpen} onClose={handleCloseModal} />
//     </>
//   );
// };

import React, { useState, useEffect } from "react";
import { ProductModal } from "../product-modal";
import { ProductSlider } from "./product-slider";
import { ReactComponent as LikeIcon } from "../../icons/like-mini.svg";
import { ReactComponent as StarIcon } from "../../icons/star-3.svg";
import { LoadingHTML } from "features/ui/loading";
import {
  useAppState,
  Cluster,
  Box,
  Favorite,
  useFavorites,
} from "features/global-state/app-context";
import { globalState } from "features/global-state/state-manager";
import { RouteParams } from "features/ui";
import { useParams, useNavigate } from "react-router-dom";
import { openAuthModal } from "features/ui/components/auth/auth";
import Modal from "features/ui/components/auth/modal";

interface Product {
  market: string;
  category: string;
  asin: string;
  title: string;
  brand: string;
  price: number;
  rating: number;
  reviews: number;
  sales: number;
  active: boolean;
  images: string[];
  category_size: string;
  category_color: string;
}

export const ProductCard: React.FC = () => {
  const stateManager = useAppState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  // const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [loading, setLoading] = useState(false);
  const { slug, sectorId, clusterId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const favorites = useFavorites() || [];

  const [isModalAuth, setModalAuth] = useState<boolean>(false);

  const openModal = () => setModalAuth(true);
  const closeModal = () => setModalAuth(false);
  const onConfirm = () => navigate("/auth");

  useEffect(() => {
    const fetchProduct = async () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const productId = params.get("product");

      if (productId) {
        try {
          const response = await fetch(`/api/products?asin=${productId}`);
          if (response.ok) {
            const data: Product = await response.json();
            setProduct(data);
          } else {
            console.error("Failed to fetch product");
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      } else {
        console.error("Product ID is missing in the URL");
      }
    };

    fetchProduct();

    //todo: clear when mounting if productId !== product.asin
    return () => {
      setProduct(null);
    };
  }, [window.location.search]);

  if (!product) {
    return <LoadingHTML />;
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const calculateNewPosition = (w: number, h: number) => {
    let foundPosition = false;
    let newX = 0;
    let newY = 0;

    // Радиусы
    const innerRadius = 5;
    const outerRadius = 30;

    // Начальная точка на середине радиуса
    const start = {
      x: 0,
      y: (outerRadius - innerRadius) / 2,
    };

    // Множество всех занятых точек с учетом размеров кубиков
    const occupiedPositions = new Set<string>();
    favorites.forEach(fav => {
      for (let i = 0; i < fav.w; i++) {
        for (let j = 0; j < fav.h; j++) {
          occupiedPositions.add(`${fav.x + i},${fav.y + j}`);
        }
      }
    });

    // Проверка, находится ли позиция в пределах кольца
    const isWithinRing = (x: number, y: number) => {
      const distance = Math.sqrt(x * x + y * y);
      return distance >= innerRadius && distance <= outerRadius;
    };

    // Проверка, свободна ли область для нового кубика
    const isAreaFree = (x: number, y: number, w: number, h: number) => {
      for (let i = 0; i < w; i++) {
        for (let j = 0; j < h; j++) {
          const checkX = x + i;
          const checkY = y + j;
          if (occupiedPositions.has(`${checkX},${checkY}`)) {
            return false;
          }
        }
      }
      return true;
    };

    // Шаги по спирали
    let step = 1;
    while (!foundPosition) {
      for (let dx = -step; dx <= step; dx++) {
        for (let dy = -step; dy <= step; dy++) {
          const candidateX = start.x + dx;
          const candidateY = start.y + dy;

          // Проверка: область свободна, и позиция в пределах кольца
          if (
            isAreaFree(candidateX, candidateY, w, h) &&
            isWithinRing(candidateX, candidateY)
          ) {
            newX = candidateX;
            newY = candidateY;
            foundPosition = true;

            // Добавление занятых позиций для нового кубика
            for (let i = 0; i < w; i++) {
              for (let j = 0; j < h; j++) {
                occupiedPositions.add(`${newX + i},${newY + j}`);
              }
            }
            break;
          }
        }
        if (foundPosition) break;
      }
      step++; // Увеличиваем радиус просмотра
    }

    return { x: newX, y: newY };
  };

  const syncFavorites = (newFavorites: Favorite[]) => {
    // setFavorites(newFavorites);
    localStorage.setItem("favorites", JSON.stringify(newFavorites));
    globalState.setState("favorites", newFavorites);
  };

  // Handle favorite toggle
  const handleFavoriteClick = async () => {
    if (!product || loading) return;

    setLoading(true);
    const asin = product.asin;
    const existingFavorite = favorites.find(fav => fav.asin === asin);
    const currentFavorites = [...favorites];

    try {
      if (existingFavorite) {
        // Remove from favorites
        const updatedFavorites = currentFavorites.filter(
          fav => fav.asin !== asin
        );
        syncFavorites(updatedFavorites);

        const response = await fetch(
          `/api/favorites?asin=${asin}&scene=${slug}`,
          {
            method: "DELETE",
            credentials: "include",
            mode: "cors",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            // openAuthModal(slug, navigate); // Открыть модальное окно
            openModal();
          }
          // Rollback on error
          syncFavorites(currentFavorites);
          throw new Error("Failed to delete favorite");
        }
      } else {
        // Add to favorites
        const w = 2; // Ширина кубика (например)
        const l = w;
        const h = Math.random() * 2 + 1; // Высота кубика, случайная для примера
        const { x, y } = calculateNewPosition(w, h);
        // const { x, y } = calculateNewPosition();
        const newFavorite: Favorite = {
          asin,
          x,
          y,
          w,
          l,
          h,
          image_id: product.images[0],
          price: product.price,
          title: product.title,
        };

        const updatedFavorites = [...currentFavorites, newFavorite];
        syncFavorites(updatedFavorites);

        const response = await fetch("/api/favorites", {
          method: "POST",
          credentials: "include",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            x,
            y,
            w,
            l,
            h,
            scene: slug,
            asin,
          }),
        });

        if (!response.ok) {
          if (response.status === 401) {
            // openAuthModal(slug, navigate); // open modal window
            openModal();
          }
          // Rollback on error
          syncFavorites(currentFavorites);
          throw new Error("Failed to add favorite");
        }
      }
    } catch (error) {
      console.error("Error handling favorite:", error);
    } finally {
      setLoading(false);
    }
  };

  const isFavorite = favorites.some(favorite => favorite.asin === product.asin);

  return (
    <>
      <div className="h-screen bg-white rounded-lg shadow-md overflow-y-auto w-full">
        <div className="relative">
          <img
            src={stateManager.getProductImageURL(product.images[0])}
            alt={product.title}
            className="w-full h-auto"
            onLoad={() => {
              console.log("Image loaded!");
            }}
            onError={event => {
              (event.target as HTMLImageElement).src =
                "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg";
            }}
          />
          <button
            onClick={() => handleFavoriteClick()}
            className={`absolute bottom-[-20px] right-4 p-2 rounded-full
              ${
                loading ? "bg-gray-300" : isFavorite ? "bg-red-500" : "bg-white"
              }
              w-12 h-12 flex items-center justify-center
              border border-gray-300`}
          >
            <LikeIcon />
          </button>
        </div>

        <div className="p-4 flex flex-col justify-between">
          <div className="relative w-full mt-4">
            {product.sales > 0 && (
              <div className="flex w-full justify-end mt-4">
                <span className="accent-bg text-white rounded-full px-3 py-1">
                  +{product.sales} SALES
                </span>
              </div>
            )}

            <div className="flex w-full items-center justify-between space-x-4">
              <p className="text-gray-500">Visit the {product.brand} Store</p>

              <div className="flex items-center justify-center">
                <StarIcon className="text-yellow-500 w-12 h-12" />
                <span className="text-xl font-[600]"> {product.rating}</span>
                <span className="ml-2 text-gray-500">({product.reviews})</span>
              </div>
            </div>

            <h2 className="text-lg text-left font-[600] pb-4">
              {product.title}
            </h2>

            {/* <div className="flex items-center justify-between space-x-4">
              <div className="text-4xl font-[700]">
                ${product.price.toFixed(2)}
              </div>
              <button
                className="px-4 py-2 accent-bg text-white rounded-full flex items-center hover:opacity-75"
                onClick={handleOpenModal}
              >
                <LikeIcon className="mr-2" />
                Go to website
              </button>
            </div> */}
            <div className="flex items-center justify-between space-x-4">
              <div className="text-4xl font-[700]">
                ${product.price.toFixed(2)}
              </div>
              <a
                href={`https://www.${product.market}.com/dp/${product.asin}`}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 accent-bg text-white rounded-full flex items-center hover:opacity-75"
              >
                {/* <LikeIcon className="mr-2" /> */}
                Go to website
              </a>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="text-lg font-[600] pb-2">
              Color: {product.category_color}
            </h3>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-[600] pb-2">
              Size: {product.category_size}
            </h3>
          </div>

          <div className="mt-4">
            <div className="flex space-x-4">
              <div className="relative py-2 pr-4 text-left font-[600] text-gray-900">
                Product Details
                <span className="absolute bottom-0 left-0 w-1/2 border-b-2 border-gray-500"></span>
              </div>
            </div>
            <div className="mt-4">
              <p className="mt-2 text-gray-500">
                Category: {product.category.replace(/_/g, " ")}
                <br />
                Market: {product.market}
                <br />
                ASIN: {product.asin}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ProductModal
        asin={product.asin}
        market={product.market}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />

      <Modal isOpen={isModalAuth} onClose={closeModal} onConfirm={onConfirm}>
        <h2 className="text-lg font-bold mb-4">Authorization required</h2>
        <p className="mb-6">
          You are not authorized. Do you want to go to the login page?
        </p>
      </Modal>
    </>
  );
};
