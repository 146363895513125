import { useEffect, useState } from "react";
import { Location } from "react-router-dom";
import { SideModal } from "features/ui/components/side-modal";
import { ProductCard } from "features/ui/components/product/product-card";
import { SearchList } from "./components/search-list";
import { SearchModal } from "features/ui/components/search-page";
import { ProductsPage } from "features/ui/components/products-page/products-page";
import { useAppState, useSectors } from "features/global-state/app-context";
import { globalState } from "features/global-state/state-manager";
// import { useSceneContext } from "features/fabric/scene-context";
import { ProductModal } from "features/ui/components/product-modal";

interface FabricHtmlProps {
  slug: string | undefined;
  sectorId: string | undefined;
  clusterId: string | undefined;
  location: Location;
}

export const FabricHtml = ({
  slug,
  sectorId,
  clusterId,
  location,
}: FabricHtmlProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [openSearchList, setOpenSearchList] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false); //start page = true
  const [isProductsPageOpen, setProductsPageOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("button-down shirt");
  const [isFrameOpen, setFrameOpen] = useState(false);
  const state = useAppState();
  const sectors = useSectors();

  useEffect(() => {
    if (!slug) {
      setSearchOpen(true);
    }
  }, [slug]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const productParam = searchParams.get("product");
    const frameParam = searchParams.get("frame");

    // Reset modal state when productParam changes
    if (productParam) {
      setModalOpen(true);
      setProductsPageOpen(true);
    } else {
      setModalOpen(false);
    }

    // Reset search list state
    if (state.state.searchData) {
      setOpenSearchList(true);
    }
  }, [
    window.location.search,
    state.state.searchData?.length,
    state.state.viewMode,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const frameParam = searchParams.get("frame");
    const youtubeParam = searchParams.get("youtubeUrl");

    if (frameParam) {
      setFrameOpen(true);
    } else {
      setFrameOpen(false);
    }
  }, [window.location.search]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  // const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     setSearchOpen(false);
  //     setProductsPageOpen(true);
  //   }
  // };

  return (
    <div
      className="absolute top-0 left-0 z-20"
      style={{ background: "transparent" }}
    >
      {isSearchOpen &&
        !slug &&
        !isProductsPageOpen &&
        !window.location.pathname.includes("profile") && (
          <SearchModal
            isOpen={isSearchOpen}
            value={searchQuery}
            onClose={() => setSearchOpen(false)}
            onChange={handleSearchInputChange}
            // onKeyPress={handleSearchKeyPress}
          />
        )}

      {slug && (
        <ProductsPage
          isOpen={isProductsPageOpen}
          onClose={() => setProductsPageOpen(!isProductsPageOpen)}
          value={searchQuery}
          onChange={handleSearchInputChange}
          // onKeyPress={handleSearchKeyPress}
        />
      )}

      <SideModal
        position={"left"}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ProductCard />
      </SideModal>

      {/* {globalState.state.viewMode === "SEARCH" &&  */}
      {/* {state.state.searchData?.length && 
        !window.location.pathname.includes("profile") && ( //!DEL
        <SideModal
          position={"right"}
          isOpen={openSearchList}
          onClose={() => {
            setOpenSearchList(false);
            globalState.state.searchData = []; //? Clear search data
          }}
        >
          <SearchList />
        </SideModal>
      )} */}
      <ProductModal isOpen={isFrameOpen} onClose={() => setFrameOpen(false)} />

      {sectors && sectorId && clusterId && (
        <div
          className="absolute top-20 xl:top-3 left-[50vw] transform -translate-x-1/2 
          text-center bg-[#826AED] text-white font-bold rounded-3xl m-2 p-4 min-w-[150px]"
        >
          {sectors[+sectorId].clusters[+clusterId].text}
        </div>
      )}
    </div>
  );
};
