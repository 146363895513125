import { GLTFMeshPrimitivePostprocessed } from "@loaders.gl/gltf";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { Texture } from "features/draw-call/core/texture";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { primitiveToBuffers } from "features/draw-call/ext/glb-primitive";
import { hextogl } from "utils/hextogl";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import cluster from "cluster";
import { useModel, useTexture } from "features/resource-loader";
import { useControls } from "leva";
import { useMemo, useEffect } from "react";
import { useCamera, useSharedShaderProgram } from "../scene-context";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { mat4, vec3 } from "gl-matrix";
import { centerRingFragmentShader300 } from "../shaders/center-ring-fragment-shader";
import { modelMatrixVertexShader300 } from "../shaders/model-matrix-vertex-shader";
import { ACCENT_COLOR, FADE_DURATION } from "config";
import { SVGRenderer } from "../render-tasks/svg-renderer";
import { ApplyTransforms } from "../render-tasks/apply-transforms";
import { FaceCamera } from "../render-tasks/face-camera";
import { DistanceEmerge } from "../render-tasks/distance-emerge";

interface CenterRingOptions {
  primitive: GLTFMeshPrimitivePostprocessed;
  texture: Texture;
  camera: OrbitCamera;
}

export class CenterRingRenderer extends RenderTask {
  camera: OrbitCamera;
  texture: Texture;
  primitive: GLTFMeshPrimitivePostprocessed;
  color0: Float32Array;
  color1: Float32Array;
  modelMatrix: mat4;
  time: number;
  density: number;
  speed: number;

  constructor(gl: WebGL2RenderingContext, options: CenterRingOptions) {
    super(gl);
    this.camera = options.camera;
    this.texture = options.texture;
    this.primitive = options.primitive;
    this.color0 = hextogl("#b1b1b1ff");
    this.color1 = hextogl("#b1b1b1ff");
    this.time = 0;
    this.density = 3;
    this.speed = 1;

    this.shaderPrograms.main = new ShaderProgram(gl, {
      vertexShader: modelMatrixVertexShader300,
      fragmentShader: centerRingFragmentShader300,
      textures: { u_texture: this.texture },
    });
    this.geometries.main = new ElementsBufferGeometry(gl, {
      ...primitiveToBuffers(gl, this.primitive),
    });
    // resize the ring
    this.modelMatrix = rotationTranslationScaleMatrix(
      mat4.create(),
      [0, 0, 0],
      [0, 0, 0],
      [10, 5, 10]
    );
  }
  render(): void {
    super.render();
    const gl = this.gl;
    gl.depthMask(false);
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      color0: this.color0,
      color1: this.color1,
      modelMatrix: this.modelMatrix,
      time: this.time * 0.0001 * this.speed,
      density: this.density,
    };
    gl.enable(gl.CULL_FACE);
    gl.cullFace(gl.FRONT);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.cullFace(gl.BACK);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.disable(gl.CULL_FACE);
    gl.depthMask(true);
  }
}

export const centrRingSvg = () => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="1024" height="1024">
    <circle cx="512" cy="512" r="484"
    stroke="white" stroke-width="40"
    stroke-dasharray="10,10"
    fill="none"/>
  </svg>`;
};

export const CenterRingComponent = () => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const model = useModel("cylinder.glb");
  const texture = useTexture("ring0.jpg");
  const fabric = useMemo(
    () =>
      new CenterRingRenderer(gl, {
        primitive: model.meshes[0].primitives[0],
        texture,
        camera,
      }),
    []
  );
  useEffect(() => {}, []);

  const program = useSharedShaderProgram("textureRenderer");

  const svgRenderer = useMemo(
    () =>
      new SVGRenderer(gl, {
        svg: centrRingSvg(),
        camera,
        program,
      }),
    []
  );

  const renderTask = useMemo(
    () =>
      new DistanceEmerge(gl, {
        camera,
        rl,
        position: vec3.fromValues(0, 0, 0),
        min: 0,
        max: 1000,
        duration: FADE_DURATION,
        children: [
          new ApplyTransforms(gl, {
            translation: vec3.fromValues(0, -0.5, 0),
            rotation: vec3.fromValues(Math.PI / 2, 0, 0),
            scale: vec3.fromValues(24, 24, 0),
            children: [svgRenderer],
          }),
        ],
      }),
    []
  );

  /*const { color0, color1, density, speed } = useControls({
    color0: "#fff7eb",
    color1: ACCENT_COLOR,
    density: { min: 0.1, max: 10, value: 4.0 },
    speed: { min: 0.1, max: 1, value: 0.3 },
  });
  fabric.color0 = hextogl(color0 + "ff");
  fabric.color1 = hextogl(color1 + "00");
  fabric.density = density;
  fabric.speed = speed;*/
  fabric.color0 = hextogl("#fff7ebff");
  fabric.color1 = hextogl(ACCENT_COLOR + "00");
  fabric.density = 4.0;
  fabric.speed = 0.3;
  useFrame(rl => {
    fabric.time = rl.time;
    fabric.render();

    renderTask.render();
  });
  return null;
};
