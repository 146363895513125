import {
  NestedRenderTask,
  NestedRenderTaskOptions,
} from "features/draw-call/core/render-task/nested-render-task";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { AABB } from "features/physics";
import { vec3, vec4, mat4 } from "gl-matrix";

const corners: vec3[] = Array.from({ length: 8 }, () => vec3.create());
const projectedCorner = vec4.create();
const halfScale = vec3.create();

function isAABBVisibleNDC(
  aabb: AABB,
  viewMatrix: mat4,
  projectionMatrix: mat4
): boolean {
  // Combine view and projection matrices
  const viewProjectionMatrix = mat4.create();
  mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);

  vec3.scale(halfScale, aabb.scale, 0.5);

  // Define the 8 corners of the AABB based on position and half scale (reuse vectors)
  vec3.set(
    corners[0],
    aabb.position[0] - halfScale[0],
    aabb.position[1] - halfScale[1],
    aabb.position[2] - halfScale[2]
  );
  vec3.set(
    corners[1],
    aabb.position[0] - halfScale[0],
    aabb.position[1] - halfScale[1],
    aabb.position[2] + halfScale[2]
  );
  vec3.set(
    corners[2],
    aabb.position[0] - halfScale[0],
    aabb.position[1] + halfScale[1],
    aabb.position[2] - halfScale[2]
  );
  vec3.set(
    corners[3],
    aabb.position[0] - halfScale[0],
    aabb.position[1] + halfScale[1],
    aabb.position[2] + halfScale[2]
  );
  vec3.set(
    corners[4],
    aabb.position[0] + halfScale[0],
    aabb.position[1] - halfScale[1],
    aabb.position[2] - halfScale[2]
  );
  vec3.set(
    corners[5],
    aabb.position[0] + halfScale[0],
    aabb.position[1] - halfScale[1],
    aabb.position[2] + halfScale[2]
  );
  vec3.set(
    corners[6],
    aabb.position[0] + halfScale[0],
    aabb.position[1] + halfScale[1],
    aabb.position[2] - halfScale[2]
  );
  vec3.set(
    corners[7],
    aabb.position[0] + halfScale[0],
    aabb.position[1] + halfScale[1],
    aabb.position[2] + halfScale[2]
  );

  // Check if any of the projected corners lie within the NDC cube
  for (const corner of corners) {
    vec4.set(projectedCorner, corner[0], corner[1], corner[2], 1);
    vec4.transformMat4(projectedCorner, projectedCorner, viewProjectionMatrix);

    // Convert to NDC space by dividing by w
    if (projectedCorner[3] === 0) continue;
    const ndcX = projectedCorner[0] / projectedCorner[3];
    const ndcY = projectedCorner[1] / projectedCorner[3];
    const ndcZ = projectedCorner[2] / projectedCorner[3];

    // If the point is within the NDC bounds, AABB is partially in view
    if (
      ndcX >= -1 &&
      ndcX <= 1 &&
      ndcY >= -1 &&
      ndcY <= 1 &&
      ndcZ >= -1 &&
      ndcZ <= 1
    ) {
      return true;
    }
  }

  // No corners within the NDC bounds; AABB is out of view
  return false;
}

export interface FrustumCulledOptions extends NestedRenderTaskOptions {
  camera: OrbitCamera;
  aabb: AABB;
}

export class FrustumCulled extends NestedRenderTask {
  aabb: AABB;
  camera: OrbitCamera;
  constructor(gl: WebGL2RenderingContext, options: FrustumCulledOptions) {
    super(gl, options);
    this.aabb = options.aabb;
    this.camera = options.camera;
  }
  render() {
    super.render();
    if (
      isAABBVisibleNDC(
        this.aabb,
        this.camera.viewMatrix,
        this.camera.projectionMatrix
      )
    ) {
      this.renderChildren();
    }
  }
}
