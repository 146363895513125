import { NumberCollection } from "features/draw-call/core/types";

// a simple performance monitor
const data: NumberCollection = {};
const pending: NumberCollection = {};

export const perfmon = {
  data,
  start: (s: string) => {
    pending[s] = performance.now();
  },
  stop: (s: string) => {
    const t = performance.now();
    data[s] = (data[s] || 0) + (t - (pending[s] || 0));
  },
  reset: (s: string) => {
    data[s] = 0;
  },
  log: (s?: string) => {
    if (typeof s === "undefined") {
      for (let key in data) {
        console.log(key, data[key]);
      }
    } else {
      console.log(data[s]);
    }
  },
  inc: (s: string) => {
    data[s] = (data[s] || 0) + 1;
  },
};

// @ts-expect-error
window.perfmon = perfmon;
