import { GLContext, defaultVertices } from "features/draw-call/core/gl-utils";
import { ArrayBufferGeometry } from "./array-buffer-geometry";
import { BufferGeometry } from "./buffer-geometry";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

export const defaultTriangleVertices = new Float32Array([
  0.0,  1.0, 0.0,  // Top vertex
  1.0,  -1.0, 0.0, // Bottom left vertex
  -1.0, -1.0, 0.0, // Bottom right vertex
]);


export const defaultTriangleNormals = new Float32Array([
  0.0, 0.0, 1.0, // Normal for top vertex
  0.0, 0.0, 1.0, // Normal for bottom left vertex
  0.0, 0.0, 1.0, // Normal for bottom right vertex
]);

export const defaultTriangleTexCoords = new Float32Array([
  0.5, 1.0, // TexCoord for top vertex
  1.0, 0.0, // TexCoord for the bottom right vertex
  0.0, 0.0, // TexCoord for the bottom left vertex
]);
  
export class TriangleGeometry extends ArrayBufferGeometry {
  constructor(gl: GLContext) {
    super(gl, {
      vertexBuffers: {
        POSITION: new VertexBuffer(gl, {
          data: new Float32Array(defaultTriangleVertices),
          size: 3,
        }),
        NORMAL: new VertexBuffer(gl, {
          data: new Float32Array(defaultTriangleNormals),
          size: 3,
        }),
        TEXCOORD_0: new VertexBuffer(gl, {
          data: new Float32Array(defaultTriangleTexCoords),
          size: 2,
        }),
      },
      count: 3,
    });
  }
}

  