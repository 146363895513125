import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { mat4, quat } from "gl-matrix";
import { useControls } from "leva";
import { FC, useEffect, useMemo } from "react";
import {
  GROUND_SIZE,
  groundMeshFragmentShader300,
} from "../shaders/ground-mesh-fragment-shader";
import { vertexShader, vertexShader300 } from "../shaders/vertex-shader";
import { basicFragmentShader300 } from "../shaders/basic-fragment-shader";
import { hextogl } from "utils/hextogl";
import { useCamera } from "../scene-context";
import { Texture } from "features/draw-call/core/texture";
import { useTexture } from "features/resource-loader";
import { matcapFragmentShader300 } from "../shaders/matcap-fragment-shader";

export interface GroundOptions {
  camera: OrbitCamera;
  matcap: Texture;
}

const _tmp0 = mat4.create();

class Ground extends RenderTask {
  camera: OrbitCamera;
  matcap: Texture;
  constructor(gl: WebGL2RenderingContext, options: GroundOptions) {
    super(gl);
    this.camera = options.camera;
    this.matcap = options.matcap;

    // create ground plane from a quad geometry
    const groundPlane = new QuadGeometry(gl);
    const groundPlaneT = rotationTranslationScaleMatrix(
      mat4.create(),
      [-Math.PI / 2, 0, 0],
      [0, -0.2, 0],
      [GROUND_SIZE, GROUND_SIZE, 1]
    );
    groundPlane.vertexBuffers.POSITION.applyMat4Position(groundPlaneT);
    groundPlane.vertexBuffers.NORMAL.applyMat4Direction(groundPlaneT);

    this.shaderPrograms.plane = new ShaderProgram(gl, {
      fragmentShader: basicFragmentShader300,
      vertexShader: vertexShader300,
      textures: { matcap: this.matcap },
    });

    this.geometries.groundPlane = groundPlane;
  }
  render(): void {
    super.render();
    const gl = this.gl;
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      color: hextogl("#e8e8e8ff"), //hextogl("#d5d5d5ff"), //hextogl("#b1b1b1ff"),
    };
    gl.depthMask(false);
    //gl.disable(gl.DEPTH_TEST);
    this.geometries.groundPlane.drawCall(this.shaderPrograms.plane, uniforms);
    //gl.enable(gl.DEPTH_TEST);
    gl.depthMask(true);
  }
}

export const GroundScene: FC = () => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const matcap = useTexture("matcap-extra-white.png");
  const renderTask = useMemo(() => new Ground(gl, { camera, matcap }), []);
  useEffect(() => {}, []);

  useFrame(rl => {
    renderTask.render();
  });
  return null;
};
