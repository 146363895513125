import { GLContext, defaultVertices } from "features/draw-call/core/gl-utils";
import { ArrayBufferGeometry } from "./array-buffer-geometry";
import { BufferGeometry } from "./buffer-geometry";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

// this file generates a quad with uvs and normals

export const defaultQuadVertices = new Float32Array([
  -1.0,
  -1.0,
  0.0, // Bottom left corner
  1.0,
  -1.0,
  0.0, // Bottom right corner
  -1.0,
  1.0,
  0.0, // Top left corner
  -1.0,
  1.0,
  0.0, // Top left corner
  1.0,
  1.0,
  0.0, // Top right corner
  1.0,
  -1.0,
  0.0, // Bottom right corner
]);

export const defaultQuadNormals = new Float32Array([
  0.0,
  0.0,
  1.0, // Normal for bottom left corner
  0.0,
  0.0,
  1.0, // Normal for bottom right corner
  0.0,
  0.0,
  1.0, // Normal for top left corner
  0.0,
  0.0,
  1.0, // Normal for top left corner
  0.0,
  0.0,
  1.0, // Normal for top right corner
  0.0,
  0.0,
  1.0, // Normal for bottom right corner
]);

export const defaultQuadTexCoords = new Float32Array([
  0.0,
  0.0, // TexCoord for bottom left corner
  1.0,
  0.0, // TexCoord for bottom right corner
  0.0,
  1.0, // TexCoord for top left corner
  0.0,
  1.0, // TexCoord for top left corner
  1.0,
  1.0, // TexCoord for top right corner
  1.0,
  0.0, // TexCoord for bottom right corner
]);

export class QuadGeometry extends ArrayBufferGeometry {
  constructor(gl: GLContext) {
    super(gl, {
      vertexBuffers: {
        POSITION: new VertexBuffer(gl, {
          data: new Float32Array(defaultQuadVertices),
          size: 3,
        }),
        NORMAL: new VertexBuffer(gl, {
          data: new Float32Array(defaultQuadNormals),
          size: 3,
        }),
        TEXCOORD_0: new VertexBuffer(gl, {
          data: new Float32Array(defaultQuadTexCoords),
          size: 2,
        }),
      },
      count: 6,
    });
  }
}
