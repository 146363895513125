import {
  GLTFMeshPrimitive,
  GLTFMeshPrimitivePostprocessed,
  GLTFWithBuffers,
  postProcessGLTF,
} from "@loaders.gl/gltf";
import { BufferGeometry } from "features/draw-call/core/geometry/buffer-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  OutputFramebuffer,
  VertexBufferCollection,
} from "features/draw-call/core/types";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

export interface ElementsBufferGeometryOptions {
  vertexBuffers: VertexBufferCollection;
  indexBuffer: VertexBuffer;
  count: number;
}

// a geometry for rendering buffers using gl.drawElements()
export class ElementsBufferGeometry extends BufferGeometry {
  indexBuffer: VertexBuffer | null = null;
  count: number = 0;
  constructor(gl: GLContext, options?: ElementsBufferGeometryOptions) {
    super(gl);
    if (options) {
      Object.assign(this, options);
    }
  }
  createVertexBuffers(): void {
    super.createVertexBuffers();
    if (this.indexBuffer) {
      this.indexBuffer.create();
    }
  }
  updateVertexBuffers(): void {
    super.updateVertexBuffers();
    if (this.indexBuffer) {
      this.indexBuffer.update();
    }
  }
  freeVertexBuffers(): void {
    if (this.indexBuffer) {
      this.indexBuffer.free();
    }
    super.freeVertexBuffers();
  }
  draw(): void {
    const gl = this.gl;
    // if you don't create the index buffer by this point, you can't draw
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer?.glBuffer!);
    gl.drawElements(gl.TRIANGLES, this.count, gl.UNSIGNED_SHORT, 0);
  }
}
