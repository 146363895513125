export const uvDebugFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D u_texture;
uniform float transparency;

void main() {
    FragColor = vec4(v_uv, .5, 1.0 - transparency);
}
`;
