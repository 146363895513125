import { useMemo } from "react";
import {
  useCamera,
  useSharedShaderProgram,
  useSharedShaderPrograms,
} from "../scene-context";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import {
  RenderTask,
  RenderTaskOptions,
} from "features/draw-call/core/render-task/render-task";
import { GLContext } from "features/draw-call/core/gl-utils";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { TriangleArrayGeometry } from "features/draw-call/core/geometry/triangle-array-geometry";
import { Sector, useAppState } from "features/global-state/app-context";
import { useSector } from "./sectors";
import { mat4 } from "gl-matrix";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { PI } from "math";
import { QuadArrayGeometry } from "features/draw-call/core/geometry/quad-array-geometry";
import { AppState } from "features/global-state/state-manager";
import { Handle, PhysicsModule } from "features/physics";
import { usePhysics } from "features/resource-loader";
import { AnimateOnHover } from "../render-tasks/animate-on-hover";

export interface SectorHoverOptions extends RenderTaskOptions {
  camera: OrbitCamera;
  sector: Sector;
}

function polarToCartesianX(radius: number, angle: number): number {
  return radius * Math.cos(angle);
}

function polarToCartesianY(radius: number, angle: number): number {
  return radius * Math.sin(angle);
}

export const RAYS_IN_CIRCLE = 600;

export class SectorHover extends RenderTask {
  camera: OrbitCamera;
  sector: Sector;
  modelMatrix: mat4;
  hovered: boolean = false;
  state: number = 0;

  // Новые параметры
  outerRadius: number = 170; // Внешний радиус
  innerRadius: number = 24; // Внутренний радиус
  triangleWidth: number = 0.01; // Ширина треугольников

  constructor(gl: GLContext, options: SectorHoverOptions) {
    super(gl, options);
    this.camera = options.camera;
    this.sector = options.sector;
    this.modelMatrix = rotationTranslationScaleMatrix(
      mat4.create(),
      [PI / 2, 0, 0],
      [0, -0.05, 0],
      [1, 1, 1]
    );

    const aw = this.sector.aw;
    const a = this.sector.a;
    const rayCount = Math.ceil(RAYS_IN_CIRCLE / 2 / PI / aw);
    const rayW = aw / rayCount;

    //! Quad Rays
    // this.geometries.main = new QuadArrayGeometry(gl, rayCount);
    // const m = mat4.create();
    // for (let i = 0; i < rayCount; i++) {
    //   this.geometries.main.vertexBuffers.POSITION.applyMat4Position(
    //     rotationTranslationScaleMatrix(
    //       m,
    //       [0, 0, a + i * rayW + PI / 2],
    //       [
    //         polarToCartesianX(120, a + i * rayW),
    //         polarToCartesianY(120, a + i * rayW),
    //         0,
    //       ],
    //       [1, 96, 1]
    //     ),
    //     i * 6,
    //     6
    //   );
    // }

    //! Triangle Rays
    // this.geometries.main = new TriangleArrayGeometry(gl, rayCount * 2); // 2 треугольника на каждую линию
    // // Создание массива для хранения вершин
    // const positions = new Float32Array(rayCount * 6 * 3); // 6 вершин на 2 треугольника
    // We'll only create half as many triangles
    this.geometries.main = new TriangleArrayGeometry(
      gl,
      Math.ceil(rayCount / 2) * 2
    );
    const positions = new Float32Array(Math.ceil(rayCount / 2) * 6 * 3);
    let positionIndex = 0;
    for (let i = 0; i < rayCount; i += 2) {
      // Skip every second triangle
      const angleStart = a + i * rayW;
      const angleEnd = angleStart + rayW;

      const x1 = polarToCartesianX(this.outerRadius, angleStart);
      const y1 = polarToCartesianY(this.outerRadius, angleStart);
      const x2 = polarToCartesianX(this.innerRadius, angleStart);
      const y2 = polarToCartesianY(this.innerRadius, angleStart);
      const x3 = polarToCartesianX(this.outerRadius, angleEnd);
      const y3 = polarToCartesianY(this.outerRadius, angleEnd);
      const x4 = polarToCartesianX(this.innerRadius, angleEnd);
      const y4 = polarToCartesianY(this.innerRadius, angleEnd);

      positions.set(
        [x2, y2, 0, x1, y1, 0, x3, y3, 0, x2, y2, 0, x3, y3, 0, x4, y4, 0],
        positionIndex
      );

      positionIndex += 18; // 6 vertices * 3 coordinates
    }

    // Установка данных в буфер и обновление
    this.geometries.main.vertexBuffers.POSITION.data = positions;
    this.geometries.main.vertexBuffers.POSITION.update(); // Обновляем буфер с новыми данными
  }
  render(u?: UniformValues) {
    super.render();
    const gl = this.gl;
    gl.depthMask(false);
    // gl.disable(gl.DEPTH_TEST);
    this.geometries.main.drawCall(this.shaderPrograms.sectorHover, {
      ...u,
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      modelMatrix: this.modelMatrix,
    });
    // gl.enable(gl.DEPTH_TEST);
    gl.depthMask(true);
  }
  free() {
    super.free();
  }
}

export const SectorHoverComponent = () => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const { sector } = useSector();
  const physics = usePhysics();
  const shaderPrograms = useSharedShaderPrograms(["sectorHover"]);
  const rt = useMemo(
    () =>
      new AnimateOnHover(gl, {
        duration: 250,
        handle: { type: "sector", id: sector.sector },
        rl,
        physics,
        children: [
          new SectorHover(gl, {
            camera,
            sector,
            shaderPrograms,
          }),
        ],
      }),
    []
  );

  useFrame(rl => {
    rt.render();
  }, 1);
  return null;
};
