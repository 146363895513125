import { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../icons/User.svg";
import { RouteParams } from "features/ui";
import { useParams, useNavigate } from "react-router-dom";
import {openAuthModal, getAuthenticatedCookie} from "features/ui/components/auth/auth"
import Modal from 'features/ui/components/auth/modal';
// import "../style.css";

interface UserData {
  roles: string[];
  user: {
    sub: string;
    email: string;
    email_verified: boolean;
    name: string;
    family_name: string;
    given_name: string;
    preferred_username: string;
  };
  
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  sub: string;
}
  
interface FavoriteItem { //!import
  id: number;
  name: string;
  price: string;
} 

export const UserProfile = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [favorites, setFavorites] = useState<FavoriteItem[] | []>([]);
  const [errorUser, setErrorUser] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { slug } = useParams<RouteParams>();
  const navigate = useNavigate();

  const [isModalAuth, setModalAuth] = useState<boolean>(false);
  const openModal = () => setModalAuth(true);
  const closeModal = () => setModalAuth(false);
  const onConfirm = () => navigate("/auth");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("/api/auth/me");
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        }else if (response.status === 401) {
          openModal();
          // setAuthenticatedCookie(false);
          // setTimeout(()=>{navigate("/auth")}, 2000)
        }else {
          const errorData = await response.json();
          setErrorUser(`Failed to load user data: ${errorData.message || 'Unknown error'}`);
        }
      } catch (error) {
        setErrorUser("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      try {
        const response = await fetch("/api/favorites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({ userId: userData?.id }),
        });
        if (response.ok) {
          const data = await response.json();
          setFavorites(data);
          console.log("!!!data", data);
        } else if (response.status === 401) {
          openModal();
          // setAuthenticatedCookie(false);
          // setTimeout(()=>{navigate("/auth")}, 2000)
          setError("Unauthorized access. Please log in again.");
        } else {
          setError("Failed to load favorites");
        }
      } catch (error) {
        setError("Error fetching favorites");
      }
    };

    fetchUserData();
    fetchFavorites();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="w-full max-w-lg p-8 space-y-8 bg-white shadow-lg rounded-lg">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-[140px] h-[140px] bg-[#826AED] rounded-full flex items-center justify-center">
            <div className="w-[110px] h-[110px] bg-[#826AED] rounded-full flex items-center justify-center">
              <UserIcon className="relative bottom-[-28px] h-[110px]" />
            </div>
            <span className="text-white text-3xl font-bold">
              {/* {userData?.user.name.charAt(0)} */}
            </span>
          </div>
          <h2 className={`text-2xl font-bold ${errorUser && 'text-red-500'}`}>
            {errorUser ? errorUser : userData?.user.name}
          </h2>
          <p className="text-gray-500">{userData?.user.email}</p>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-bold">Favorites</h3>
          {error ? (
            <p className="text-red-500">{error}</p>
          ) : favorites.length > 0 ? (
            <ul className="space-y-2">
              {favorites.map(item => (
                <li key={item.id} className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
                  <span>{item.name}</span>
                  <span className="text-[#826AED] font-bold">{item.price}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No favorite items.</p>
          )}
        </div>
      </div>

      <Modal isOpen={isModalAuth} onClose={closeModal} onConfirm={onConfirm}>
        <h2 className="text-lg font-bold mb-4">Authorization required</h2>
        <p className="mb-6">
          You are not authorized. Do you want to go to the login page?
        </p>
      </Modal>
    </div>
  );
};

export default UserProfile;
