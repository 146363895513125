export const GROUND_SIZE = 800;

export const groundMeshFragmentShader300: string = `#version 300 es
    precision highp float;

    in vec2 v_uv;
    in vec4 v_projPosition;
    out vec4 fragColor;


    void main() {
        vec2 coord = v_uv * ${GROUND_SIZE}.0;

  // Compute anti-aliased world-space grid lines
  vec2 grid = abs(fract(coord) - 0.5) / fwidth(coord);
  float line = min(grid.x, grid.y);

  // Just visualize the grid lines directly
  float color = 1.0 - min(line, 1.0);

  // Apply gamma correction
   color = pow(color, 1.0 / 2.2);

  float fog = 1. - smoothstep(0.0, 1.0, v_projPosition.z / 50.);
        float f = color * fog;
        fragColor = vec4(vec3(0.8) * vec3(color) * fog, f);
    }
`;

export const groundMeshFragmentShader1: string = `#version 300 es
    precision highp float;

    in vec2 v_uv;
    out vec4 fragColor;

    const float lineWidth = 0.1;

    void main() {
        vec2 uv = v_uv;

        vec4 uvDDXY = vec4(dFdx(uv), dFdy(uv)); //
        vec2 uvDeriv = vec2(length(uvDDXY.xz), length(uvDDXY.yw)); //
        bool invertLine = lineWidth > 0.5;
        vec2 targetWidth = vec2(invertLine ? 1.0 - lineWidth : lineWidth);
        vec2 drawWidth = clamp(targetWidth, uvDeriv, vec2(0.5));
        vec2 lineAA = uvDeriv * 1.5;
        vec2 gridUV = abs(fract(uv * 1000.) * 2.0 - 1.0);
        gridUV = invertLine ? gridUV : 1.0 - gridUV;
        vec2 grid2 = smoothstep(drawWidth + lineAA, drawWidth - lineAA, gridUV);
        grid2 *= (targetWidth / drawWidth);
        grid2 = mix(grid2, targetWidth, (uvDeriv * 2.0 - 1.0));
        grid2 = invertLine ? 1.0 - grid2 : grid2;
        float grid = mix(grid2.x, 1.0, grid2.y);

        float alpha = grid;

        fragColor = vec4(vec3(1.), alpha);
    }
  `;
export const groundMeshFragmentShader2: string = `#version 300 es
    precision highp float;

    in vec2 v_uv;
    in vec3 v_viewPosition;
    uniform float transparency;
    out vec4 fragColor;

    const float lineWidth = 0.1;

    float grid(vec2 uv, float g, vec2 uvDeriv, float s) {
        float p = 2500. / g;
        float c = 0.025 / g;
        vec2 r = (2. * uv * ${GROUND_SIZE}.0 + .5) / g;
        vec2 st = fract(r) - 0.5;

        float ret = smoothstep(p * uvDeriv.x, 0., st.x) * smoothstep(0., p * uvDeriv.x, st.x + c);
        ret += smoothstep(p * uvDeriv.y, 0., st.y) * smoothstep(0., p * uvDeriv.y, st.y + c);
        return ret * smoothstep(0.005, 0.2, s / v_viewPosition.z);
    }

    void main() {
        vec2 uv = v_uv;
        
        vec4 uvDDXY = vec4(dFdx(uv), dFdy(uv));
        vec2 uvDeriv = vec2(length(uvDDXY.xz), length(uvDDXY.yw));

        float grid1 = grid(uv, 1., uvDeriv, 6.);
        float grid2 = grid(uv, 5., uvDeriv, 12.);
        float grid3 = grid(uv, 25., uvDeriv, 24.);

        float alpha1 = grid1;
        float alpha2 = grid2 * 3.0;
        float alpha3 = grid3 * 6.0;

        float alpha = alpha1 + alpha2 + alpha3;

        vec3 color = vec3(1.);

        fragColor = vec4(color, alpha * transparency);
    }
`;
