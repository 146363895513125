import {
  AppState,
  NavigationState,
  VIEW_MODES,
  ViewMode,
} from "features/global-state/state-manager";
import {
  ConditionalEmerge,
  ConditionalEmergeOptions,
} from "./conditional-emerge";
import { GLContext } from "features/draw-call/core/gl-utils";

// conditionally show or hide children based on the current view mode

export interface AppStateEmergeOptions extends ConditionalEmergeOptions {
  viewMode?: ViewMode | ViewMode[];
  sectorId?: number | string;
  clusterId?: number;
  stateManager: AppState;
}

export const isClusterActive = (
  state: NavigationState,
  clusterId: number,
  sectorId: number
) => {
  let visible = true;
  if (sectorId !== undefined) {
    visible &&= state.sectorId === sectorId;
  }
  if (clusterId !== undefined) {
    visible &&= state.clusterId === clusterId;
  }
  return visible;
};

export class AppStateEmerge extends ConditionalEmerge {
  viewMode: ViewMode | ViewMode[];
  sectorId?: number | string;
  clusterId?: number;
  stateManager: AppState;
  unbindNavigation?: () => void;
  constructor(gl: GLContext, options: AppStateEmergeOptions) {
    super(gl, options);
    this.viewMode = options.viewMode ?? Array.from(VIEW_MODES);
    this.stateManager = options.stateManager;
    this.sectorId = options.sectorId;
    this.clusterId = options.clusterId;
    // perform initial check unless `visible` is explicitly overridden
    if (typeof options.visible === "undefined") {
      this.onNavigation(this.stateManager.state);
    }
  }
  onNavigation(state: NavigationState) {
    // set visibility based on the current state
    let visible = true;
    if (Array.isArray(this.viewMode)) {
      visible &&= this.viewMode.includes(state.viewMode);
    } else {
      visible &&= state.viewMode === this.viewMode;
    }
    if (this.sectorId !== undefined) {
      visible &&= state.sectorId === this.sectorId || state.sectorId == 999;
    }
    if (this.clusterId !== undefined) {
      visible &&= state.clusterId === this.clusterId || state.clusterId == 0;
    }
    this.visible = visible;
  }
  create(): void {
    super.create();
    this.unbindNavigation = this.stateManager.on(
      "navigation",
      this.onNavigation.bind(this)
    );
  }
  free(): void {
    super.free();
    this.unbindNavigation?.();
  }
}
