export const MODELS = [
  "box-regular.glb",
  "box-rounded.glb",
  "box-walls.glb",
  "cylinder.glb",
  "screenP.glb",
  "quad-rounded.glb",
  "cluster-top.glb",
];
export const MODELS_COUNT = MODELS.length;
