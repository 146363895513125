import { mat4, quat, vec3, vec4 } from "gl-matrix";

const _tmp0 = vec4.create();

// apply a matrix transformation to an array (typically a vertex buffer)
// useful for manipulating vertices on the cpu side
export function mat4Array(
  va: Float32Array,
  start: number,
  count: number,
  m: mat4,
  fourth: number = 1
) {
  const l = count + start;
  for (let i = start; i < l; i++) {
    const index = i * 3;
    vec4.set(_tmp0, va[index], va[index + 1], va[index + 2], fourth);
    vec4.transformMat4(_tmp0, _tmp0, m);
    va[index] = _tmp0[0];
    va[index + 1] = _tmp0[1];
    va[index + 2] = _tmp0[2];
  }
}

// create a large array and fill it with copies of a smaller array
// useful for creating ArrayBufferGeometry buffers
// also goes well with mat4Array()
export function manyCopiesOfArray(
  a: Float32Array,
  copies: number
): Float32Array {
  const l = a.length * copies;
  const la = a.length;
  const r = new Float32Array(l);
  for (let i = 0; i < l; i += la) {
    r.set(a, i);
  }
  return r;
}

// create a transformation matrix from 3 vectors: rotation, translation and scale
export function rotationTranslationScaleMatrix(
  m: mat4,
  q: vec3,
  t: vec3,
  s: vec3
) {
  const qu = quat.create();
  quat.rotateX(qu, qu, q[0]);
  quat.rotateY(qu, qu, q[1]);
  quat.rotateZ(qu, qu, q[2]);
  mat4.fromRotationTranslationScale(m, qu, t, s);
  return m;
}
