import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { QuadGeometry } from "features/draw-call/core/geometry/quad-geometry";
import { primitiveToBuffers } from "features/draw-call/ext/glb-primitive";
import { useDC } from "features/draw-call/tsx/canvas";
import { useModel } from "features/resource-loader";
import { mat4 } from "gl-matrix";
import { useMemo } from "react";
import { useMemoElementsGeometry } from ".";

export const useCreateSharedGeometries = () => {
  const { gl } = useDC();
  const screenPModel = useModel("screenP.glb");
  const screenP = useMemoElementsGeometry(gl, screenPModel);
  const roundedQuadModel = useModel("quad-rounded.glb");
  const roundedQuad = useMemoElementsGeometry(gl, roundedQuadModel);
  const clusterTopModel = useModel("cluster-top.glb");
  const clusterTop = useMemoElementsGeometry(gl, clusterTopModel);
  const cylinderModel = useModel("cylinder.glb");
  const cylinder = useMemoElementsGeometry(gl, cylinderModel);
  const roundedBoxModel = useModel("box-rounded.glb");
  const roundedBox = useMemoElementsGeometry(gl, roundedBoxModel);
  const boxModel = useModel("box-regular.glb");
  const box = useMemoElementsGeometry(gl, boxModel);
  const boxWallsModel = useModel("box-walls.glb");
  const boxWalls = useMemoElementsGeometry(gl, boxWallsModel);
  const centeredBox = useMemo(() => {
    const g = new ElementsBufferGeometry(
      gl,
      primitiveToBuffers(gl, boxModel.meshes[0].primitives[0])
    );
    const t = mat4.create();
    mat4.fromTranslation(t, [0, -0.5, 0]);
    g.vertexBuffers.POSITION.applyMat4Position(t);
    g.vertexBuffers.NORMAL.applyMat4Direction(t);
    return g;
  }, []);
  const quad = useMemo(() => {
    const g = new QuadGeometry(gl);
    const t = mat4.create();
    mat4.fromScaling(t, [0.5, -0.5, 0.5]);
    g.vertexBuffers.POSITION.applyMat4Position(t);
    g.vertexBuffers.NORMAL.applyMat4Direction(t);
    return g;
  }, []);
  return useMemo(
    () => ({
      quad,
      roundedQuad,
      clusterTop,
      cylinder,
      roundedBox,
      box,
      centeredBox,
      screenP,
      boxWalls,
    }),
    []
  );
};
