import { CAMERA_FAR } from "config";
import { vec3, vec4, mat4 } from "gl-matrix";

// Normalized device coordinates
export function NDC(
  screenX: number,
  screenY: number,
  screenWidth: number,
  screenHeight: number
) {
  // Convert screen coordinates to normalized device coordinates (NDC)
  let ndcX = (2.0 * screenX) / screenWidth - 1.0;
  let ndcY = 1.0 - (2.0 * screenY) / screenHeight;

  // NDC coordinates
  return vec4.fromValues(ndcX, ndcY, 0.5, 1.0);
}

// camera position in world space
export function viewPosition(viewMatrix: mat4) {
  // Assuming viewMatrix is a Float32Array or similar
  const m = viewMatrix;

  const x = -(m[0] * m[12] + m[1] * m[13] + m[2] * m[14]);
  const y = -(m[4] * m[12] + m[5] * m[13] + m[6] * m[14]);
  const z = -(m[8] * m[12] + m[9] * m[13] + m[10] * m[14]);

  return vec4.fromValues(x, y, z, 1);
}
const _t = mat4.create();
// unproject vector from a projection matrix
export function unproject(v: vec3, projectionMatrix: mat4, viewMatrix: mat4) {
  // Inverse projection matrix
  let invProjectionMatrix = _t;
  mat4.invert(invProjectionMatrix, projectionMatrix);

  // View space coordinates
  let r = vec4.fromValues(v[0], v[1], v[2], 1);
  vec4.transformMat4(r, r, invProjectionMatrix);

  // Inverse view matrix
  let invViewMatrix = _t;
  mat4.invert(invViewMatrix, viewMatrix);

  // World coordinates
  vec4.transformMat4(r, r, invViewMatrix);

  vec3.set(v, r[0] / r[3], r[1] / r[3], r[2] / r[3]);
  return v;
}
// NDC to world space
export function worldCoords(
  ndc: vec4,
  viewMatrix: mat4,
  projectionMatrix: mat4
) {
  // Inverse projection matrix
  let invProjectionMatrix = mat4.create();
  mat4.invert(invProjectionMatrix, projectionMatrix);
  // Inverse view matrix
  let invViewMatrix = mat4.create();
  mat4.invert(invViewMatrix, viewMatrix);

  // View space coordinates
  let r = vec4.create();
  vec4.transformMat4(r, ndc, invProjectionMatrix);
  r[0] /= r[3];
  r[1] /= r[3];
  r[2] /= r[3];
  r[3] = 1;

  // World coordinates
  vec4.transformMat4(r, r, invViewMatrix);

  const viewPos = viewPosition(viewMatrix);
  vec4.sub(r, r, viewPos);
  vec4.normalize(r, r);
  vec4.scale(r, r, CAMERA_FAR);
  vec4.add(r, r, viewPos);

  const result = vec3.fromValues(r[0] / r[3], r[1] / r[3], r[2] / r[3]);
  return result;
}

export function rayDirection(rayOrigin: vec3, worldCoords: vec3) {
  // Ray direction (normalized)
  let rd = vec3.create();
  vec3.subtract(rd, worldCoords, rayOrigin);
  vec3.normalize(rd, rd);
  return rd;
}
