import { useEffect } from "react";
import { useCamera } from "../scene-context";
import { vec3 } from "gl-matrix";
import { usePhysics } from "features/resource-loader";
import { useAppState, useSectors } from "features/global-state/app-context";
import { useNavigate } from "react-router-dom";
import { sectorCoords } from "math/sector-coords";
import {
  CLUSTER_FAR,
  CLUSTER_SECTOR_BORDER_MULTIPLIER,
  FAVORITES_RADIUS,
  SCENE_FAR,
  SCENE_NEAR,
  SECTOR_FAR,
  SECTOR_SCENE_BORDER_MULTIPLIER,
} from "config";

// contains logic for navigating the scene
export const SceneNavigator = () => {
  const camera = useCamera();
  const physics = usePhysics();
  const sectors = useSectors();
  const appState = useAppState();
  const navigate = useNavigate();
  // navigate scene by clicking on objects
  useEffect(() => {
    return camera.on("clickPhysics", handle => {
      console.log("hit: ", handle);
      if (
        handle &&
        !handle.type.includes("slide") &&
        !handle.type.includes("banner")
      ) {
        const viewMode = appState.state.viewMode;
        const sceneId = appState.state.sceneId;
        if (viewMode === "SCENE") {
          const sectorId = handle.id;
          navigate(`/${sceneId}/${sectorId}`);
        } else if (viewMode === "SECTOR") {
          const clusterId = handle.id;
          navigate(`/${sceneId}/${appState.state.sectorId}/${clusterId}`);
        } else if (viewMode === "CLUSTER") {
          const productId = handle.id;
          navigate(
            `/${sceneId}/${appState.state.sectorId}/${appState.state.clusterId}?product=${productId}`
          );
        } else if (viewMode === "FAVORITES") {
          const productId = handle.id;
          navigate(`/${sceneId}/favorites?product=${productId}`);
        }
      } else if (handle && handle.type.includes("banner")) {
        const typeIdParts = handle.id.split("-");
        if (typeIdParts.length > 1) {
          const id = typeIdParts[1];
          navigate(`${window.location.pathname}?product=${id}`);
        }
      } else if (handle && handle.type.includes("slide")) {
        if (handle?.type === "slide" && handle.id) {
          const slideIdParts = handle.id.split(":");
          const contentType = slideIdParts[2];
          const url = slideIdParts[3];
          // .split('/').pop()?.split('__')[0]

          if (contentType === "video") {
            navigate(`${window.location.pathname}?frame=${url}`);
            console.log("open modal", slideIdParts);
          } else if (contentType === "image") {
            navigate(`${window.location.pathname}?product=${url}`);
            console.log("open product", slideIdParts);
          }
        }
      }
    });
  }, []);
  // navigate up the scene hierarchy
  // when radius gets too big
  useEffect(() => {
    return camera.on("wheel", camera => {
      const viewMode = appState.state.viewMode;
      const sceneId = appState.state.sceneId;
      const sectorId = appState.state.sectorId;
      const clusterId = appState.state.clusterId;
      if (viewMode === "SECTOR" && typeof sectorId === "number") {
        const coords = sectorCoords(sectors[sectorId]);
        if (camera.radius > coords.radius * SECTOR_SCENE_BORDER_MULTIPLIER) {
          navigate(`/${sceneId}`);
        }
      } else if (
        (viewMode === "CLUSTER" || viewMode === "SEARCH") &&
        typeof clusterId === "number" &&
        typeof sectorId === "number"
      ) {
        const cluster = sectors[sectorId].clusters[clusterId];
        if (camera.radius > cluster.r * CLUSTER_SECTOR_BORDER_MULTIPLIER) {
          navigate(`/${sceneId}/${sectorId}`);
        }
      } else if (viewMode === "FAVORITES") {
        if (
          camera.radius >
          FAVORITES_RADIUS * CLUSTER_SECTOR_BORDER_MULTIPLIER
        ) {
          navigate(`/${sceneId}`);
        }
      } else if (viewMode === "SCENE") {
        if (camera.radius > SCENE_FAR) {
          // navigate(`/${sceneId}/favorites`);
        }
      }
    });
  }, []);
  return null;
};
