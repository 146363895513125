import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { GLContext } from "features/draw-call/core/gl-utils";
import { RenderLoop } from "features/draw-call/core/render-loop";
import {
  NestedRenderTaskOptions,
  NestedRenderTask,
} from "features/draw-call/core/render-task/nested-render-task";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { Handle, PhysicsModule } from "features/physics";
import { vec3, mat4 } from "gl-matrix";
import { clamp, invlerp } from "math";

// this nested render task makes children gradually fade in and out when the `visible` property changes

export interface AnimateOnHoverOptions extends NestedRenderTaskOptions {
  duration?: number;
  physics: PhysicsModule;
  handle: Handle;
  rl: RenderLoop;
}

export class AnimateOnHover extends NestedRenderTask {
  hovered: boolean = false;
  rl: RenderLoop;
  duration: number;
  state: number;
  physics: PhysicsModule;
  handle: Handle;
  unbindOnHover: () => void;
  unbindOnUnhover: () => void;
  constructor(gl: GLContext, options: AnimateOnHoverOptions) {
    super(gl, options);
    this.duration = options.duration ?? 0;
    this.rl = options.rl;
    this.state = Number(this.hovered);
    this.physics = options.physics;
    this.handle = options.handle;
    this.unbindOnHover = this.physics.on("hover", (h: Handle) => {
      if (h.type === this.handle.type && h.id === this.handle.id) {
        this.hovered = true;
      } else {
        this.hovered = false;
      }
    });
    this.unbindOnUnhover = this.physics.on("unhover", () => {
      this.hovered = false;
    });
  }
  render(u?: UniformValues): void {
    super.render();
    this.state = clamp(
      this.state +
        ((2 * Number(this.hovered) - 1) * this.rl.delta) / this.duration
    );
    this.renderChildren({
      ...u,
      animationState: this.state,
    });
  }
  free(): void {
    this.unbindOnHover();
    this.unbindOnUnhover();
    super.free();
  }
}
