import {
  FC,
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RenderLoop } from "../core/render-loop";
import { RenderLoopCallback } from "../core/types";
import { Loading } from "features/ui/loading";

interface Props {
  children?: ReactNode;
  width?: number;
  height?: number;
}

export const Canvas: FC<Props> = ({ children, width = 300, height = 150 }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const context: CanvasContext = useMemo(() => ({} as CanvasContext), []);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (ref.current && !init) {
      const gl = ref.current.getContext("webgl2", {})!;
      context.gl = gl;
      context.onStart = [];
      context.onFrame = [];
      const rl = new RenderLoop(gl, {
        onStart: rl => {
          for (let i = 0; i < context.onStart.length; i++) {
            context.onStart[i].cb(rl);
          }
        },
        onFrame: rl => {
          for (let i = 0; i < context.onFrame.length; i++) {
            context.onFrame[i].cb(rl);
          }
        },
      });
      context.rl = rl;
      context.canvas = ref.current;
      setInit(true);
      rl.start();
    }
  }, []);
  context.width = width;
  context.height = height;
  return (
    <renderLoopContext.Provider value={context}>
      <canvas width={width} height={height} ref={ref}>
        {init ? children : <Loading />}
      </canvas>
    </renderLoopContext.Provider>
  );
};

export interface OrderedRenderLoopCallback {
  order: number;
  cb: RenderLoopCallback;
}

export interface CanvasContext {
  gl: WebGL2RenderingContext;
  rl: RenderLoop;
  onStart: OrderedRenderLoopCallback[];
  onFrame: OrderedRenderLoopCallback[];
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
}

const renderLoopContext = createContext<CanvasContext>({} as CanvasContext);
export const useDC = () => useContext(renderLoopContext);
const _useCb = (
  cb: RenderLoopCallback,
  fn: "onStart" | "onFrame",
  order: number = 0
) => {
  const all = useDC();
  useEffect(() => {
    const x = all[fn].find(x => x.cb === cb);
    if (x) {
      x.order = order ?? x.order;
    } else {
      all[fn].push({ order, cb }); // Add to the end of the list
    }
    all[fn] = all[fn].sort((a, b) => a.order - b.order);
    return () => {
      all[fn] = all[fn].filter(x => x.cb !== cb);
    };
  }, [cb, fn, order]);
};

export const useFrame = (cb: RenderLoopCallback, order?: number) =>
  _useCb(cb, "onFrame", order);
// export const useStart = (cb: RenderLoopCallback) => _useCb(cb, "onStart");
