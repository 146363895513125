export const clusterRingFragmentShader300: string = `#version 300 es
    precision highp float;
   
    uniform sampler2D u_texture;
    uniform vec4 color0;
    uniform vec4 color1;
    uniform float time;
    uniform float density;

    in vec2 v_uv;

    out vec4 FragColor;

    void main() {
        float factor = 1.;//density;
        vec2 uv = fract((v_uv + vec2(0., 0)) * vec2(33. * factor, 1. * factor)); //deleted time and 50. -> 1.
        float d = smoothstep(0.15, 0.9, distance(uv, vec2(0.5)));
        vec4 c = mix(color0, color1, d);

        vec2 t_uv = mat2(0., 1., -1., 0.) * (v_uv - 0.5) + 0.5;
        t_uv = fract(t_uv * vec2(1., factor));
        vec4 t = texture(u_texture, t_uv);
        vec4 semiOpaque = vec4(color1.rgb, 0.55 * v_uv.y);
        vec4 mainColor = vec4(c.rgb, (1. - t.r) * c.a);
        FragColor = mix(semiOpaque, mainColor, mainColor.a);
    }
`;

//!1
// float factor = 1.0; // density;
// vec2 uv = fract((v_uv + vec2(0.0, time)) * vec2(33.0 * factor, 50.0 * factor));
// vec2 adjusted_uv = fract(uv) - vec2(0.5);
// float d = smoothstep(0.15, 0.9, length(adjusted_uv));
// vec4 c = mix(color0, color1, d);

// // Rotate the texture coordinates to get the desired effect
// vec2 t_uv = mat2(0.0, 1.0, -1.0, 0.0) * (uv - 0.5) + 0.5;
// t_uv = fract(t_uv * vec2(1.0, factor));
// vec4 t = texture(u_texture, t_uv);

// // Create semi-transparent and main color layers
// vec4 semiOpaque = vec4(color1.rgb, 0.075 * v_uv.y);
// vec4 mainColor = vec4(c.rgb, (1.0 - t.r) * c.a);

// // Mix the layers to get the final fragment color
// FragColor = mix(semiOpaque, mainColor, mainColor.a);


//!2
// out vec4 FragColor;
// in vec2 v_uv;

// uniform float time;
// uniform sampler2D u_texture;
// uniform vec4 color0;
// uniform vec4 color1;

// void main()
// {
//     float factor = 1.0; // density;
//     vec2 uv = fract((v_uv + vec2(0.0, time)) * vec2(33.0 * factor, 50.0 * factor));
//     vec2 adjusted_uv = fract(uv) - vec2(0.5);

//     // Rotate the coordinates by 90 degrees
//     vec2 rotated_uv = vec2(adjusted_uv.y, -adjusted_uv.x);

//     // Create the triangle shape
//     float d = 1.0 - smoothstep(0.0, 0.5, abs(rotated_uv.y) / (1.0 - abs(rotated_uv.x)));
    
//     vec4 c = mix(color0, color1, d);

//     // No rotation needed for this effect, just simple texture lookup
//     vec4 t = texture(u_texture, uv);
    
//     // Create semi-transparent and main color layers
//     vec4 semiOpaque = vec4(color1.rgb, 0.075 * v_uv.y);
//     vec4 mainColor = vec4(c.rgb, (1.0 - t.r) * c.a);
    
//     // Mix the layers to get the final fragment color
//     FragColor = mix(semiOpaque, mainColor, mainColor.a);
// }

//!main
// uniform sampler2D u_texture;
//     uniform vec4 color0;
//     uniform vec4 color1;
//     uniform float time;
//     uniform float density;

//     in vec2 v_uv;

//     out vec4 FragColor;

//     void main() {
//         float factor = 1.;//density;
//         vec2 uv = fract((v_uv + vec2(0., time)) * vec2(33. * factor, 5. * factor));
//         float d = smoothstep(0.15, 0.9, distance(uv, vec2(0.5)));
//         vec4 c = mix(color0, color1, d);

//         vec2 t_uv = mat2(0., 1., -1., 0.) * (v_uv - 0.5) + 0.5;
//         t_uv = fract(t_uv * vec2(1., factor));
//         vec4 t = texture(u_texture, t_uv);
//         vec4 semiOpaque = vec4(color1.rgb, 0.075 * v_uv.y);
//         vec4 mainColor = vec4(semiOpaque.rgb, (1. - t.r) * c.a);
//         FragColor = mix(semiOpaque, mainColor, mainColor.a);
//     }
