import { GLContext } from "../gl-utils";
import { UniformValues } from "../types";
import { RenderTask, RenderTaskOptions } from "./render-task";

export interface NestedRenderTaskOptions extends RenderTaskOptions {
  children?: RenderTask[];
}

// a nested render task that can call its children's render methods
// use it to control draw order or to modify children's uniforms before rendering
export class NestedRenderTask extends RenderTask {
  children: RenderTask[] = [];
  constructor(gl: GLContext, options?: NestedRenderTaskOptions) {
    super(gl, options);
    this.children = options?.children || [];
  }
  add(task: RenderTask) {
    this.children.push(task);
  }
  remove(task: RenderTask) {
    const index = this.children.indexOf(task);
    if (index > -1) {
      this.children.splice(index, 1);
    }
  }
  // render children manually
  renderChildren(uniforms?: UniformValues) {
    this.children.forEach(renderTask => renderTask.render(uniforms));
  }
}
