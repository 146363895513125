import { GLTFPostprocessed } from "@loaders.gl/gltf";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MODELS, MODELS_COUNT } from "./models";
import { loadGLB } from "features/draw-call/ext/load-glb";
import { Loading } from "features/ui/loading";
import { IMAGES, IMAGES_COUNT } from "./images";
import { VIDEOS, VIDEOS_COUNT } from "./videos";
import { loadImage } from "./load-image";
import { loadVideo } from "./load-video";
import { Texture } from "features/draw-call/core/texture";
import { useDC } from "features/draw-call/tsx/canvas";
import { FONTS, FONTS_COUNT } from "./fonts";
import { DynamicTextureLoader } from "./dynamic-texture-loader";
import { physics } from "features/physics";
// import { resourcePath, clusterVideoPath } from "config";
import { resourcePath } from "config";
import { useRerender } from "hooks/rerender";

interface ResourceContext {
  gltf: Map<string, GLTFPostprocessed>;
  textures: Map<string, Texture>;
  videos: Map<string, HTMLVideoElement>;
  loaded: number;
  textureCache: DynamicTextureLoader;
}

interface Props {
  children?: ReactNode;
}

const resourceContext = createContext<ResourceContext>({} as ResourceContext);

export const COUNT = MODELS_COUNT + IMAGES_COUNT + FONTS_COUNT + VIDEOS_COUNT;

export const ResourceLoader: FC<Props> = ({ children }) => {
  const gltf = useMemo(() => new Map<string, GLTFPostprocessed>(), []);
  const textures = useMemo(() => new Map<string, Texture>(), []);
  const videos = useMemo(() => new Map<string, HTMLVideoElement>(), []);
  const fonts = useMemo(() => new Map<string, FontFace>(), []);
  const [loaded, setLoaded] = useState(0);
  const { gl } = useDC();
  // create texture cache for the  rendering context
  const textureCache = useMemo(() => new DynamicTextureLoader(gl), []);
  useEffect(() => {
    MODELS.forEach(name =>
      loadGLB(resourcePath(name)).then(model => {
        gltf.set(name, model);
        setLoaded(prev => prev + 1);
      })
    );
    IMAGES.forEach(name =>
      loadImage(resourcePath(name)).then(image => {
        // set dynamic texture fallback image
        if (name === "no-image.svg") {
          textureCache.images.set("fallback", image);
        }
        textures.set(name, new Texture(gl, { pixels: image }));
        setLoaded(prev => prev + 1);
      })
    );
    VIDEOS.forEach(name =>
      loadVideo(resourcePath(name)).then(video => {
        videos.set(name, video);
        setLoaded(prev => prev + 1);
      })
    );
    FONTS.forEach(({ name, url, weight }) =>
      new FontFace(name, `url(${resourcePath(url)})`, { weight })
        .load()
        .then(font => {
          document.fonts.add(font);
          fonts.set(name, font);
          setLoaded(prev => prev + 1);
        })
    );
    // free textures
    return () => {
      IMAGES.forEach(name => {
        const texture = textures.get(name);
        if (texture) texture.free();
      });
    };
  }, []);
  return (
    <resourceContext.Provider
      value={{ gltf, textures, videos, loaded, textureCache }}
    >
      {loaded >= COUNT ? children : <Loading />}
    </resourceContext.Provider>
  );
};

// use physics (instead of relying on it being a global module)
export const usePhysics = () => {
  const rerender = useRerender();
  useEffect(() => physics.on("change", rerender), []);
  return physics;
};
// use texture cache
export const useTextureCache = () => useContext(resourceContext).textureCache;
// returns null if the texture is not loaded
export const useDynamicTexture = (url: string) =>
  useTextureCache().textures.get(url) || null;
// loads the texture and returns it
export const useLoadTexture = (url: string) => {
  const textureCache = useTextureCache();
  const texture = useDynamicTexture(url);
  const [loaded, setLoaded] = useState(Boolean(texture));
  if (!loaded) textureCache.loadURL(url).then(tex => setLoaded(true));
  return texture;
};
export const useModel = (s: string) => {
  const model = useContext(resourceContext).gltf.get(s);
  if (!model) throw new Error(`model ${s} not found`);
  return model;
};

export const useTexture = (s: string): Texture => {
  const texture = useContext(resourceContext).textures.get(s);
  if (!texture) throw new Error(`image ${s} not found`);
  return texture;
};

// export const useVideo = (s: string): HTMLVideoElement => {
//   const video = useContext(resourceContext).videos.get(s);
//   if (!video) throw new Error(`video ${s} not found`);

//   useEffect(() => {
//     const handleCanPlayThrough = async () => {
//       try {
//         await video.play();
//       } catch (error) {
//         console.error("Video play failed:", error);
//       }
//     };
//     video.addEventListener("canplaythrough", handleCanPlayThrough);
//     video.src = getVideoUrl(s);
//     return () => {
//       video.removeEventListener("canplaythrough", handleCanPlayThrough);
//     };
//   }, [s]);

//   return video;
// };

// export const getVideoUrl = (videoId: string) => 
//   `/videos/prev/youtube/${videoId}.webm`;

// export const useResource = (
//   s: string,
//   contentType: string | undefined
// ): Texture | HTMLVideoElement => {
//   const { gl } = useDC();
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const resource = contentType === "video" ? useVideo(s) : useTexture(s);
//   let texture: Texture | null = null;

//   if (contentType === "video") {
//     const video = resource as HTMLVideoElement;

//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     useEffect(() => {
//       const createTexture = () => {
//         if (video.videoWidth > 0 && video.videoHeight > 0) {
//           texture = new Texture(gl, {
//             pixels: video,
//             width: video.videoWidth,
//             height: video.videoHeight,
//           });
//           texture.create();
//         }
//       };

//       const handleLoadedData = () => {
//         if (video.readyState >= 3) {
//           video.play().catch(error => console.error("Video play failed:", error));
//           createTexture(); // Создаем текстуру при загрузке данных видео
//         }
//       };

//       const handleCanPlayThrough = () => {
//         video.play().catch(error => console.error("Video play failed:", error));
//       };

//       const handleEnded = () => {
//         video.currentTime = 0;
//         video.play().catch(error => console.error("Video play failed:", error));
//       };

//       video.addEventListener("loadeddata", handleLoadedData);
//       video.addEventListener("canplaythrough", handleCanPlayThrough);
//       video.addEventListener("ended", handleEnded);

//       // Попробуем воспроизвести видео сразу
//       video.muted = true; // браузер позволяет воспроизводить видео автоматически без звука
//       video.play().catch(error => console.error("Video play failed:", error));

//       return () => {
//         video.removeEventListener("loadeddata", handleLoadedData);
//         video.removeEventListener("canplaythrough", handleCanPlayThrough);
//         video.removeEventListener("ended", handleEnded);
//       };
//     }, [gl, video]); // В зависимости от gl и video

//     // Возвращаем текстуру, если она создана, иначе возвращаем видео
//     return texture ? texture : video; 
//   } else {
//     return resource as Texture;
//   }
// };

export const useVideo = (s: string): HTMLVideoElement => {
  const video = useContext(resourceContext).videos.get(s);
  if (!video) throw new Error(`video ${s} not found`);

  useEffect(() => {
    const handleCanPlayThrough = () => {
      video.play().catch(error => console.error("Video play failed:", error));
    };
    video.addEventListener("canplaythrough", handleCanPlayThrough);
    return () => {
      video.removeEventListener("canplaythrough", handleCanPlayThrough);
    };
  }, [video]);

  return video;
};

export const useResource = (
  s: string,
  contentType: string | undefined
): HTMLVideoElement | Texture => {
  const { gl, rl } = useDC();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resource = contentType === "video" ? useVideo(s) : useTexture(s);

  if (contentType === "video") {
    const video = resource as HTMLVideoElement;
    const tex = new Texture(gl, {
      pixels: video,
      width: video.videoWidth,
      height: video.videoHeight,
    });
    tex.create();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const handleLoadedData = () => {
        if (video.readyState >= 3) {
          video
            .play()
            .catch(error => console.error("Video play failed:", error));
        }
        tex.updated = false; // Update texture when video is ready
      };

      const handleCanPlayThrough = () => {
        video.play().catch(error => console.error("Video play failed:", error));
      };

      const handleEnded = () => {
        video.currentTime = 0;
        video.play().catch(error => console.error("Video play failed:", error));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("canplaythrough", handleCanPlayThrough);
      video.addEventListener("ended", handleEnded);

      // Try to play the video immediately
      video.muted = true; // browser allows the video to play automatically, without sound
      video.play().catch(error => console.error("Video play failed:", error));

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("canplaythrough", handleCanPlayThrough);
        video.removeEventListener("ended", handleEnded);
      };
    }, [gl, rl, video]);

    return tex;
  } else {
    return resource as Texture;
  }
};
