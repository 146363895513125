import { mat4 } from "gl-matrix";
import { GLContext } from "./gl-utils";
import { mat4Array } from "./geometry/array-transforms";
import { perfmon } from "features/perfmon";

export interface VertexBufferOptions {
  data: ArrayBufferView | null;
  size: number;
  type?: number;
  normalized?: boolean;
  stride?: number;
  offset?: number;
  usage?: number;
  target?: number;
}

// vertex buffer class controls one gl vertex buffer
export class VertexBuffer {
  gl: GLContext;
  glBuffer: WebGLBuffer | null = null;
  created: boolean = false;
  updated: boolean = false;
  data: ArrayBufferView | null = null;
  size: number = 0;
  type: number = 0;
  normalized: boolean = false;
  stride: number = 0;
  offset: number = 0;
  target: number = 0;
  usage: number = 0;

  constructor(gl: GLContext, options?: VertexBufferOptions) {
    this.gl = gl;
    if (options) {
      Object.assign(this, options);
    }
    if (typeof options?.type === "undefined") {
      this.type = gl.FLOAT;
    }
    if (typeof options?.target === "undefined") {
      this.target = gl.ARRAY_BUFFER;
    }
    if (typeof options?.usage === "undefined") {
      this.usage = gl.STATIC_DRAW;
    }
  }
  create() {
    if (this.created) return;
    const gl = this.gl;
    var buffer = gl.createBuffer()!;
    this.glBuffer = buffer;
    this.created = true;
    this.update();
  }
  update() {
    if (this.updated) return;
    if (!this.created) this.create();
    perfmon.start("uploading vertex buffer data");
    const buffer = this.glBuffer;
    const gl = this.gl;
    gl.bindBuffer(this.target, buffer);
    gl.bufferData(this.target, this.data, this.usage);
    this.updated = true;
    perfmon.stop("uploading vertex buffer data");
  }
  enableAttribute(attributeLocation: number) {
    const gl = this.gl;
    gl.bindBuffer(this.target, this.glBuffer);
    gl.enableVertexAttribArray(attributeLocation);
    gl.vertexAttribPointer(
      attributeLocation,
      this.size,
      this.type,
      this.normalized,
      this.stride,
      this.offset
    );
  }
  applyMat4Position(matrix: mat4, start: number = 0, count?: number) {
    let d = this.data as Float32Array;
    mat4Array(
      d,
      start,
      typeof count === "undefined" ? d.length / 3 - start : count,
      matrix
    );
  }
  applyMat4Direction(matrix: mat4, start: number = 0, count?: number) {
    let d = this.data as Float32Array;
    mat4Array(
      d,
      start,
      typeof count === "undefined" ? d.length / 3 - start : count,
      matrix,
      0
    );
  }
  free() {
    const gl = this.gl;
    gl.deleteBuffer(this.glBuffer);
    this.created = false;
    this.updated = false;
  }
}
