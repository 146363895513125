export const ABannerFragmentShader300: string = `#version 300 es
  precision highp float;

  in vec2 v_uv;
  uniform sampler2D u_texture;
  uniform float u_alpha;
  uniform float u_time;

  out vec4 outColor;

  void main() {
    float startAngle = 1.5708; // Starting angle 90 degrees (π/2)
    float angle = max(startAngle - u_time, 0.0); // Reduce the angle to zero
    float cosAngle = cos(angle); // Turn right to left "-" to reverse
    float sinAngle = sin(angle); // Turn right to left "-" to reverse

    // Shift coordinates to rotate around the center of the texture
    vec2 centered_uv = v_uv - 0.5;

    // Transform coordinates for rotation along the X axis
    vec2 rotated_uv = vec2(
      centered_uv.x,
      cosAngle * centered_uv.y + sinAngle * centered_uv.x // Apply rotation around center
    );

    // Returning the coordinates back
    rotated_uv += 0.5;

    // We limit the coordinates within [0, 1]
    rotated_uv = clamp(rotated_uv, 0.0, 1.0);

    vec4 color = texture(u_texture, rotated_uv);
    color.a *= u_alpha;
    outColor = color;
  }
`;