export const IMAGES = [
  "arrow.png",
  "ring1-r.jpg",
  "matcap-extra-white.png",
  "matcap-gray-glossy.png",
  "ring0.jpg",
  "ring1.jpg",
  "ring1.png",
  "border0.png",
  "border1.png",
  "spinner.png",
  "spinner-tr-neg.png",
  "images/test-ads-0.jpg",
  "images/test-ads-1.jpg",
  "images/test-ads-2.jpg",
  "images/test-ads-3.jpg",
  "images/test-ads-4.jpg",
  "images/test-ads-5.jpg",
  "images/test-ads-6.jpg",
  "images/test-ads-7.jpg",
  "images/test-ads-8.jpg",
  "images/t_mobile_logo.png",
  "images/slider-title/bloggers.png",
  "images/slider-title/fashion.png",
  "images/slider-title/sales.png",
  "images/slider-title/sponsors.png",
  "no-image.svg",
];
export const IMAGES_COUNT = IMAGES.length;
