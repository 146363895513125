export const vertexShader: string = `
    precision highp float;
    attribute vec3 POSITION;
    attribute vec3 NORMAL;
    attribute vec2 TEXCOORD_0;
    // used for instancing only
    attribute vec3 INSTANCE_POSITION;
    attribute vec3 INSTANCE_SCALE;
    attribute vec2 INSTANCE_COLOR;
    attribute vec2 INSTANCE_UV;
    uniform mat4 projectionMatrix;
    uniform mat4 viewMatrix;
    uniform mat4 lightProjectionMatrix;
    uniform mat4 lightViewMatrix;
    varying vec2 v_uv;
    varying vec3 v_normal;
    varying vec4 v_lightSpacePosition;
    varying vec3 v_lightDirection;
    varying vec2 v_instanceColor;
    varying vec3 v_viewPosition;
    varying vec4 v_projPosition;

    void main() {
        v_uv = TEXCOORD_0;
        v_normal = NORMAL;
        vec4 pos = vec4(POSITION, 1.0);
        v_lightSpacePosition = lightProjectionMatrix * lightViewMatrix * pos;
        v_lightDirection = normalize(vec3(lightViewMatrix[0][2], lightViewMatrix[1][2], lightViewMatrix[2][2]));
        v_instanceColor = INSTANCE_COLOR;

        v_viewPosition = - (viewMatrix * pos).xyz; // vector from vertex to camera

        v_projPosition = projectionMatrix * viewMatrix * pos;
        gl_Position = v_projPosition;
    }
  `;
export const vertexShader300: string = `#version 300 es
    precision highp float;
    in vec3 POSITION;
    in vec3 NORMAL;
    in vec2 TEXCOORD_0;
    // used for instancing only
    in vec3 INSTANCE_POSITION;
    in vec3 INSTANCE_SCALE;
    in vec2 INSTANCE_COLOR;
    in vec2 INSTANCE_UV;
    uniform mat4 projectionMatrix;
    uniform mat4 viewMatrix;
    uniform mat4 modelMatrix;
    uniform mat4 lightProjectionMatrix;
    uniform mat4 lightViewMatrix;
    uniform float N;
    out vec2 v_uv;
    out vec3 v_normal;
    out vec4 v_lightSpacePosition;
    out vec3 v_lightDirection;
    out vec2 v_instanceColor;
    out vec3 v_viewPosition;
    out vec4 v_projPosition;

    void main() {
        v_uv = TEXCOORD_0;
        v_normal = NORMAL;
        vec4 pos = vec4(POSITION, 1.0);
        v_lightSpacePosition = lightProjectionMatrix * lightViewMatrix * pos;
        v_lightDirection = normalize(vec3(lightViewMatrix[0][2], lightViewMatrix[1][2], lightViewMatrix[2][2]));
        v_instanceColor = INSTANCE_COLOR;

        v_viewPosition = - (viewMatrix * pos).xyz; // vector from vertex to camera

        v_projPosition = projectionMatrix * viewMatrix * pos;
        gl_Position = v_projPosition;
    }
  `;
