import { rootElement } from "index";
import { createPortal } from "react-dom";
import { ImSpinner2 } from "react-icons/im";

export const Loading = () =>
  createPortal(
    <div className="fixed top-0 bg-white w-full h-screen flex items-center justify-center z-[1000]">
      {/* <InitialStateFiller /> */}
      <div className="flex flex-col justify-center">
        <ImSpinner2 className="w-12 h-12 animate-spin" />
        {/* <AiOutlineLoading3Quarters className="w-12 h-12 animate-spin" /> */}
      </div>
    </div>,
    rootElement
  );

export const LoadingHTML = () => (
  <div className="bg-white w-full h-screen flex items-center justify-center z-[1000]">
    {/* <InitialStateFiller /> */}
    <div className="flex flex-col justify-center">
      <ImSpinner2 className="w-12 h-12 animate-spin" />
      {/* <AiOutlineLoading3Quarters className="w-12 h-12 animate-spin" /> */}
    </div>
  </div>
);
