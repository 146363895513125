import { SHADOWMAP_SIZE } from "config";

export const penumbraFragmentShader = `
  precision highp float;
  // depth map
  uniform sampler2D shadowMap;

  varying vec2 v_uv;

  const int radius = 3;
  const float r = float(radius);
  const float PI = ${Math.PI.toFixed(4)};

  void main() {
    vec2 uv = v_uv;
    vec4 color = vec4(0.0);
    int samples = 0;
    int hits = 0;

    float texelSize = 3.0 / 2048.;//${SHADOWMAP_SIZE.toFixed(1)};

    vec4 mainColor = texture2D(shadowMap, uv);
    vec4 mc = mainColor;

    // retrieve depth value from the depth map
    float depth = texture2D(shadowMap, uv).a;
    float m = depth;
    for (int x = -radius; x <= radius; x++) {
      for (int y = -radius; y <= radius; y++) {
        vec2 offset = vec2(x, y) * texelSize;
        if (length(offset) <= r) {
          float current = texture2D(shadowMap, uv + offset).a;
          if (current > m) {
            m = current;
            mc = texture2D(shadowMap, uv + offset);
          }
        }
      }
    }
    for (int x = -radius; x <= radius; x++) {
      for (int y = -radius; y <= radius; y++) {
        vec2 offset = vec2(x, y) * texelSize;
        float d = texture2D(shadowMap, uv + offset).a;
        if (length(offset) <= r) {
          color = texture2D(shadowMap, uv + offset);
          if ((color.xyz != mainColor.xyz)) {
            hits++;
          }
          samples++;
        }
      }
    }

    float f = float(hits) / float(samples) * 2.;
    gl_FragColor = vec4(depth, mc.gb, f);
  }

`;
