import { IDENTITY4 } from "features/draw-call/core/constants";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { elementsToInstanced } from "features/draw-call/core/geometry/instanced-elements";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { UniformValues } from "features/draw-call/core/types";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { vec3, mat4 } from "gl-matrix";
import { hextogl } from "utils/hextogl";

interface ClusterRingOptions {
  geometry: ElementsBufferGeometry;
  program: ShaderProgram;
  camera: OrbitCamera;
  translations: vec3[];
  diameters: number[];
  count: number;
}

export class ClusterRingsRenderer extends RenderTask {
  camera: OrbitCamera;
  color0: Float32Array;
  color1: Float32Array;
  time: number;
  density: number;
  speed: number;

  constructor(gl: WebGL2RenderingContext, options: ClusterRingOptions) {
    super(gl);
    this.camera = options.camera;
    this.color0 = hextogl("#b1b1b1ff");
    this.color1 = hextogl("#b1b1b1ff");
    this.time = 0;
    this.density = 3;
    this.speed = 1;

    this.shaderPrograms.main = options.program;

    const { count, translations, diameters } = options;

    // create instance buffers
    const instancePos = new Float32Array(count * 3);
    const instanceScale = new Float32Array(count * 3);

    // fill instance buffers
    for (let i = 0; i < count; i++) {
      const index = i * 3;
      instancePos[index] = translations[i][0];
      instancePos[index + 1] = translations[i][1];
      instancePos[index + 2] = translations[i][2];
      instanceScale[index] = diameters[i];
      instanceScale[index + 1] = 2.5;
      instanceScale[index + 2] = diameters[i];
    }
    // create geometry
    this.geometries.main = elementsToInstanced(options.geometry, {
      instanceCount: count,
      instanceBuffers: {
        INSTANCE_POSITION: new VertexBuffer(gl, { data: instancePos, size: 3 }),
        INSTANCE_SCALE: new VertexBuffer(gl, { data: instanceScale, size: 3 }),
      },
    });
  }
  render(u?: UniformValues): void {
    super.render();
    const gl = this.gl;
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      color0: this.color0,
      color1: this.color1,
      // set model matrix to identity if it wasn't passed down from parent
      //   modelMatrix: u?.modelMatrix || IDENTITY4,
      time: this.time * 0.0001 * this.speed,
      density: this.density,
    };
    gl.depthMask(false);
    gl.enable(gl.CULL_FACE);
    // do the front and back faces separately
    // so that the back was visible through semi-transparent front
    gl.cullFace(gl.FRONT);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.cullFace(gl.BACK);
    this.geometries.main.drawCall(this.shaderPrograms.main, uniforms);
    gl.disable(gl.CULL_FACE);
    gl.depthMask(true);
  }
}
