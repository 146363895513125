export const centerRingFragmentShader300: string = `#version 300 es
    precision highp float;
   
    uniform sampler2D u_texture;
    uniform vec4 color0;
    uniform vec4 color1;
    uniform float time;
    uniform float density;

    in vec2 v_uv;

    out vec4 FragColor;

    void main() {
        float factor = 2.;//density;
        vec2 uv = fract((v_uv + vec2(0., time)) * vec2(33. * factor, 5. * factor));
        float d = smoothstep(0.15, 0.7, distance(uv, vec2(0.5)));
        vec4 c = mix(color0, color1, d);

        vec2 t_uv = mat2(0., 1., -1., 0.) * (v_uv - 0.5) + 0.5;
        t_uv = fract(t_uv * vec2(1., factor));
        vec4 t = texture(u_texture, t_uv);
        vec4 mainColor = vec4(c.rgb, (1. - t.r) * c.a);
        vec4 semiOpaque = vec4(color1.rgb, 0.075 * v_uv.y);
        FragColor = mix(semiOpaque, mainColor, mainColor.a);
    }
`;
