// import React, { useState, useEffect, FC, ReactNode } from 'react';
// import { useSectors, Sector, useAppState, Cluster } from "features/global-state/app-context";
// import { ClusterComponent } from "./cluster";
// import { ClusterTopsComponent } from "./cluster-tops";
// import { ClusterCaption } from "./cluster-caption";

// import { PromoPrice } from "./promo-price";
// import { SectorHoverComponent } from "./sector-hover";
// import { useCamera } from "../scene-context";
// import { sectorCoords } from "math/sector-coords";
// import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
// import { vec3 } from 'gl-matrix';

// export const getDistanceToCamera = (sector: Sector, camera: OrbitCamera): number => {
//   const { center } = sectorCoords(sector);
//   return -vec3.distance([center.x, center.y, 0], camera.position);
// };



// export interface ClusterContext {
//   cluster: Cluster;
//   sectorIndex: number;
//   clusterIndex: number;
// }

// const clusterContext = React.createContext<ClusterContext | null>(null);

// export const useCluster = () => {
//   const cluster = React.useContext(clusterContext);
//   if (!cluster) {
//       throw new Error("useCluster must be used within a ClusterProvider");
//   }
//   return cluster;
// };

// export const ForEachCluster: FC<{ children?: ReactNode }> = ({ children }) => {
//   const { sector, sectorIndex } = useSector();
//   return (
//       <>
//           {sector.clusters.map((cluster, clusterIndex) => (
//               <clusterContext.Provider
//                   key={clusterIndex}
//                   value={{ cluster, sectorIndex, clusterIndex }}
//               >
//                   {children}
//               </clusterContext.Provider>
//           ))}
//       </>
//   );
// };

// export interface SectorContext {
//   sector: Sector;
//   sectorIndex: number;
// }

// const sectorContext = React.createContext<SectorContext | null>(null);
// export const useSector = () => {
//   const sector = React.useContext(sectorContext);
//   if (!sector) {
//       throw new Error("useSector must be used within a SectorProvider");
//   }
//   return sector;
// };
// export const ForEachSector: FC<{ children?: ReactNode }> = ({ children }) => {
//   const sectors = useSectors();
//   return (
//       <>
//           {sectors.map((sector, sectorIndex) => (
//               <sectorContext.Provider key={sectorIndex} value={{ sector, sectorIndex }}>
//                   {children}
//               </sectorContext.Provider>
//           ))}
//       </>
//   );
// };

// export const AsyncSectorRenderer: FC = () => {
//   const sectors = useSectors();
//   const camera = useCamera();
//   const [sortedSectors, setSortedSectors] = useState<Sector[]>([]);
//   const [renderedSectors, setRenderedSectors] = useState<number[]>([]);

//   useEffect(() => {
//       const sorted = [...sectors].sort((a, b) => {
//           const distA = getDistanceToCamera(a, camera);
//           const distB = getDistanceToCamera(b, camera);
//           return distA - distB;
//       });
//       setSortedSectors(sorted);
//   }, [sectors, camera]);

//   useEffect(() => {
//       let isMounted = true;
//       const renderNextSector = async () => {
//           if (renderedSectors.length < sortedSectors.length) {
//               await new Promise(resolve => setTimeout(resolve, 1000));
//               if (isMounted) {
//                   setRenderedSectors(prev => [...prev, prev.length]);
//               }
//           }
//       };
//       renderNextSector();
//       return () => { isMounted = false; };
//   }, [sortedSectors, renderedSectors]);

//   return (
//       <>
//           {renderedSectors.map(index => (
//               <SectorRenderer key={index} sector={sortedSectors[index]} sectorIndex={index} />
//           ))}
//       </>
//   );
// };

// const SectorRenderer: FC<{ sector: Sector; sectorIndex: number }> = ({ sector, sectorIndex }) => {
//   return (
//       <sectorContext.Provider value={{ sector, sectorIndex }}>
//           <ForEachCluster>
//               <ClusterComponent />
//               <PromoPrice />
//               <ClusterTopsComponent />
//           </ForEachCluster>
//           <ForEachCluster>
//               <ClusterCaption />
//           </ForEachCluster>
//           <SectorHoverComponent />
//       </sectorContext.Provider>
//   );
// };

import {
  Cluster,
  Favorite,
  Sector,
  useAppState,
  useSectors,
  useFavorites
} from "features/global-state/app-context";
import React, { FC, ReactNode, useState, useEffect } from "react";
import { ClusterComponent } from "./cluster";
import { Caption } from "./caption";
import { ClusterTopsComponent } from "./cluster-tops";
import { useParams, useNavigate } from "react-router-dom";
import { setAuthenticatedCookie } from "features/ui/components/auth/auth"

export interface ClusterContext {
  cluster: Cluster;
  sectorIndex: number;
  clusterIndex: number;
}

const clusterContext = React.createContext<ClusterContext | null>(null);

export const useCluster = () => {
  const cluster = React.useContext(clusterContext);
  if (!cluster) {
    throw new Error("useCluster must be used within a ClusterProvider");
  }
  return cluster;
};

export const ForEachCluster: FC<{ children?: ReactNode }> = ({ children }) => {
  const sectors = useSectors();
  return (
    <>
      {sectors.map((sector, i) => (
        <React.Fragment key={i}>
          {sector.clusters.map((cluster, j) => (
            <React.Fragment key={j}>
              <clusterContext.Provider
                value={{ cluster, sectorIndex: i, clusterIndex: j }}
              >
                {children}
              </clusterContext.Provider>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export interface SectorContext {
  sector: Sector;
  sectorIndex: number;
}

const sectorContext = React.createContext<SectorContext | null>(null);
export const useSector = () => {
  const sector = React.useContext(sectorContext);
  if (!sector) {
    throw new Error("useSector must be used within a SectorProvider");
  }
  return sector;
};
export const ForEachSector: FC<{ children?: ReactNode }> = ({ children }) => {
  const sectors = useSectors();
  return (
    <>
      {sectors.map((sector, i) => (
        <React.Fragment key={i}>
          <sectorContext.Provider value={{ sector, sectorIndex: i }}>
            {children}
          </sectorContext.Provider>
        </React.Fragment>
      ))}
    </>
  );
};

export const ForEachFavorites: FC<{ children?: ReactNode }> = ({ children }) => {
  const favorites = useFavorites();
  return (
    <>
      {favorites &&
        <React.Fragment>
          <clusterContext.Provider
            value={{
              cluster: {
                c: 999,
                ids: favorites.map(fav => (console.log("fav.image_id", fav.image_id), {
                  ...fav,
                  id: (fav as any).asin,
                  h: (fav as any).h || 1,
                  l: (fav as any).l || 1,
                  w: (fav as any).w || 1,
                  image_id: fav.image_id,
                })),
                image: "favorites",
                r: 10,
                text: "Favorites",
                x: 0,
                y: 0
              },
              sectorIndex: 999,
              clusterIndex: 0
            }}
          >
            {children}
          </clusterContext.Provider>
        </React.Fragment>
      }
    </>
  );
};