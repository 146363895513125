import {
  AttributeLocationCollection,
  NumberCollection,
  OutputFramebuffer,
  VertexBufferCollection,
} from "features/draw-call/core/types";
import {
  ElementsBufferGeometry,
  ElementsBufferGeometryOptions,
} from "./elements-buffer-geometry";
import { GLContext } from "features/draw-call/core/gl-utils";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";

export interface InstancedElementsOptions
  extends ElementsBufferGeometryOptions {
  instanceBuffers: VertexBufferCollection;
  instanceCount: number;
}

// instanced version of ElementsBufferGeometry
export class InstancedElements extends ElementsBufferGeometry {
  instanceBuffers: VertexBufferCollection = {};
  instanceCount: number = 0;
  //   ext: ANGLE_instanced_arrays;
  // instancing is only supported in webgl2 by default
  gl: WebGL2RenderingContext;
  constructor(gl: WebGL2RenderingContext, options?: InstancedElementsOptions) {
    super(gl, options); // this one is supposed to do Object.assign(this, options)
    this.gl = gl;
    Object.assign(this, options);
    // const ext = gl.getExtension("ANGLE_instanced_arrays");
    // if (!ext) {
    //   throw new Error("ANGLE_instanced_arrays not supported");
    // }
    // this.ext = ext;
  }
  createVertexBuffers(): void {
    super.createVertexBuffers();
    for (let key in this.instanceBuffers) {
      this.instanceBuffers[key].create();
    }
  }
  updateVertexBuffers(): void {
    super.updateVertexBuffers();
    for (let key in this.instanceBuffers) {
      this.instanceBuffers[key].update();
    }
  }
  enableAttributes(
    locations: AttributeLocationCollection,
    divisors?: NumberCollection
  ) {
    for (let key in locations) {
      if (this.vertexBuffers[key] !== undefined) {
        this.vertexBuffers[key].enableAttribute(locations[key]);
      }
      if (this.instanceBuffers[key] !== undefined) {
        this.instanceBuffers[key].enableAttribute(locations[key]);
        this.gl.vertexAttribDivisor(locations[key], divisors?.[key] || 1);
      }
    }
  }
  freeVertexBuffers(): void {
    for (let key in this.instanceBuffers) {
      this.instanceBuffers[key].free();
    }
    super.freeVertexBuffers();
  }
  draw(): void {
    const gl = this.gl;
    // const ext = this.ext;
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer?.glBuffer!);
    gl.drawElementsInstanced(
      gl.TRIANGLES,
      this.count,
      gl.UNSIGNED_SHORT,
      0,
      this.instanceCount
    );
  }
}

// promote a regular ElementsBufferGeometry to an instanced one
export function elementsToInstanced(
  geometry: ElementsBufferGeometry,
  options: { instanceBuffers: VertexBufferCollection; instanceCount: number }
): InstancedElements {
  return new InstancedElements(geometry.gl as WebGL2RenderingContext, {
    vertexBuffers: geometry.vertexBuffers,
    indexBuffer: geometry.indexBuffer!,
    count: geometry.count,
    ...options,
  });
}
