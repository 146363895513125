import { useAppState, useSectors } from "features/global-state/app-context";
import { Fragment, useEffect, useMemo } from "react";
import { ACCENT_COLOR, MESH_PADDING } from "config";
import { Caption } from "./caption";
import {
  useCamera,
  useSharedGeometry,
  useSharedShaderProgram,
} from "../scene-context";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { useControls } from "leva";
import { hextogl } from "utils/hextogl";
import { ClusterRingsRenderer } from "../render-tasks/cluster-rings";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { vec3 } from "gl-matrix";

export const ClusterRings = () => {
  const sectors = useSectors();
  const { gl, rl } = useDC();
  const camera = useCamera();
  const geometry = useSharedGeometry("cylinder") as ElementsBufferGeometry;
  const program = useSharedShaderProgram("clusterRing");

  // calculate (and memoize) translations and radii
  const translations = useMemo(
    () =>
      sectors.flatMap(sector =>
        sector.clusters.map(cluster => vec3.fromValues(cluster.x, 0, cluster.y))
      ),
    []
  );
  const diameters = useMemo(
    () =>
      sectors.flatMap(sector => sector.clusters.map(cluster => 2 * cluster.r)),
    []
  );
  const count = diameters.length;

  // create render task
  const renderTask = useMemo(
    () =>
      new ClusterRingsRenderer(gl, {
        geometry,
        program,
        camera,
        translations,
        diameters,
        count,
      }),
    []
  );
  useEffect(() => {}, []);

  // specify leva options
  /*const { color0, color1, density, speed } = useControls({
    color0: "#ffffff", //"#fff7eb",
    color1: "#ffffff", //ACCENT_COLOR,
    density: { min: 0.1, max: 10, value: 4.0 },
    speed: { min: 0.1, max: 1, value: 0.3 },
  });
  renderTask.color0 = hextogl(color0 + "ff");
  renderTask.color1 = hextogl(color1 + "00");
  renderTask.density = density;
  renderTask.speed = speed;*/
  renderTask.color0 = hextogl("#ffffff" + "ff");
  renderTask.color1 = hextogl("#ffffff" + "00");
  renderTask.density = 4.0;
  renderTask.speed = 0.3;
  useFrame(rl => {
    renderTask.time = rl.time;
    renderTask.render();
  });
  return null;
};
