export async function loadVideo(url: string): Promise<HTMLVideoElement> {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = url;
    //   video.muted = "muted" // autoplay="true"
      video.onloadeddata = () => resolve(video);
      video.onerror = () => reject(new Error("Failed to load video"));
      video.load();
    });
} 
