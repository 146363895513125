import { useEffect } from "react";
import { useCamera } from "../scene-context";
import { vec3 } from "gl-matrix";
import { usePhysics } from "features/resource-loader";
import { useAppState, useSectors } from "features/global-state/app-context";

// changes cursor depending on the object hovered
export const HoverManager = () => {
  const camera = useCamera();
  const physics = usePhysics();
  const sectors = useSectors();
  // change cursor on hover by updating the body class
  useEffect(() => {
    return camera.on("pointermovePhysics", handle => {
      if (handle) {
        window.document.body.classList.add("cursor-pointer");
        physics.emitter.emit("hover", handle);
      } else {
        window.document.body.classList.remove("cursor-pointer");
        physics.emitter.emit("unhover");
      }
    });
  }, []);
  return null;
};
