// render loop class

import { RenderLoopCallback } from "./types";
import { GLContext } from "./gl-utils";

// handles window.requestAnimationFrame
export class RenderLoop {
  gl: GLContext;
  time: number;
  frame: number;
  delta: number;
  constructor(gl: GLContext, options: RenderLoopOptions = {}) {
    this.gl = gl;
    this.time = 0;
    this.frame = 0;
    this.delta = 0;
    Object.assign(this, options || {});
  }
  start() {
    this.time = performance.now();
    if (this.onStart) this.onStart(this);
    this._render(this.time);
  }
  private _raf: number | null = null;
  private _boundRender: FrameRequestCallback = this._render.bind(this);
  private _render(time: number) {
    this._raf = window.requestAnimationFrame(this._boundRender);
    this.delta = time - this.time;
    this.time = time;
    if (this.onFrame) this.onFrame(this);
    this.frame++;
  }
  pause() {
    if (this._raf !== null) {
      window.cancelAnimationFrame(this._raf);
    }
    if (this.onPause) this.onPause(this);
  }
  onStart?: RenderLoopCallback;
  onFrame?: RenderLoopCallback;
  onPause?: RenderLoopCallback;
}

export interface RenderLoopOptions {
  onStart?: RenderLoopCallback;
  onFrame?: RenderLoopCallback;
  onPause?: RenderLoopCallback;
}
