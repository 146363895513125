export const captionFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D backgroundTexture;
uniform sampler2D textTexture;
uniform sampler2D imageTexture;
uniform float animationState;
uniform float transparency;

void main() {
    vec2 uv = v_uv;
    vec2 st = v_uv;
    vec2 xy = v_uv;
    // compute background uv
    uv.y = (uv.y - 0.5) * 2. * (1.0 - 0.5 * animationState) + 0.5;
    if (uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0 || uv.y > 1.0) {
        discard;
    }
    // compute text uv
    st.y = (st.y - 0.5) * 2. + 0.5;
    // compute image uv
    xy = v_uv;
    // sample textures
    vec4 bg = texture(backgroundTexture, uv);
    vec4 fg = texture(textTexture, st);
    vec4 fg2 = texture(imageTexture, xy);
    // make text fade out and image fade in
    fg.a *= 1. - animationState;
    fg2.a *= animationState;
    // mix
    vec4 color = mix(bg, fg, fg.a);
    color = mix(color, fg2, fg2.a);
    // undo alpha premultiplication
    color.rgb /= color.a + 0.00001;
    // apply transparency
    color.a *= 1. - transparency;
    FragColor = color;
}
`;

export const loadingCaptionFragmentShader300 = `#version 300 es
    precision highp float;

    in vec2 v_uv;

    out vec4 FragColor;

    uniform sampler2D backgroundTexture;
    uniform sampler2D textTexture;
    uniform sampler2D imageTexture;
    // uniform sampler2D spinnerTexture;
    uniform float animationState;
    uniform float transparency;
    // uniform float spinnerRotation;
    // uniform bool isLoading;
    uniform mat2 rotationMatrix;

    void main() {
        vec2 uv = v_uv;
        vec2 st = v_uv;
        vec2 xy = rotationMatrix * (v_uv - 0.5) + 0.5;
        // Compute background UV
        uv.y = (uv.y - 0.5) * 2.0 * (1.0 - 0.5 * animationState) + 0.5;
        if (uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0 || uv.y > 1.0) {
            discard;
        }
        // Compute text UV
        st.y = (st.y - 0.5) * 2.0 + 0.5;
        // Apply scale transformation to the rotated image
        vec2 centeredXY = (xy - 0.5) * 2.0 + 0.5;
        // Sample textures
        vec4 bg = texture(backgroundTexture, uv);
        vec4 fg = texture(textTexture, st);
        vec4 fg2 = texture(imageTexture, centeredXY);
        // Make text fade out and image fade in
        fg.a *= 1.0 - animationState;
        fg2.a *= animationState;
        // Mix background, text, and image
        vec4 color = mix(bg, fg, fg.a);
        color = mix(color, fg2, fg2.a);

        // // Add spinner if loading
        // if (isLoading) {
        //     // Compute spinner UV
        //     vec2 spinnerUV = v_uv - 0.5;
        //     float spinnerAngle = spinnerSpeed * 2.0; // Ускорение вращения спиннера
        //     mat2 spinnerRotationMatrix = mat2(cos(spinnerAngle), -sin(spinnerAngle), sin(spinnerAngle), cos(spinnerAngle));
        //     spinnerUV = spinnerRotationMatrix * spinnerUV + 0.5;
        //     vec4 spinnerColor = texture(spinnerTexture, spinnerUV);
        //     // Invert spinner color
        //     vec4 invertedSpinnerColor = vec4(1.0 - spinnerColor.rgb, spinnerColor.a);
        //     // Mix inverted spinner with the existing color
        //     color = mix(color, invertedSpinnerColor, invertedSpinnerColor.a);
        // }

        // Undo alpha premultiplication
        color.rgb /= color.a + 0.00001;
        // Apply transparency
        color.a *= 1.0 - transparency;
        FragColor = color;    
    }
`;