import { useEffect, useMemo } from "react";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { mat4 } from "gl-matrix";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import {
  useCamera,
  useSharedShaderProgram,
} from "features/fabric/scene-context";
import {
  useAppState,
  useSectors,
  Sector,
} from "features/global-state/app-context";
import {
  vertexShader,
  vertexShader300,
} from "features/fabric/shaders/vertex-shader";

interface SectorOptions {
  gl: WebGL2RenderingContext;
  gridUnits?: number[];
  innerRadius?: number;
  height?: number;
  camera: OrbitCamera;
  sectors: Sector[];
}

class SectorAll extends RenderTask {
  gridUnits: number[];
  sectors: Sector[] = [];
  innerRadius: number;
  height: number;
  camera: OrbitCamera;
  readonly gap: number = 0.15;
  readonly sectorGap: number = 0.05;
  readonly cubeSize: number = 1;

  constructor(gl: WebGL2RenderingContext, options: SectorOptions) {
    super(gl);
    this.gridUnits = options.gridUnits || [10000, 12000, 4000, 15000, 25000];
    this.sectors = options.sectors;
    this.innerRadius = options.innerRadius || 170;
    this.height = options.height || 0.9;
    this.camera = options.camera;

    this.shaderPrograms.sector = new ShaderProgram(gl, {
      vertexShader: `#version 300 es
        precision highp float;

        in vec3 position;
        in vec2 uv;
        in vec3 normal;
        
        uniform mat4 modelMatrix;
        uniform mat4 viewMatrix;
        uniform mat4 projectionMatrix;
        uniform float angleStart;
        uniform float angleWidth;
        
        out vec2 vUv;
        out vec3 vNormal;
        out vec3 vWorldPos;
        out vec3 vPosition;
        
        void main() {
          vec4 worldPosition = modelMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * viewMatrix * worldPosition;
          
          vUv = uv;
          vNormal = normalize((modelMatrix * vec4(normal, 0.0)).xyz);
          vWorldPos = worldPosition.xyz;
          vPosition = position;
        }
      `,
      fragmentShader: `#version 300 es
precision highp float;

uniform vec3 baseColor;
uniform float cubeSize;
uniform float gap;
uniform float innerRadius;
uniform float outerRadius;
uniform float angleStart;
uniform float angleWidth;
uniform mat4 viewMatrix;
uniform float lineWidth;
uniform vec3 gridColor;

in vec2 vUv;
in vec3 vNormal;
in vec3 vWorldPos;
in vec3 vPosition;

out vec4 fragColor;

float getGridLine(float coordinate, float cellSize, float lineWidth) {
    float halfWidth = lineWidth * 0.5;
    float cellPosition = coordinate / cellSize;
    float wrapped = abs(fract(cellPosition + 0.5) - 0.5);
    return 1.0 - smoothstep(halfWidth - 0.001, halfWidth + 0.001, wrapped);
}

void main() {
  // Calculate distance from camera to fragment
  vec4 viewPosition = viewMatrix * vec4(vWorldPos, 1.0);
  float distanceFromCamera = length(viewPosition.xyz);

  // Calculate grid fade based on distance
  float startFade = 60.0;  // Расстояние, с которого начинается затухание
  float endFade = 240.0;    // Расстояние, на котором сетка полностью исчезает
  float gridOpacity = 1.0 - smoothstep(startFade, endFade, distanceFromCamera);
  
  // Fixed grid size regardless of distance
  float gridSize = cubeSize + gap;
  
  // Calculate grid lines
  float xLine = getGridLine(vPosition.x, gridSize, lineWidth);
  float zLine = getGridLine(vPosition.z, gridSize, lineWidth);
  
  // Combine grid lines
  float gridPattern = max(xLine, zLine);
  
  // Sector bounds check
  float angle = atan(vPosition.z, vPosition.x);
  if (angle < 0.0) angle += 2.0 * 3.14159;
  
  float normalizedAngle = angle - angleStart;
  if (normalizedAngle < 0.0) normalizedAngle += 2.0 * 3.14159;

    float radius = length(vPosition.xz);
    bool inSector = normalizedAngle <= angleWidth && radius >= innerRadius && radius <= outerRadius;

    if (!inSector) {
      // discard;
    }

    // Расчет освещения
    vec3 lightDir = normalize(vec3(1.0, 1.0, 1.0));
    float ambient = 0.3;
    float diffuse = max(dot(vNormal, lightDir), 0.0);

    // Определяем, является ли это боковой гранью
    bool isSideFace = (vNormal.y < 0.8) && (vNormal.y > -1.5);

    // Если это боковая грань, делаем цвет ярче
    vec3 adjustedFigureColor = isSideFace ? baseColor * 2.2 : baseColor;

    // Расчет финального цвета
    vec3 figureColor = adjustedFigureColor;
    vec3 lineColor = gridColor;
    vec3 color = mix(figureColor, lineColor, gridPattern * gridOpacity);

    // Применение освещения
    vec3 finalColor = color * (ambient + diffuse);

    // Убеждаемся, что значение цвета не превышает 1.0
    fragColor = vec4(clamp(finalColor, 0.0, 1.0), 1.0);
}
      `,
    });
  }

  private createSectorShape(
    angleStart: number,
    angleWidth: number,
    outerRadius: number
  ): {
    positions: number[];
    normals: number[];
    uvs: number[];
    indices: number[];
  } {
    // Добавляем отступ к начальному и конечному углу для создания промежутка
    const gapAngle = this.sectorGap / 2;
    angleStart += gapAngle;
    angleWidth -= this.sectorGap;

    const segments = 32;
    const positions: number[] = [];
    const normals: number[] = [];
    const uvs: number[] = [];
    const indices: number[] = [];
    const halfHeight = this.height / 2;
    const angleEnd = angleStart + angleWidth;

    // Функция для добавления точки в массивы
    const addVertex = (
      x: number,
      y: number,
      z: number,
      u: number,
      v: number,
      nx: number,
      ny: number,
      nz: number
    ) => {
      positions.push(x, y, z);
      normals.push(nx, ny, nz);
      uvs.push(u, v);
    };

    // Создаем точки для верхней и нижней поверхностей
    for (let i = 0; i <= segments; i++) {
      const theta = angleStart + angleWidth * (i / segments);
      const cosTheta = Math.cos(theta);
      const sinTheta = Math.sin(theta);

      const innerX = this.innerRadius * cosTheta;
      const innerZ = this.innerRadius * sinTheta;

      const outerX = outerRadius * cosTheta;
      const outerZ = outerRadius * sinTheta;

      const u = i / segments;

      addVertex(innerX, halfHeight, innerZ, 0, u, 0, 1, 0);
      addVertex(outerX, halfHeight, outerZ, 1, u, 0, 1, 0);

      addVertex(innerX, -halfHeight, innerZ, 0, u, 0, -1, 0);
      addVertex(outerX, -halfHeight, outerZ, 1, u, 0, -1, 0);
    }

    for (let i = 0; i < segments; i++) {
      const baseIndex = i * 4;
      indices.push(
        baseIndex,
        baseIndex + 1,
        baseIndex + 4,
        baseIndex + 1,
        baseIndex + 5,
        baseIndex + 4,
        baseIndex + 2,
        baseIndex + 6,
        baseIndex + 3,
        baseIndex + 3,
        baseIndex + 6,
        baseIndex + 7,
        baseIndex,
        baseIndex + 4,
        baseIndex + 2,
        baseIndex + 2,
        baseIndex + 4,
        baseIndex + 6,
        baseIndex + 1,
        baseIndex + 3,
        baseIndex + 5,
        baseIndex + 3,
        baseIndex + 7,
        baseIndex + 5
      );
    }

    return { positions, normals, uvs, indices };
  }

  private calculateOuterRadius(units: number, angleWidth: number): number {
    // Учитываем промежуток между секторами при расчете площади
    const effectiveAngle = angleWidth - this.sectorGap;

    // Вычисляем количество рядов кубиков от центра
    const cellSize = this.cubeSize + this.gap;
    const cellArea = cellSize * cellSize;

    // Площадь сектора = количество ячеек * площадь одной ячейки
    const sectorArea = units * cellArea;

    // Используем формулу площади сектора: S = (θ/2)(R² - r²)
    // где θ - угол в радианах, R - внешний радиус, r - внутренний радиус
    // Отсюда: R = √((2S/θ) + r²)
    const outerRadius = Math.sqrt(
      (2 * sectorArea) / effectiveAngle + Math.pow(this.innerRadius, 2)
    );

    return outerRadius;
  }

  render(): void {
    super.render();
    const gl = this.gl;
    const program = this.shaderPrograms.sector.getProgram();

    gl.useProgram(program);

    // Set common uniforms
    const modelMatrix = mat4.create();
    // mat4.identity(modelMatrix);
    gl.uniformMatrix4fv(
      gl.getUniformLocation(program, "modelMatrix"),
      false,
      modelMatrix
    );
    gl.uniformMatrix4fv(
      gl.getUniformLocation(program, "viewMatrix"),
      false,
      this.camera.viewMatrix
    );
    gl.uniformMatrix4fv(
      gl.getUniformLocation(program, "projectionMatrix"),
      false,
      this.camera.projectionMatrix
    );

    this.sectors.forEach((sector, i) => {
      const angleStart = sector.a;
      const angleWidth = sector.aw;
      const outerRadius = this.calculateOuterRadius(
        this.gridUnits[i],
        angleWidth
      );

      const geometry = this.createSectorShape(
        angleStart,
        angleWidth,
        outerRadius
      );

      // Create and bind vertex buffer
      const positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(geometry.positions),
        gl.STATIC_DRAW
      );
      const positionLocation = gl.getAttribLocation(program, "position");
      gl.enableVertexAttribArray(positionLocation);
      gl.vertexAttribPointer(positionLocation, 3, gl.FLOAT, false, 0, 0);

      const normalBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, normalBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(geometry.normals),
        gl.STATIC_DRAW
      );
      const normalLocation = gl.getAttribLocation(program, "normal");
      gl.enableVertexAttribArray(normalLocation);
      gl.vertexAttribPointer(normalLocation, 3, gl.FLOAT, false, 0, 0);

      const uvBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, uvBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(geometry.uvs),
        gl.STATIC_DRAW
      );
      const uvLocation = gl.getAttribLocation(program, "uv");
      gl.enableVertexAttribArray(uvLocation);
      gl.vertexAttribPointer(uvLocation, 2, gl.FLOAT, false, 0, 0);

      const indexBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
      gl.bufferData(
        gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(geometry.indices),
        gl.STATIC_DRAW
      );

      gl.uniform1f(gl.getUniformLocation(program, "cubeSize"), this.cubeSize);
      gl.uniform1f(gl.getUniformLocation(program, "gap"), this.gap);
      gl.uniform1f(
        gl.getUniformLocation(program, "innerRadius"),
        this.innerRadius
      );
      gl.uniform1f(gl.getUniformLocation(program, "outerRadius"), outerRadius);
      gl.uniform1f(gl.getUniformLocation(program, "angleStart"), angleStart);
      gl.uniform1f(gl.getUniformLocation(program, "angleWidth"), angleWidth);
      gl.uniform3f(
        gl.getUniformLocation(program, "baseColor"),
        0.97,
        0.97,
        0.97
      );
      gl.uniform1f(gl.getUniformLocation(program, "lineWidth"), 0.025);
      gl.uniform3f(gl.getUniformLocation(program, "gridColor"), 0.7, 0.7, 0.7);

      // gl.disable(gl.CULL_FACE);

      gl.drawElements(
        gl.TRIANGLES,
        geometry.indices.length,
        gl.UNSIGNED_SHORT,
        0
      );
    });
  }
}

export const SectorWithGrid = () => {
  const { gl, rl } = useDC();
  const camera = useCamera();
  const sectors = useSectors();

  const arr = [10000, 12000, 4000, 15000, 9000, 6000, 13000, 8500];
  const gridUnits = sectors.map((sector, i) => {
    return Math.floor(sector.aw * arr[i]);
  });

  const innerRadius = 180;
  const height = 1;

  useEffect(() => {
    if (gl) {
      const canvas = gl.canvas as HTMLCanvasElement;
      const displayWidth = canvas.clientWidth;
      const displayHeight = canvas.clientHeight;

      if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
        canvas.width = displayWidth;
        canvas.height = displayHeight;
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
    }
  }, [gl]);

  const renderTask = useMemo(
    () =>
      new SectorAll(gl as WebGL2RenderingContext, {
        gl,
        sectors,
        gridUnits,
        innerRadius,
        height,
        camera,
      }),
    [gl, camera, sectors]
  );

  useFrame(() => {
    if (gl) {
      renderTask.render();
    }
  });

  return null;
};
