import {
  RenderTask,
  RenderTaskOptions,
} from "features/draw-call/core/render-task/render-task";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { Box, Cluster, useViewMode } from "features/global-state/app-context";
import { AppState, VIEW_MODES } from "features/global-state/state-manager";
import { mat4 } from "gl-matrix";
import { ClusterContext } from "../components/sectors";
import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { clamp, PI } from "math";
import { Texture } from "features/draw-call/core/texture";
import { DynamicTextureLoader } from "features/resource-loader/dynamic-texture-loader";
import { BOX_MARGIN, RICH_PRODUCT_COUNT, SVG_SCALE } from "config";
import { RenderLoop } from "features/draw-call/core/render-loop";
import { imageToBase64 } from "./caption";
import { escapeHtml } from "utils/escape-html";

export const productToSvg = (product: Box) => {
  let title = escapeHtml(product.title || "");
  if (title.length > 60) {
    title = title.slice(0, 58) + "...";
  }
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="${
    1024 * SVG_SCALE
  }" height="${1024 * SVG_SCALE}">
        <image x="0" y="0" width="1024"  height="1024" href="" />
        <rect x="0" y="724" width="1024" height="300" fill="rgba(255,255,255,0.75)"/>
<foreignObject x="75" y="724" width="874" height="300">
      <div xmlns="http://www.w3.org/1999/xhtml" style="font-family: Inter, sans-serif; font-weight: semibold; display: flex; flex-direction: column; text-align: left; align-items: start; justify-content: center; height: 100%;">
        <div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
          <div xmlns="http://www.w3.org/1999/xhtml" style="color: black; font-size: 72px;">
            $${Number(product.price || 0).toFixed(2)}
          </div>
          <div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; align-items: center;">
            <svg width="100" height="97" viewBox="0 0 100 97" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-yellow-500 w-12 h-12"><g filter="url(#filter0_d_375_376)"><path d="M49.3224 33.0571C49.6588 32.2219 50.8412 32.2219 51.1776 33.0571L57.2288 48.0819C57.2623 48.1652 57.3069 48.2435 57.3614 48.3149L67.1806 61.1845C67.7221 61.8943 67.1329 62.904 66.2485 62.7817L50.3871 60.5869C50.2961 60.5743 50.2039 60.5743 50.1129 60.5869L34.2515 62.7817C33.3671 62.904 32.7779 61.8943 33.3194 61.1845L43.1386 48.3149C43.1931 48.2435 43.2377 48.1652 43.2712 48.0819L49.3224 33.0571Z" fill="#FFB800"></path></g><defs></defs></svg>
            <div xmlns="http://www.w3.org/1999/xhtml" style="color: black; font-size: 48px;">
              ${Number(product.rating || 0).toFixed(1)}
            </div>
          </div>
        </div>
        <div xmlns="http://www.w3.org/1999/xhtml" style="color: black; font-size: 48px;">
          ${title}
        </div>
      </div>
    </foreignObject>
    </svg>`;
};

export interface RichProductsRenderTaskOptions extends RenderTaskOptions {
  clusterContext: ClusterContext;
  camera: OrbitCamera;
  stateManager: AppState;
  textureCache: DynamicTextureLoader;
  rl: RenderLoop;
}

export class RichProductsRenderTask extends RenderTask {
  clusterContext: ClusterContext;
  camera: OrbitCamera;
  modelMatrices: mat4[];
  modelMatrices2: mat4[];
  stateManager: AppState;
  boxes: Box[];
  textTextures: Map<string, Texture> = new Map();
  imageTextures: Map<string, Texture> = new Map();
  svgs: Map<string, string> = new Map();
  textureCache: DynamicTextureLoader;
  rl: RenderLoop;
  unbindCameraOnUpdate: () => void;
  isFavorites: boolean;

  constructor(
    gl: WebGL2RenderingContext,
    options: RichProductsRenderTaskOptions
  ) {
    super(gl, options);
    this.clusterContext = options.clusterContext;
    this.camera = options.camera;
    this.stateManager = options.stateManager;
    this.boxes = this.clusterContext.cluster.ids.concat([]);
    this.textureCache = options.textureCache;
    this.rl = options.rl;
    this.isFavorites = this.clusterContext.sectorIndex === 999;

    this.modelMatrices = new Array(RICH_PRODUCT_COUNT)
      .fill(null)
      .map(() => mat4.create());
    this.modelMatrices2 = new Array(RICH_PRODUCT_COUNT)
      .fill(null)
      .map(() => mat4.create());

    this.unbindCameraOnUpdate = this.camera.on("update", camera => {
      if (!this.active()) return;
      const boxes = this.boxes.sort((a, b) => {
        // sort by distance to camera
        const aspect = window.innerHeight / window.innerWidth;
        const ad =
          ((a.x - this.camera.position[0]) * aspect) ** 2 +
          (a.y - this.camera.position[2]) ** 2;
        const bd =
          ((b.x - this.camera.position[0]) * aspect) ** 2 +
          (b.y - this.camera.position[2]) ** 2;
        return ad - bd;
      });

      const max = Math.min(boxes.length, RICH_PRODUCT_COUNT);
      for (let i = 0; i < max; i++) {
        rotationTranslationScaleMatrix(
          this.modelMatrices[i],
          [PI / 2, 0, 0],
          [boxes[i].x, boxes[i].h + 0.6, boxes[i].y],
          [-boxes[i].w + BOX_MARGIN, boxes[i].l - BOX_MARGIN, 1]
        );
        rotationTranslationScaleMatrix(
          this.modelMatrices2[i],
          [PI / 2, 0, 0],
          [boxes[i].x, boxes[i].h + 0.3, boxes[i].y],
          [-boxes[i].w + BOX_MARGIN, boxes[i].l - BOX_MARGIN, 1]
        );
      }
      return;
    });
  }

  active(): boolean {
    if (this.isFavorites) {
      return (
        this.clusterContext.clusterIndex === 0 &&
        this.clusterContext.sectorIndex === 999 &&
        this.camera.transitionValue === 1
      );
    } else {
      return (
        (this.clusterContext.clusterIndex ===
          this.stateManager.state.clusterId ||
          this.clusterContext.clusterIndex === 0) &&
        (this.stateManager.state.sectorId === this.clusterContext.sectorIndex ||
          this.clusterContext.sectorIndex === 999) &&
        this.camera.transitionValue === 1
      );
    }
  }

  render(): void {
    if (!this.active()) return;
    super.render();
    const gl = this.gl;
    const max = Math.min(this.boxes.length, RICH_PRODUCT_COUNT);

    for (let i = 0; i < max; i++) {
      if (!this.boxes[i].image_id) continue;
      const image_id = this.boxes[i].image_id!;
      const product = this.boxes[i];

      // Handle text textures
      const textTexture = this.textTextures.get(image_id);
      if (!textTexture) {
        const imageUrl = this.isFavorites
          ? this.stateManager.getProductImageURL(image_id)
          : this.stateManager.getImageURL(image_id);

        if (this.svgs.has(image_id)) {
          this.textureCache
            .loadSVG(`svg-${imageUrl}`, this.svgs.get(image_id)!)
            .then(texture => {
              this.textTextures.set(image_id, texture);
            });
        } else {
          this.svgs.set(image_id, productToSvg(product));
        }
        continue;
      }

      // Handle image textures
      const imageTexture = this.imageTextures.get(image_id);
      if (!imageTexture) {
        const imageUrl = this.isFavorites
          ? this.stateManager.getProductImageURL(image_id)
          : this.stateManager.getImageURL(image_id);

        this.textureCache
          .loadURL(imageUrl)
          .then(texture => {
            this.imageTextures.set(image_id, texture);
          })
          .catch(() => {
            // Load fallback texture on error (only for favorites)
            if (this.isFavorites) {
              this.textureCache
                .loadURL(
                  "https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg"
                )
                .then(texture => {
                  this.imageTextures.set(image_id, texture);
                });
            }
          });
      } else {
        this.geometries.roundedQuad.drawCall(
          this.shaderPrograms.textureRenderer,
          {
            projectionMatrix: this.camera.projectionMatrix,
            viewMatrix: this.camera.viewMatrix,
            modelMatrix: this.modelMatrices2[i],
            u_texture: imageTexture,
            transparency: 0,
          }
        );
      }

      this.geometries.roundedQuad.drawCall(
        this.shaderPrograms.textureRenderer,
        {
          projectionMatrix: this.camera.projectionMatrix,
          viewMatrix: this.camera.viewMatrix,
          modelMatrix: this.modelMatrices[i],
          u_texture: textTexture,
          transparency: 0,
        }
      );
    }
  }

  free(): void {
    this.unbindCameraOnUpdate();
    super.free();
  }
}
