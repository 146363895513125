export const depthFragmentShader = `
    precision mediump float;
    varying vec4 v_lightSpacePosition;
    varying vec2 v_instanceColor;

    void main() {
        vec3 lsp = v_lightSpacePosition.xyz / v_lightSpacePosition.w * 0.5 + 0.5;
        gl_FragColor = vec4(0., v_instanceColor, 1. - lsp.z);
    }
`;
