import { useState } from 'react';
import { useFetch } from "hooks/fetch";
import { useNavigate, useSearchParams } from "react-router-dom";
import { globalState } from 'features/global-state/state-manager';

// const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL;
// const BACKEND_SERVER_URL = 'http://100.123.239.23'

const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchData, setSearchData] = useState(null);
  const navigate = useNavigate();
  const { data: categories } = useFetch("/data/categories.json");

  const removeSearchParam = (param:string) => {
    const params = new URLSearchParams(searchParams);
    params.delete(param);
    setSearchParams(params);
  };

  const handleSearch = async (endpoint: string, options: RequestInit,  successfulSearch: () => void) => {
    try {
      const response = await fetch(`${endpoint}`, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
    //   setSearchData(data);
      globalState.setSearchData(data);
      successfulSearch();

      const findCatalog = () => {
        if (!categories || !data || data.length === 0) return;
        const firstSearch = data[0]
        const catalogName = firstSearch.catalog;
        const categoryKey = Object.keys(categories).find(
          key => categories[key].setName === catalogName
        );
      
        if (categoryKey) {
          navigate(`/${categoryKey}/${firstSearch.sector}/${firstSearch.cluster}/?product=${firstSearch.article}`);
        } else {
          console.error("Category not found for catalog:", catalogName);
        }
      };
      findCatalog();
    } catch (error) {
      console.error('Error:', error);
      removeSearchParam('search')
    }
  };

  return { searchData, handleSearch };
};

export default useSearch;