import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { GLContext } from "features/draw-call/core/gl-utils";
import {
  NestedRenderTaskOptions,
  NestedRenderTask,
} from "features/draw-call/core/render-task/nested-render-task";
import { UniformValues } from "features/draw-call/core/types";
import { vec3, mat4 } from "gl-matrix";

export interface ApplyTransformsOptions extends NestedRenderTaskOptions {
  translation?: vec3;
  rotation?: vec3;
  scale?: vec3;
}

export class ApplyTransforms extends NestedRenderTask {
  modelMatrix: mat4;
  _modelMatrix: mat4;
  translation: vec3;
  rotation: vec3;
  scale: vec3;
  constructor(gl: GLContext, options?: ApplyTransformsOptions) {
    super(gl, options);
    this.modelMatrix = mat4.identity(mat4.create());
    this._modelMatrix = mat4.create();
    this.translation = options?.translation || vec3.create();
    this.rotation = options?.rotation || vec3.create();
    this.scale = options?.scale || vec3.fromValues(1, 1, 1);
    this.updateMatrix();
  }
  updateMatrix() {
    rotationTranslationScaleMatrix(
      this.modelMatrix,
      this.rotation,
      this.translation,
      this.scale
    );
  }
  render(u?: UniformValues): void {
    super.render();
    if (u?.modelMatrix) {
      mat4.multiply(this._modelMatrix, u.modelMatrix as mat4, this.modelMatrix);
    } else {
      mat4.copy(this._modelMatrix, this.modelMatrix);
    }
    this.renderChildren({ ...u, modelMatrix: this._modelMatrix });
  }
}
