import React, { useRef, useEffect, useMemo } from "react";
import { vec3 } from "gl-matrix";
import { useDC, useFrame } from "features/draw-call/tsx/canvas";
import { useCamera, useSharedShaderProgram } from "../scene-context";
import { SVGRenderer } from "../render-tasks/svg-renderer";
import { ApplyTransforms } from "../render-tasks/apply-transforms";
import { FaceCamera } from "../render-tasks/face-camera";
import { DistanceEmerge } from "../render-tasks/distance-emerge";
import { useCluster } from "./sectors";
import { globalState } from "features/global-state/state-manager";

import {
  PROMO_PRICE_HEIGHT,
  PROMO_PRICE_MIN_DISTANCE,
  PROMO_PRICE_MAX_DISTANCE,
  PROMO_PRICE_SCALE,
  FADE_DURATION,
} from "config";

export const promoPriceSvg = (text: string) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="1024" height="1024">
  <style>
   <![CDATA[
      div.block{
        position: relative;
        font-family: Inter, sans-serif; 
        display: flex; 
        flex-direction: column; 
        gap: 20px;
        text-align: center;
        align-items: center;
        justify-content: space-around;
        height: 10%;
        font-size: 18px;
        padding: 40px;
        font-size: 2.2rem;
        font-weight: 700;
      }

      div.header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 180px;
        font-weight: bold;
      }

      div.header div{
        background-color: #D5F55C;
        border-radius: 20px;
        width: 30%;
        height: 100%;
        text-align: center;
      }

      div.average{
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #C7C2DC;
        padding: 20px;
        border-radius: 20px;
      }

      div.average div:not(:first-child) {
        position: relative;
        left: -20px;
      }

      div.main-column{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 15px;
      }

      div.column{
        display: flex;
        flex-direction: column;
        gap: 5px; 
        background-color: #826AED;
        padding: 15px; 
        border-radius: 20px; 
        color: white;
      }

      div.current{
        display: flex; 
        justify-content: space-between; 
        width: 100%;
        background-color: #D5F55C;
        padding: 10px;
        border-radius: 20px;
      }
    ]]>
  </style>

  <rect stroke="#ffffff" fill="none" stroke-width="18" rx="100" id="svg_1" height="1000" width="1000" y="6" x="12"/>

  <defs>
    <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" stop-color="#DCD5FB" />
      <stop offset="100%" stop-color="#EAE6FD" />
    </linearGradient>
  </defs>
  <rect stroke="none" fill="url(#grad)" stroke-width="0" rx="100" id="svg_1" height="900" width="880" y="48" x="72"/>

  <foreignObject x="75" y="50" width="874" height="100%">
    <div xmlns="http://www.w3.org/1999/xhtml" class="block">
      <!-- Title Row -->
      <div class="header">
        <div><p>1000 views</p></div>
        <div><p>CPM, $</p></div>
        <div><p>CTR, %</p></div>
      </div>
      
      <!-- Average Row -->
      <div class="average">
        <div>Average</div>
        <div>4.98$</div>
        <div>9.9%</div>
      </div>
      
      <!-- History Row -->
      <div style="display: flex; flex-direction: column; gap: 10px; width: 100%;">
        <div style="display: flex; justify-content: space-between;">
          <div class="main-column"><p>History</p></div>
          <div class="column">
            <div>5.20</div>
            <div>5.10</div>
            <div>4.90</div>
            <div>4.85</div>
            <div>4.85</div>
          </div>
          <div class="column">
            <div>9.0%</div>
            <div>11.2%</div>
            <div>9.8%</div>
            <div>16.1%</div>
            <div>3.3%</div>
          </div>
        </div>
      </div>
      
      <!-- Current Deal Row -->
      <div class="current">
        <div>Current deal</div>
        <div>5.00</div>
        <div style="width: 24%;"> </div>
      </div>
      
      <!-- Offers Row -->
      <div style="display: flex; flex-direction: column; gap: 10px; width: 100%;">
        <div style="display: flex; justify-content: space-between;">
          <div class="main-column">Offers</div>
          <div class="column">
            <div>5.20</div>
            <div>5.10</div>
            <div>4.90</div>
            <div>4.85</div>
            <div>4.85</div>
          </div>

          <div style="width: 14%;"> </div>

        </div>
      </div>
    </div>
  </foreignObject>

  </svg>`;
};

// export const lineSvg = () => {
//   return `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
//     <line x1="0" y1="10" x2="100" y2="10" stroke="white" />
//   </svg>`;
// };

export const lineSvg = (
  startX: number,
  startY: number,
  endX: number,
  endY: number
) => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <line x1="${startX}" y1="${startY}" x2="${endX}" y2="${endY}" stroke="white" stroke-width="100" />
    </svg>
  `;
};

export const PromoPrice: React.FC = () => {
  const { cluster } = useCluster();
  // return early on empty cluster
  if (cluster.ids.length === 0) return null;
  return <UnsafePromoPrice />;
};

export const UnsafePromoPrice: React.FC = () => {
  const { gl, rl } = useDC();
  const { cluster } = useCluster();
  const camera = useCamera();
  const program = useSharedShaderProgram("textureRenderer");
  const layersModes: string[] = globalState.state.layersModes;

  const renderTaskRef = useRef<DistanceEmerge | null>(null);
  const svgRendererRef = useRef<SVGRenderer | null>(null);
  const lineRendererRef = useRef<SVGRenderer | null>(null);
  const box = cluster.ids[0];
  const translation = useMemo(
    () => vec3.fromValues(box.x, PROMO_PRICE_HEIGHT, box.y),
    []
  );

  // const lineSvgContent = useMemo(() => lineSvg(50, 0, 50, 50), []); // Vertical Line Center SVG

  useEffect(() => {
    if (window.location.search.includes("PROMO_PRICE")) {
      svgRendererRef.current = new SVGRenderer(gl, {
        svg: promoPriceSvg("text"),
        camera,
        program,
      });

      lineRendererRef.current = new SVGRenderer(gl, {
        svg: lineSvg(50, 0, 50, 50),
        camera,
        program,
      });

      renderTaskRef.current = new DistanceEmerge(gl, {
        camera,
        rl,
        position: translation,
        min: PROMO_PRICE_MIN_DISTANCE,
        max: PROMO_PRICE_MAX_DISTANCE,
        duration: FADE_DURATION,
        children: [
          new ApplyTransforms(gl, {
            translation,
            children: [
              new FaceCamera(gl, {
                camera,
                children: [
                  new ApplyTransforms(gl, {
                    scale: PROMO_PRICE_SCALE,
                    children: [svgRendererRef.current],
                  }),
                ],
              }),

              new ApplyTransforms(gl, {
                translation: vec3.fromValues(0, -PROMO_PRICE_HEIGHT / 1.5, 0),
                scale: vec3.fromValues(0.05, PROMO_PRICE_HEIGHT / 3, 0),
                children: [lineRendererRef.current],
              }),
            ],
          }),
        ],
      });
    } else {
      // del renderTask и svgRenderer
      if (renderTaskRef.current) {
        renderTaskRef.current.free();
      }
      if (svgRendererRef.current) {
        svgRendererRef.current.free();
      }
      if (lineRendererRef.current) {
        lineRendererRef.current.free();
      }
      renderTaskRef.current = null;
      svgRendererRef.current = null;
      lineRendererRef.current = null;
    }
    return () => {
      // Cleaning up resources when unmounting a component
      if (renderTaskRef.current) {
        renderTaskRef.current.free();
      }
      if (svgRendererRef.current) {
        svgRendererRef.current.free();
      }
      if (lineRendererRef.current) {
        lineRendererRef.current.free();
      }
    };
  }, [
    gl,
    rl,
    camera,
    program,
    box.x,
    box.y,
    layersModes,
    window.location.search,
  ]);

  useFrame(() => {
    if (renderTaskRef.current) {
      renderTaskRef.current.render();
    }
  });

  return null;
};
