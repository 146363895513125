//!HEXAGON animation!
// export const GBFragmentShader300: string = `#version 300 es
//   precision highp float;
//   uniform float time;
//   uniform vec3 color_g_b_0;
//   uniform vec3 color_g_b_1;
//   uniform float u_scale;

//   uniform float cellSize;
//   uniform float lineWidth;
//   uniform float speed_g_b;

//   in vec2 v_uv;
  
//   out vec4 outColor;

//   #ifdef FLAT_TOP_HEXAGON
//   const vec2 s = vec2(1.7320508, 1);
//   #else
//   const vec2 s = vec2(1, 1.7320508);
//   #endif

//   float hash21(vec2 p) {
//     return fract(sin(dot(p, vec2(141.13, 289.97))) * 4378.5453);
//   }

//   float hex(in vec2 p) {    
//     p = abs(p);

//     #ifdef FLAT_TOP_HEXAGON
//     return max(dot(p, s * 0.5), p.y);
//     #else
//     return max(dot(p, s * 0.5), p.x);
//     #endif    
//   }

//   vec4 getHex(vec2 p) {    
//     #ifdef FLAT_TOP_HEXAGON
//     vec4 hC = floor(vec4(p, p - vec2(1, 0.5)) / s.xyxy) + 0.5;
//     #else
//     vec4 hC = floor(vec4(p, p - vec2(0.5, 1)) / s.xyxy) + 0.5;
//     #endif
    
//     vec4 h = vec4(p - hC.xy * s, p - (hC.zw + 0.5) * s);
    
//     return dot(h.xy, h.xy) < dot(h.zw, h.zw) 
//       ? vec4(h.xy, hC.xy) 
//       : vec4(h.zw, hC.zw + 0.5);
//   }

//   // // Function to generate random color based on position
//   // vec3 randomColor(vec2 position) {
//   //   // float r = hash21(position + 0.1);
//   //   // float g = hash21(position + 0.2);
//   //   // float b = hash21(position + 0.3);
//   //   // return vec3(r, g, b);

//   //   vec3 colors[2];
//   //   colors[0] = color_g_b_0;
//   //   colors[1] = color_g_b_1;
//   //   // colors[2] = vec3(1.0, 1.0, 1.0);
    
//   //   int index = int(hash21(position) * 3.0); // Select color index based on hash
//   //   return colors[index];
//   // }

//   // Noise Perlin
//   vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
//   vec4 mod289(vec4 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
//   vec4 permute(vec4 x) { return mod289(((x*34.0)+1.0)*x); }
//   vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
//   vec3 fade(vec3 t) { return t*t*t*(t*(t*6.0-15.0)+10.0); }
//   float noiseP(vec3 P) {
//     vec3 i0 = mod289(floor(P)), i1 = mod289(i0 + vec3(1.0));
//     vec3 f0 = fract(P), f1 = f0 - vec3(1.0), f = fade(f0);
//     vec4 ix = vec4(i0.x, i1.x, i0.x, i1.x), iy = vec4(i0.yy, i1.yy);
//     vec4 iz0 = i0.zzzz, iz1 = i1.zzzz;
//     vec4 ixy = permute(permute(ix) + iy), ixy0 = permute(ixy + iz0), ixy1 = permute(ixy + iz1);
//     vec4 gx0 = ixy0 * (1.0 / 7.0), gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
//     vec4 gx1 = ixy1 * (1.0 / 7.0), gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
//     gx0 = fract(gx0); gx1 = fract(gx1);
//     vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0), sz0 = step(gz0, vec4(0.0));
//     vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1), sz1 = step(gz1, vec4(0.0));
//     gx0 -= sz0 * (step(0.0, gx0) - 0.5); gy0 -= sz0 * (step(0.0, gy0) - 0.5);
//     gx1 -= sz1 * (step(0.0, gx1) - 0.5); gy1 -= sz1 * (step(0.0, gy1) - 0.5);
//     vec3 g0 = vec3(gx0.x,gy0.x,gz0.x), g1 = vec3(gx0.y,gy0.y,gz0.y),
//       g2 = vec3(gx0.z,gy0.z,gz0.z), g3 = vec3(gx0.w,gy0.w,gz0.w),
//       g4 = vec3(gx1.x,gy1.x,gz1.x), g5 = vec3(gx1.y,gy1.y,gz1.y),
//       g6 = vec3(gx1.z,gy1.z,gz1.z), g7 = vec3(gx1.w,gy1.w,gz1.w);
//     vec4 norm0 = taylorInvSqrt(vec4(dot(g0,g0), dot(g2,g2), dot(g1,g1), dot(g3,g3)));
//     vec4 norm1 = taylorInvSqrt(vec4(dot(g4,g4), dot(g6,g6), dot(g5,g5), dot(g7,g7)));
//     g0 *= norm0.x; g2 *= norm0.y; g1 *= norm0.z; g3 *= norm0.w;
//     g4 *= norm1.x; g6 *= norm1.y; g5 *= norm1.z; g7 *= norm1.w;
//     vec4 nz = mix(vec4(dot(g0, vec3(f0.x, f0.y, f0.z)), dot(g1, vec3(f1.x, f0.y, f0.z)),
//       dot(g2, vec3(f0.x, f1.y, f0.z)), dot(g3, vec3(f1.x, f1.y, f0.z))),
//       vec4(dot(g4, vec3(f0.x, f0.y, f1.z)), dot(g5, vec3(f1.x, f0.y, f1.z)),
//         dot(g6, vec3(f0.x, f1.y, f1.z)), dot(g7, vec3(f1.x, f1.y, f1.z))), f.z);
//     return 2.2 * mix(mix(nz.x,nz.z,f.y), mix(nz.y,nz.w,f.y), f.x);
//   }
//   float noiseP(vec2 P) { return noiseP(vec3(P, 0.0)); }

//   void main() {
//     vec2 u = (v_uv - 0.5) * 2.0; // Centering UV coordinates

//     float transition = smoothstep(0.0, 0.2, v_uv.y) * smoothstep(1.0, 0.8, v_uv.y);

//     vec2 offset = vec2(
//         sin(v_uv.y * 10.0 + time * speed_g_b) * 0.05 * transition, // X offset
//         cos(v_uv.x * 10.0 + time * speed_g_b) * 0.025 * transition // Y offset
//     );

//     // Correct periodic UV mapping
//     vec2 periodicUV = fract(u * u_scale + offset);

//     vec4 h = getHex(periodicUV * cellSize); // Use cellSize for hex pattern scale
//     float eDist = hex(h.xy);

//     // Noise Hexagon color
//     vec3 color;
//     float x = v_uv.x;
//     float y = v_uv.y;

//     // Увеличиваем яркость цветов для линий, но не так сильно
//     vec3 brightColor1 = color_g_b_0 * 1.8;
//     vec3 brightColor2 = color_g_b_1 * 1.8;

//     // Генерируем более тонкие анимированные блики для фона
//     float backgroundNoise = noiseP(vec2(x * 5.0 + time * speed_g_b * 0.5, y * 5.0 + time * speed_g_b * 0.5));
//     vec3 backgroundColor = mix(vec3(0.20, 0.20, 0.22), vec3(0.23, 0.23, 0.25), backgroundNoise);
    
//     // Уменьшаем интенсивность бликов на фоне
//     float highlights = pow(noiseP(vec2(x * 8.0 + time * speed_g_b, y * 8.0 + time * speed_g_b)), 4.0);
//     backgroundColor += vec3(highlights) * 0.08;

//     // Анимация для линий сетки
//     vec3 lineColor = mix(brightColor1, brightColor2, 
//         pow(noiseP(vec2(x * 15.0 + time * speed_g_b, y * 15.0 + time * speed_g_b)), 2.0));

//     // Настраиваем толщину линий
//     float edgeWidth = 0.08; // Уменьшаем толщину линий
//     float gridPattern = smoothstep(0.0, edgeWidth, abs(eDist - 0.5));

//     // Смешиваем цвета фона и линий, делая линии более заметными
//     vec3 finalColor = mix(lineColor, backgroundColor, gridPattern);

//     // Добавляем более тонкий bloom эффект
//     float bloom = pow(noiseP(vec2(x * 3.0 + time * speed_g_b * 0.3, y * 3.0 + time * speed_g_b * 0.3)), 3.0);
//     finalColor += vec3(bloom) * 0.05;

//     outColor = vec4(finalColor, transition);
//   }
// `;

export const GBFragmentShader300: string = `#version 300 es
  precision highp float;
  uniform float time;
  uniform vec3 brightColor;
  uniform vec3 darkColor;
  uniform vec3 backgroundColor; 
  uniform float u_scale;

  uniform float cellSize;
  uniform float lineWidth;
  uniform float speed_g_b;

  in vec2 v_uv;
  
  out vec4 outColor;

  #ifdef FLAT_TOP_HEXAGON
  const vec2 s = vec2(1.7320508, 1);
  #else
  const vec2 s = vec2(1, 1.7320508);
  #endif

  float hash21(vec2 p) {
    return fract(sin(dot(p, vec2(141.13, 289.97))) * 4378.5453);
  }

  float hex(in vec2 p) {    
    p = abs(p);

    #ifdef FLAT_TOP_HEXAGON
    return max(dot(p, s * 0.5), p.y);
    #else
    return max(dot(p, s * 0.5), p.x);
    #endif    
  }

  vec4 getHex(vec2 p) {    
    #ifdef FLAT_TOP_HEXAGON
    vec4 hC = floor(vec4(p, p - vec2(1, 0.5)) / s.xyxy) + 0.5;
    #else
    vec4 hC = floor(vec4(p, p - vec2(0.5, 1)) / s.xyxy) + 0.5;
    #endif
    
    vec4 h = vec4(p - hC.xy * s, p - (hC.zw + 0.5) * s);
    
    return dot(h.xy, h.xy) < dot(h.zw, h.zw) 
      ? vec4(h.xy, hC.xy) 
      : vec4(h.zw, hC.zw + 0.5);
  }

  vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec4 mod289(vec4 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec4 permute(vec4 x) { return mod289(((x*34.0)+1.0)*x); }
  vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
  vec3 fade(vec3 t) { return t*t*t*(t*(t*6.0-15.0)+10.0); }
  float noiseP(vec3 P) {
    vec3 i0 = mod289(floor(P)), i1 = mod289(i0 + vec3(1.0));
    vec3 f0 = fract(P), f1 = f0 - vec3(1.0), f = fade(f0);
    vec4 ix = vec4(i0.x, i1.x, i0.x, i1.x), iy = vec4(i0.yy, i1.yy);
    vec4 iz0 = i0.zzzz, iz1 = i1.zzzz;
    vec4 ixy = permute(permute(ix) + iy), ixy0 = permute(ixy + iz0), ixy1 = permute(ixy + iz1);
    vec4 gx0 = ixy0 * (1.0 / 7.0), gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    vec4 gx1 = ixy1 * (1.0 / 7.0), gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0); gx1 = fract(gx1);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0), sz0 = step(gz0, vec4(0.0));
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1), sz1 = step(gz1, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5); gy0 -= sz0 * (step(0.0, gy0) - 0.5);
    gx1 -= sz1 * (step(0.0, gx1) - 0.5); gy1 -= sz1 * (step(0.0, gy1) - 0.5);
    vec3 g0 = vec3(gx0.x,gy0.x,gz0.x), g1 = vec3(gx0.y,gy0.y,gz0.y),
      g2 = vec3(gx0.z,gy0.z,gz0.z), g3 = vec3(gx0.w,gy0.w,gz0.w),
      g4 = vec3(gx1.x,gy1.x,gz1.x), g5 = vec3(gx1.y,gy1.y,gz1.y),
      g6 = vec3(gx1.z,gy1.z,gz1.z), g7 = vec3(gx1.w,gy1.w,gz1.w);
    vec4 norm0 = taylorInvSqrt(vec4(dot(g0,g0), dot(g2,g2), dot(g1,g1), dot(g3,g3)));
    vec4 norm1 = taylorInvSqrt(vec4(dot(g4,g4), dot(g6,g6), dot(g5,g5), dot(g7,g7)));
    g0 *= norm0.x; g2 *= norm0.y; g1 *= norm0.z; g3 *= norm0.w;
    g4 *= norm1.x; g6 *= norm1.y; g5 *= norm1.z; g7 *= norm1.w;
    vec4 nz = mix(vec4(dot(g0, vec3(f0.x, f0.y, f0.z)), dot(g1, vec3(f1.x, f0.y, f0.z)),
      dot(g2, vec3(f0.x, f1.y, f0.z)), dot(g3, vec3(f1.x, f1.y, f0.z))),
      vec4(dot(g4, vec3(f0.x, f0.y, f1.z)), dot(g5, vec3(f1.x, f0.y, f1.z)),
        dot(g6, vec3(f0.x, f1.y, f1.z)), dot(g7, vec3(f1.x, f1.y, f1.z))), f.z);
    return 2.2 * mix(mix(nz.x,nz.z,f.y), mix(nz.y,nz.w,f.y), f.x);
  }
  float noiseP(vec2 P) { return noiseP(vec3(P, 0.0)); }

  void main() {
    vec2 u = (vec2(v_uv.x * 6.0, v_uv.y) - 0.5) * 2.0;

    float transition = smoothstep(0.0, 0.2, v_uv.y) * smoothstep(1.0, 0.8, v_uv.y);

    vec2 offset = vec2(
        sin(v_uv.y * 10.0 + time * speed_g_b) * 0.05 * transition,
        cos(v_uv.x * 10.0 + time * speed_g_b) * 0.025 * transition
    );

    vec2 periodicUV = fract(u * u_scale + offset);

    vec4 h = getHex(periodicUV * cellSize);
    float eDist = hex(h.xy);

    float x = v_uv.x;
    float y = v_uv.y;

    // Determining the exact colors
    // vec3 brightColor = vec3(214.0, 214.0, 214.0) / 255.0;  // #D6D6D6
    // vec3 darkColor = vec3(99.0, 99.0, 101.0) / 255.0;      // #636365
    // vec3 backgroundColor = vec3(44.0, 43.0, 47.0) / 255.0; // #2C2B2F

    // Generate noise for animation
    float noise = noiseP(vec2(x * 15.0 + time * speed_g_b, y * 15.0 + time * speed_g_b));
    
    // Mix dark and bright colors for line animation
    vec3 lineColor = mix(darkColor * 0.8, brightColor, noise);

    // Adjust line thickness
    float edgeWidth = 0.05;
    float gridPattern = smoothstep(0.0, edgeWidth, abs(eDist - 0.5));

    // Mix line and background colors
    vec3 finalColor = mix(lineColor, backgroundColor, gridPattern);

    // Add a very light bloom effect
    float bloom = pow(noiseP(vec2(x * 3.0 + time * speed_g_b * 0.3, y * 3.0 + time * speed_g_b * 0.3)), 3.0);
    finalColor += vec3(bloom) * 0.02;

    outColor = vec4(finalColor, transition);
  }

`;



// void main() {
//   vec2 u = (v_uv - 0.5) * 2.0; // Центрирование UV координат

//   // Расчет плавного перехода на основе координаты y
//   float transition = smoothstep(0.0, 0.2, v_uv.y) * smoothstep(1.0, 0.8, v_uv.y);

//   // Корректировка масштаба и смещения для волнового эффекта
//   vec2 offset = vec2(
//       sin(v_uv.y * 10.0 + time * speed_g_b) * 0.05 * transition, // X смещение
//       cos(v_uv.x * 10.0 + time * speed_g_b) * 0.025 * transition  // Y смещение
//   );

//   // Корректировка периодических UV координат
//   vec2 periodicUV = fract(u * u_scale + offset);

//   vec4 h = getHex(periodicUV * cellSize); // Используем cellSize для масштаба гексагонального узора
//   float eDist = hex(h.xy);

//   // Цвета для анимации гексагонов
//   vec3 color;
//   float x = v_uv.x;
//   float y = v_uv.y;

//   vec3 dimColor = color_g_b_0 * 0.6;
//   vec3 brightColor = color_g_b_1 * 2.0;
//   color = mix(dimColor, brightColor, pow(noiseP(vec2(x * 21. + time * speed_g_b, y * 7. + time * speed_g_b)), 2.0));
//   vec3 animatedColor = mix(dimColor, brightColor, pow(noiseP(vec2(x * 10.5 + time * speed_g_b, y * 7.5 + time * speed_g_b)), 2.0));

//   vec3 backgroundColor = vec3(0.2, 0.2, 0.2);
//   vec3 col = mix(backgroundColor, animatedColor, smoothstep(0.0, lineWidth, eDist - 0.5 + 0.04));

//   // Применяем переход для плавного исчезновения
//   outColor = vec4(col, transition);
// }

//*dark gray
// void main() {
//   vec2 u = (v_uv - 0.5) * 2.0; // Centering UV coordinates

//   // Calculate smooth transition factor based on y coordinate
//   float transition = smoothstep(0.0, 0.2, v_uv.y) * smoothstep(1.0, 0.8, v_uv.y);

//   // Adjust the scale and offset for the wave effect
//   vec2 offset = vec2(
//       sin(v_uv.y * 10.0 + time * speed_g_b) * 0.05 * transition, // X offset
//       cos(v_uv.x * 10.0 + time * speed_g_b) * 0.025 * transition  // Y offset
//   );

//   // Correct periodic UV mapping
//   vec2 periodicUV = fract(u * u_scale + offset);

//   vec4 h = getHex(periodicUV * cellSize); // Use cellSize for hex pattern scale
//   float eDist = hex(h.xy);

//   // Noise Hexagon color
//   vec3 color;
//   float x = v_uv.x;
//   float y = v_uv.y;

//   // Decrease brightness color_g_b_0
//   vec3 dimColor = color_g_b_0 * 0.6;

//   // Increase brightness color_g_b_1
//   vec3 brightColor = color_g_b_1 * 2.0;

//   // Mix colors based on brightness
//   color = mix(dimColor, brightColor, pow(noiseP(vec2(x * 21. + time * speed_g_b, y * 7. + time * speed_g_b)), 2.0));
//   vec3 animatedColor = mix(dimColor, brightColor, pow(noiseP(vec2(x * 10.5 + time * speed_g_b, y * 7.5 + time * speed_g_b)), 2.0));

//   // Gray color background
//   vec3 backgroundColor = vec3(0.20, 0.20, 0.22);

//   // Mix background color with animated color
//   vec3 col = mix(backgroundColor, animatedColor, smoothstep(0.0, lineWidth, eDist - 0.5 + 0.04));

//   // Apply the transition factor for smooth fading
//   outColor = vec4(col, transition);
// }