import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { GLContext } from "features/draw-call/core/gl-utils";
import { RenderLoop } from "features/draw-call/core/render-loop";
import {
  NestedRenderTaskOptions,
  NestedRenderTask,
} from "features/draw-call/core/render-task/nested-render-task";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { vec3, mat4 } from "gl-matrix";
import { clamp, invlerp } from "math";

// this nested render task makes children gradually fade in and out on certain distance to camera

export interface DistanceEmergeOptions extends NestedRenderTaskOptions {
  min?: number;
  max?: number;
  position: vec3;
  camera: OrbitCamera;
  duration?: number;
  rl: RenderLoop;
}

export class DistanceEmerge extends NestedRenderTask {
  min: number;
  max: number;
  camera: OrbitCamera;
  position: vec3;
  duration: number;
  rl: RenderLoop;
  inside: boolean;
  state: number;
  private unbindCameraUpdate: () => void;
  constructor(gl: GLContext, options: DistanceEmergeOptions) {
    super(gl, options);
    this.min = options.min || 0;
    this.max = options.max ?? +Infinity;
    this.position = options.position;
    this.camera = options.camera;
    this.duration = options.duration ?? 0;
    this.rl = options.rl;
    const distance = vec3.dist(this.camera.position, this.position);
    this.inside = distance >= this.min && distance <= this.max;
    this.state = Number(!this.inside);
    this.unbindCameraUpdate = this.camera.on(
      "update",
      this.onCameraUpdate.bind(this)
    );
  }
  onCameraUpdate() {
    const distance = vec3.dist(this.camera.position, this.position);
    if (this.inside && (distance < this.min || distance > this.max)) {
      this.inside = false;
    } else if (!this.inside && distance >= this.min && distance <= this.max) {
      this.inside = true;
    }
  }
  render(u?: UniformValues): void {
    if (this.state === 1 && !this.inside) return;
    super.render();
    this.state = clamp(
      this.state -
        ((2 * Number(this.inside) - 1) * this.rl.delta) / this.duration
    );
    this.renderChildren({
      ...u,
      transparency: this.state,
    });
  }
  free(): void {
    super.free();
    this.unbindCameraUpdate();
  }
}
