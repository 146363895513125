export const clusterThumbnailsFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D u_texture;
uniform float transparency;

void main() {
    vec4 color = texture(u_texture, v_uv);
    FragColor = vec4(color.xyz, color. a * (1.0 - transparency));
}
`;

export const loadingThumbnailsFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D u_texture;
uniform float transparency;
uniform mat2 rotationMatrix;

void main() {
    vec2 st = rotationMatrix * (v_uv - .5) + .5;
    vec4 color = texture(u_texture, st);
    FragColor = vec4(color.xyz, color.a * (1.0 - transparency));
}
`;
