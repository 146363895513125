import { mat4, vec3, vec4 } from "gl-matrix";
import { clamp, PI, rad } from "./math";
import { createNanoEvents, Emitter } from "nanoevents";
import { NDC, unproject, worldCoords } from "math/raycast";
import { Handle, physics, PhysicsModule } from "features/physics";
import { mat4Array } from "../core/geometry/array-transforms";
import {
  CAMERA_2D_THRESHOLD,
  CAMERA_ANIMATION_ANGULAR_SPEED,
  CAMERA_ANIMATION_SPEED,
  CAMERA_FAR,
  CAMERA_NEAR,
  CLUSTER_NEAR,
  MODE_2D_NEAR,
  SCENE_FAR,
} from "config";
import Hammer from "hammerjs";
import { lerp } from "math";
import { RenderLoop } from "../core/render-loop";

export interface OrbitCameraOptions {
  projectionMatrix: mat4;
  position?: vec3;
  target?: vec3;
  minDistance?: number;
  maxDistance?: number;
  el?: HTMLCanvasElement | OffscreenCanvas;
  physics?: PhysicsModule;
  rl?: RenderLoop;
}

export const UP = vec3.fromValues(0, 1, 0);
export const CAMERA_SPEED = 1 / Math.max(window.innerHeight, window.innerWidth);

const _tmp = vec3.create();

// possible events
interface Events {
  update: (camera: OrbitCamera) => void;
  wheel: (camera: OrbitCamera) => void;
  click: (worldCoordinates: vec3) => void;
  clickPhysics: (handle: Handle | undefined) => void;
  pointerdown: (worldCoordinates: vec3) => void;
  pointermove: (worldCoordinates: vec3) => void;
  pointermovePhysics: (handle: Handle | undefined) => void;
}

// a camera is just a set of two matrices (view and projection) plus input handlers
// this class has evolved into something resembling the MapCamera from THREE.js
export class OrbitCamera {
  emitter: Emitter = createNanoEvents();
  angleX: number = -Math.PI / 2 - Math.PI / 12;
  angleY: number = Math.PI / 6;
  radius: number;
  target: vec3;
  position: vec3;
  viewMatrix: mat4;
  projectionMatrix: mat4;
  maxDistance: number;
  minDistance: number;
  pointerDown = false;
  rmbDown = false;
  pointerMoved = false; // set if pointer mover during click
  controlledAngleX = false;
  el: HTMLCanvasElement | OffscreenCanvas | Window;
  rl?: RenderLoop;

  // 2d mode function
  useCameraTransition = true;
  transitionValue = 0;
  orthoR = 0;
  physics: PhysicsModule;

  constructor(options?: OrbitCameraOptions) {
    this.position = vec3.create();
    this.target = vec3.create();
    this.viewMatrix = mat4.create();
    this.projectionMatrix = options?.projectionMatrix || mat4.create();
    this.position = options?.position || vec3.fromValues(0, 0, 0);
    this.target = options?.target || vec3.create();
    this.el = options?.el || window;
    this.physics = options?.physics || physics;
    this.rl = options?.rl;

    vec3.sub(_tmp, this.target, this.position);
    this.radius = vec3.length(_tmp);

    // Calculate the radius, angleX and angleY based on the given position.
    this.calculateInitialAngles();

    this.maxDistance = options?.maxDistance || SCENE_FAR;
    this.minDistance = options?.minDistance || MODE_2D_NEAR;

    this.init();
  }

  private calculateInitialAngles() {
    vec3.sub(_tmp, this.position, this.target);
    this.radius = vec3.length(_tmp);

    this.angleY = Math.asin(_tmp[1] / this.radius);
    this.angleX = Math.atan2(_tmp[2], _tmp[0]) - Math.PI / 2;
  }

  private updateAngles(x: number, y: number) {
    this.angleX = (this.angleX + rad(x * 0.3)) % (Math.PI * 2);
    this.angleY = clamp(
      this.angleY + rad(y * 0.3),
      -Math.PI / 2 + 0.001,
      Math.PI / 2 - 0.001
    );
  }
  private init() {
    this.el.addEventListener("wheel", evt => {
      const e = evt as WheelEvent;
      const delta = e.deltaY;
      this.radius = clamp(
        this.radius + delta * 0.03,
        this.minDistance,
        this.maxDistance
      );
      this.emitter.emit("wheel", this);
    });
    if (this.el instanceof HTMLCanvasElement) {
      const hammer = new Hammer(this.el);
      hammer.get("pinch").set({ enable: true });
      hammer.get("pan").set({ enable: true, direction: Hammer.DIRECTION_ALL });
      // hammer.get("tap").set({ enable: true });
      hammer.get("swipe").set({ enable: false });
      hammer.on("pinch", ev => {
        // Simulate wheel event for pinch zoom
        const wheelEvent = new WheelEvent("wheel", {
          deltaY: ev.scale > 1 ? -50 : 50, // Pinch out (zoom in) or pinch in (zoom out)
          bubbles: true,
          cancelable: true,
        });
        this.el.dispatchEvent(wheelEvent);
      });
      // Detect tap for mouse click emulation
      /*hammer.on("tap", ev => {
        const clickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          clientX: ev.center.x,
          clientY: ev.center.y,
        });
        this.el.dispatchEvent(clickEvent);
      });*/

      // Detect pan for mouse down, move, and up emulation
      hammer.on("panstart", ev => {
        if (ev.pointerType === "mouse") return;
        const mouseDownEvent = new MouseEvent("mousedown", {
          bubbles: true,
          cancelable: true,
          clientX: ev.center.x,
          clientY: ev.center.y,
          button: 0,
        });
        this.el.dispatchEvent(mouseDownEvent);

        this.pointerMoved = false;
      });

      hammer.on("panmove", ev => {
        if (ev.pointerType === "mouse") return;
        const mouseMoveEvent = new MouseEvent("mousemove", {
          bubbles: true,
          cancelable: true,
          clientX: ev.center.x,
          clientY: ev.center.y,
          movementX: ev.velocityX * 5 * window.devicePixelRatio,
          movementY: ev.velocityY * 5 * window.devicePixelRatio,
          button: 2,
        });
        this.el.dispatchEvent(mouseMoveEvent);
      });

      hammer.on("panend", ev => {
        if (ev.pointerType === "mouse") return;
        const mouseUpEvent = new MouseEvent("mouseup", {
          bubbles: true,
          cancelable: true,
          clientX: ev.center.x,
          clientY: ev.center.y,
          button: 2,
        });
        this.el.dispatchEvent(mouseUpEvent);
      });
    }

    // prevent context menu
    this.el.addEventListener("contextmenu", evt => {
      const e = evt as MouseEvent;
      if (!e.ctrlKey) {
        e.preventDefault();
      }
    });
    this.el.addEventListener("mousedown", evt => {
      const e = evt as MouseEvent;

      if (e.button === 0) {
        this.rmbDown = true;
      } else if (e.button === 2) {
        this.pointerDown = true;
        this.controlledAngleX = true;
      }
      this.emitter.emit(
        "pointerdown",
        this.getEventCoords(e.clientX, e.clientY)
      );
      this.pointerMoved = false;
    });
    this.el.addEventListener("mousemove", evt => {
      const e = evt as MouseEvent;
      const x = e.movementX;
      const y = e.movementY;
      if (this.pointerDown) {
        this.updateAngles(x, y);
      }
      this.pointerMoved = true;
      if (this.rmbDown) {
        const dx = x * CAMERA_SPEED * this.position[1];
        const dy = y * CAMERA_SPEED * this.position[1];
        this.target[2] +=
          dx * Math.cos(this.angleX) - dy * Math.sin(this.angleX);
        this.target[0] -=
          dx * Math.sin(this.angleX) + dy * Math.cos(this.angleX);
      }
      this.emitter.emit(
        "pointermove",
        this.getEventCoords(e.clientX, e.clientY)
      );
      this.emitter.emit(
        "pointermovePhysics",
        this.getEventPhysicsObject(e.clientX, e.clientY)
      );
    });
    this.el.addEventListener("mouseup", e => {
      this.pointerDown = false;
      this.rmbDown = false;
    });
    this.el.addEventListener("click", evt => {
      const e = evt as MouseEvent;
      // don't emit click if pointer moved
      // because we want our clicks to be true clicks
      if (this.pointerMoved) return;
      this.emitter.emit("click", this.getEventCoords(e.clientX, e.clientY));
      this.emitter.emit(
        "clickPhysics",
        this.getEventPhysicsObject(e.clientX, e.clientY)
      );
    });
    this.updateAngles(0, 0);
    this.update();
  }
  getEventCoords(x: number, y: number) {
    const el = this.el;
    // @ts-expect-error
    const width = el.width || el.innerWidth;
    // @ts-expect-error
    const height = el.height || el.innerHeight;
    const ndc = NDC(x, y, width, height);
    return worldCoords(ndc, this.viewMatrix, this.projectionMatrix);
  }
  getEventPhysicsObject(x: number, y: number) {
    const el = this.el;
    // @ts-expect-error
    const width = el.width || el.innerWidth;
    // @ts-expect-error
    const height = el.height || el.innerHeight;
    const ndc = NDC(x, y, width, height);
    if (this.transitionValue > 0 && this.orthoR > 0) {
      const r = this.orthoR;
      const aspect = width / height;
      const position = vec3.fromValues(
        ndc[0],
        ndc[1],
        (CAMERA_NEAR + CAMERA_FAR) / (CAMERA_NEAR - CAMERA_FAR)
      );
      unproject(position, this.projectionMatrix, this.viewMatrix);
      const direction = vec3.fromValues(0, 0, -1);
      mat4Array(direction as Float32Array, 0, 1, this.viewMatrix, 0);
      vec3.normalize(direction, direction);
      vec3.scale(direction, direction, CAMERA_FAR - CAMERA_NEAR);
      vec3.add(direction, position, direction);
      return physics.castRay(position, direction);
    } else
      return physics.castRay(
        this.position,
        worldCoords(ndc, this.viewMatrix, this.projectionMatrix)
      );
  }
  on<E extends keyof Events>(event: E, callback: Events[E]) {
    return this.emitter.on(event, callback);
  }
  update() {
    this.position[1] = this.target[1] + this.radius * Math.sin(this.angleY);
    this.position[0] =
      this.target[0] +
      this.radius * Math.cos(this.angleY) * Math.cos(this.angleX);
    this.position[2] =
      this.target[2] +
      this.radius * Math.cos(this.angleY) * Math.sin(this.angleX);

    //! Prohibit falling under the stage
    if (this.position[1] < 1) {
      this.position[1] = 1; //! Y axis block
    }

    mat4.lookAt(this.viewMatrix, this.position, this.target, UP);
    this.emitter.emit("update", this);
    if (
      this.useCameraTransition &&
      this.angleY >= PI / 2 - CAMERA_2D_THRESHOLD
    ) {
      this.transitionValue = clamp(
        (this.angleY - (PI / 2 - CAMERA_2D_THRESHOLD)) / CAMERA_2D_THRESHOLD +
          0.07
      );
    } else {
      this.transitionValue = 0;
    }
  }

  getFrustumPlanes() {
    const planes = [];
    const vpMatrix = mat4.multiply(
      mat4.create(),
      this.projectionMatrix,
      this.viewMatrix
    );

    for (let i = 0; i < 6; i++) {
      planes[i] = vec4.create();
    }

    // Left
    planes[0][0] = vpMatrix[3] + vpMatrix[0];
    planes[0][1] = vpMatrix[7] + vpMatrix[4];
    planes[0][2] = vpMatrix[11] + vpMatrix[8];
    planes[0][3] = vpMatrix[15] + vpMatrix[12];

    // Right
    planes[1][0] = vpMatrix[3] - vpMatrix[0];
    planes[1][1] = vpMatrix[7] - vpMatrix[4];
    planes[1][2] = vpMatrix[11] - vpMatrix[8];
    planes[1][3] = vpMatrix[15] - vpMatrix[12];

    // Bottom
    planes[2][0] = vpMatrix[3] + vpMatrix[1];
    planes[2][1] = vpMatrix[7] + vpMatrix[5];
    planes[2][2] = vpMatrix[11] + vpMatrix[9];
    planes[2][3] = vpMatrix[15] + vpMatrix[13];

    // Top
    planes[3][0] = vpMatrix[3] - vpMatrix[1];
    planes[3][1] = vpMatrix[7] - vpMatrix[5];
    planes[3][2] = vpMatrix[11] - vpMatrix[9];
    planes[3][3] = vpMatrix[15] - vpMatrix[13];

    // Near
    planes[4][0] = vpMatrix[3] + vpMatrix[2];
    planes[4][1] = vpMatrix[7] + vpMatrix[6];
    planes[4][2] = vpMatrix[11] + vpMatrix[10];
    planes[4][3] = vpMatrix[15] + vpMatrix[14];

    // Far
    planes[5][0] = vpMatrix[3] - vpMatrix[2];
    planes[5][1] = vpMatrix[7] - vpMatrix[6];
    planes[5][2] = vpMatrix[11] - vpMatrix[10];
    planes[5][3] = vpMatrix[15] - vpMatrix[14];

    for (let i = 0; i < 6; i++) {
      const length = Math.sqrt(
        planes[i][0] * planes[i][0] +
          planes[i][1] * planes[i][1] +
          planes[i][2] * planes[i][2]
      );
      planes[i][0] /= length;
      planes[i][1] /= length;
      planes[i][2] /= length;
      planes[i][3] /= length;
    }

    return planes;
  }
}
