import React, { FC, ChangeEvent, KeyboardEvent } from "react";
import classNames from "classnames";
import { InputSearch } from "./input-search";
import { ReactComponent as IrbiIcon } from "../icons/irbi.svg";
import "./style.css";

interface SearchModalProps {
  isOpen: boolean;
  value: string;
  onClose: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchModal: FC<SearchModalProps> = ({
  isOpen,
  value,
  onChange,
  // onKeyPress,
  onClose,
}) => {
  return (
    <div
      style={{ position: "absolute" }}
      className={classNames(
        "flex items-center justify-center min-h-screen bg-gray-100",
        {
          "-translate-x-full": !isOpen,
          "translate-x-0": isOpen,
        },
        "w-screen"
      )}
    >
      <div className="text-center w-full relative">
        {/* <h1 className="text-4xl font-bold font-inter text-[96px] italic mb-8 font-ultrabold">
          IRBI<span className="accent-text">.</span>
        </h1> */}
        <div
          className="text-center glowing-svg grow w-full h-24 grid place-items-center absolute bottom-36 flex"
          style={{ background: "transparent" }}
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="240px"
            viewBox="0 0 800 400"
            preserveAspectRatio="xMidYMid meet"
            className="glowing-svg"
          >
            <g
              transform="translate(0, 400) scale(0.05, -0.05)"
              fill="#826AED"
              stroke="none"
            >
              <path
                d="M14935 7191 c-275 -38 -513 -72 -530 -76 -140 -35 -275 -166 -325
              -314 -19 -58 -26 -194 -115 -2189 -52 -1170 -95 -2168 -95 -2219 0 -115 23
              -184 83 -245 48 -50 95 -75 170 -90 46 -9 117 -2 587 61 516 69 537 73 605
              105 85 40 150 97 201 172 77 117 65 -49 170 2309 52 1171 94 2175 92 2230 -2
              80 -8 109 -26 146 -49 100 -146 165 -258 174 -40 3 -220 -18 -559 -64z"
              />
              <path
                d="M11910 6758 c-52 -5 -787 -101 -1633 -214 -1120 -149 -1553 -210
              -1592 -225 -135 -50 -256 -185 -294 -325 -13 -48 -204 -4274 -199 -4409 2 -71
              8 -100 27 -139 48 -96 138 -160 243 -172 39 -4 475 51 1633 205 869 116 1645
              223 1725 237 506 91 854 232 1058 427 190 183 352 581 407 1000 20 153 20 417
              0 533 -41 235 -140 427 -294 565 -151 137 -329 199 -569 199 -63 0 -113 2
              -110 5 3 3 42 15 87 25 316 76 522 240 675 538 198 387 240 844 111 1202 -55
              152 -162 330 -231 386 -101 80 -244 134 -422 158 -125 18 -459 20 -622 4z
              m-502 -1258 c65 -29 85 -82 90 -232 7 -194 -37 -353 -116 -418 -20 -17 -66
              -40 -102 -50 -94 -28 -1026 -151 -1070 -141 -61 14 -98 40 -126 87 l-27 46 6
              186 c3 103 12 204 18 224 18 56 79 128 134 159 47 26 85 33 525 93 523 71 602
              76 668 46z m66 -1748 c56 -25 81 -76 97 -197 14 -107 6 -331 -16 -460 -31
              -178 -79 -238 -225 -281 -26 -8 -300 -48 -611 -90 -491 -66 -570 -74 -608 -65
              -60 16 -97 45 -123 94 -20 40 -21 50 -14 260 11 320 20 416 45 465 28 55 84
              110 136 133 28 13 206 40 566 88 288 38 538 72 554 75 55 8 155 -3 199 -22z"
              />
              <path
                d="M6547 6130 c-136 -12 -3258 -427 -3312 -441 -171 -45 -318 -209 -345
              -385 -6 -38 -53 -1041 -105 -2229 -70 -1607 -92 -2171 -85 -2203 22 -94 82
              -172 163 -214 77 -39 146 -36 687 37 272 36 513 71 535 77 72 19 139 60 205
              127 71 72 115 150 131 234 5 28 23 346 38 705 l29 653 26 54 c30 60 80 109
              141 136 40 18 361 67 371 57 3 -3 182 -352 399 -774 217 -423 409 -789 427
              -814 46 -62 129 -103 217 -108 81 -4 1084 129 1151 153 66 23 134 70 190 132
              116 127 156 299 100 428 -9 22 -177 315 -373 652 -196 336 -358 617 -359 623
              -2 6 35 27 81 46 110 44 248 121 336 187 255 190 469 544 565 939 54 222 65
              318 65 598 0 291 -17 419 -81 622 -95 298 -234 492 -423 592 -185 97 -475 140
              -774 116z m-611 -1297 c55 -29 97 -79 125 -150 21 -52 23 -74 23 -218 0 -171
              -11 -239 -59 -342 -52 -114 -134 -182 -266 -221 -76 -22 -933 -142 -1017 -142
              -88 0 -157 54 -182 140 -12 40 15 602 31 652 20 62 79 130 139 160 49 25 102
              34 495 87 360 49 461 59 555 58 95 -2 122 -6 156 -24z"
              />
              <path
                d="M1402 5450 c-488 -66 -506 -70 -577 -103 -138 -66 -244 -204 -266
              -348 -14 -92 -201 -4376 -193 -4424 16 -98 78 -181 168 -225 52 -26 74 -31
              132 -31 78 0 1030 125 1084 143 169 53 305 210 331 380 6 35 53 1035 105 2223
              72 1652 92 2171 84 2207 -20 97 -80 173 -170 219 -78 39 -145 35 -698 -41z"
              />
            </g>
          </svg>
        </div>
        {/* <div className="text-center w-full grid place-items-center absolute bottom-10 flex">
          <IrbiIcon className="w-1/4 mb-20" />
        </div> */}
        <div className="text-center w-full grid place-items-center absolute flex">
          <InputSearch
            glow={true}
            type="search"
            value={value}
            onChange={onChange}
            // onKeyPress={onKeyPress}
            onClose={onClose}
            isOpen={isOpen}
          />
        </div>
      </div>
    </div>
  );
};
