// import React from 'react';
// import ReactDOM from 'react-dom';

// export const ProductModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
//   if (!isOpen) return null;

//   return ReactDOM.createPortal(
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={onClose}>
//       <div className="relative bg-white p-6 h-[80%] rounded-lg max-w-3xl w-full mx-4 h-full" onClick={(e) => e.stopPropagation()}>
//         <button
//           onClick={onClose}
//           className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full text-gray-500 hover:text-gray-700 hover:bg-gray-300"
//         >
//           &times;
//         </button>

//         <iframe
//           src="https://www.youtube.com/"
//           className="w-full h-full"
//         ></iframe>

//       </div>
//     </div>,
//     document.body
//   );
// };

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

interface ProductModalProps {
  market?: string;
  asin?: string;
  // youtubeUrl?: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export const ProductModal: React.FC<ProductModalProps> = ({
  isOpen,
  onClose,
  market,
  asin,
}) => {
  const [videoUrl, setVideoUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const updateVideoUrl = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const videoId = searchParams.get("frame");

      if (videoId) {
        setVideoUrl(
          `https://www.youtube.com/embed/${videoId}?autoplay=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&modestbranding=1&widgetid=1&origin=${window.location.origin}`
        );
      } else {
        setVideoUrl("");
      }
    };

    if (isOpen) {
      updateVideoUrl();
    }

    window.addEventListener("popstate", updateVideoUrl);
    return () => {
      window.removeEventListener("popstate", updateVideoUrl);
      setVideoUrl(""); // Очистка URL при размонтировании
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    return () => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("frame");
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
      setVideoUrl("");
    };
  }, [isOpen, navigate]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="relative bg-white p-4 rounded-lg w-full max-w-3xl mx-4 h-[80%] sm:h-full lg:h-[80%]"
        // style={{
        //   height: '80%', // высота по умолчанию
        //   maxHeight: 'calc(100vh - 40px)', // ограничение по высоте для больших экранов
        //   ...(window.innerWidth < 640 ? { height: '100%' } : {}) // на мобильных – полная высота
        // }}
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={() => {
            setVideoUrl("");
            onClose();
          }}
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center 
            bg-gray-200 rounded-full text-gray-500 hover:text-gray-700 hover:bg-gray-300"
        >
          &times;
        </button>

        {market && asin ? (
          <iframe
            src={`https://www.${market}.com/dp/${asin}`}
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
        ) : (
          videoUrl && (
            <iframe
              src={videoUrl}
              className="w-full h-full"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          )
        )}
      </div>
    </div>,
    document.body
  );
};
