// a tiny math library. consists mostly of js implementations of common glsl functions
export function clamp(value: number, min: number = 0, max: number = 1) {
  return Math.min(Math.max(value, min), max);
}

// linear interpolation
export function mix(x: number, y: number, a: number) {
  return x * (1 - a) + y * a;
}

export function smoothstep(edge0: number, edge1: number, x: number) {
  const t = Math.min(Math.max((x - edge0) / (edge1 - edge0), 0.0), 1.0);
  return t * t * (3.0 - 2.0 * t);
}

// convert degrees to radians
export const rad = (deg: number) => (deg / 180) * Math.PI;

// pure convenience
export const PI = Math.PI;
