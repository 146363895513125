import {
  clusterImagePath,
  MATCAP_MAX,
  MAX_LIGHT_INTENSITY,
  MIN_LIGHT_INTENSITY,
  SHADOW_COLOR,
  SHADOW_INTENSITY,
} from "config";
import { ElementsBufferGeometry } from "features/draw-call/core/geometry/elements-buffer-geometry";
import { elementsToInstanced } from "features/draw-call/core/geometry/instanced-elements";
import { RenderLoop } from "features/draw-call/core/render-loop";
import { RenderTask } from "features/draw-call/core/render-task/render-task";
import { ShaderProgram } from "features/draw-call/core/shader-program/shader-program";
import { Texture } from "features/draw-call/core/texture";
import {
  VertexBufferCollection,
  UniformValues,
} from "features/draw-call/core/types";
import { VertexBuffer } from "features/draw-call/core/vertex-buffer";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { DynamicTextureLoader } from "features/resource-loader/dynamic-texture-loader";
import { Cluster } from "features/global-state/app-context";
import { mat2, mat4, vec3 } from "gl-matrix";
import { PI } from "math";
import { BOX_MARGIN, BOX_GROW_TIME } from "config";
import { AppState } from "features/global-state/state-manager";
import { getClusterLightData, intColorTo3Floats } from "./cluster";
import { isClusterActive } from "./app-state-emerge";

export interface ClusterTopsOptions {
  camera: OrbitCamera;
  cluster: Cluster;
  clusterIndex: number;
  sectorIndex: number;
  sharedGeometry: ElementsBufferGeometry;
  loadingProgram: ShaderProgram;
  mainProgram: ShaderProgram;
  instancedShadowsProgram: ShaderProgram;
  textureCache: DynamicTextureLoader;
  rl: RenderLoop;
  stateManager: AppState;
}

// temp mem
const _tmp2 = vec3.create();

export class ClusterTops extends RenderTask {
  instanceBuffers: VertexBufferCollection;
  camera: OrbitCamera;
  cluster: Cluster;
  center: vec3;
  N: number;
  textureCache: DynamicTextureLoader;
  textures: (Texture | null)[] = [];
  mainTexture: Texture | null = null;
  rl: RenderLoop;
  rotationMatrix: mat2 = mat2.create();
  clusterIndex: number;
  sectorIndex: number;
  boxes: { x: number; y: number; l: number; h: number; w: number; image_id?: string }[];
  startTime: number;
  stateManager: AppState;
  penumbraMap?: Texture;
  lightProjection: mat4;
  lightView: mat4;
  isFavorites: boolean;

  constructor(gl: WebGL2RenderingContext, options: ClusterTopsOptions) {
    super(gl);
    this.geometries.shared = options.sharedGeometry;
    this.camera = options.camera;
    this.cluster = options.cluster;
    this.center = vec3.fromValues(this.cluster.x, 0, this.cluster.y);
    this.textureCache = options.textureCache;
    this.rl = options.rl;
    this.clusterIndex = options.clusterIndex;
    this.sectorIndex = options.sectorIndex;
    this.startTime = this.rl.time;
    this.stateManager = options.stateManager;
    this.isFavorites = this.sectorIndex === 999;

    // set light view and projection matrices
    const { lightProjection, lightView } = getClusterLightData(this.cluster);
    this.lightProjection = lightProjection;
    this.lightView = lightView;

    this.boxes = options.cluster.ids;
    const instanceCount = this.boxes.length;
    const instancePos = new Float32Array(instanceCount * 3);
    const instanceScale = new Float32Array(instanceCount * 3);
    const instanceColor = new Float32Array(instanceCount * 2);
    for (let i = 0; i < instanceCount; i++) {
      const index = i * 3;
      instancePos[index] = this.boxes[i].x;
      instancePos[index + 1] = 0; // Starting height
      instancePos[index + 2] = this.boxes[i].y;
      instanceScale[index] = this.boxes[i].l - BOX_MARGIN;
      instanceScale[index + 1] = this.boxes[i].h; // Final height
      instanceScale[index + 2] = -this.boxes[i].w + BOX_MARGIN;

      intColorTo3Floats(_tmp2, ~~((i / instanceCount) * 0xffff));
      const index2 = i * 2;
      instanceColor[index2] = _tmp2[1];
      instanceColor[index2 + 1] = _tmp2[2];
    }
    const N = Math.trunc(Math.sqrt(instanceCount)) + 1; // number of boxes in a grid row
    this.N = N;
    const instanceUV = new Float32Array(instanceCount * 2);
    for (let i = 0; i < N; i++) {
      for (let j = 0; j < N && i * N + j < instanceCount; j++) {
        const index = i * N + j;
        const index2 = index * 2;
        // test and possibly fix to 1 - i / N
        instanceUV[index2 + 1] = i / N;
        instanceUV[index2] = j / N;
      }
    }
    this.instanceBuffers = {
      INSTANCE_POSITION: new VertexBuffer(gl, { data: instancePos, size: 3 }),
      INSTANCE_SCALE: new VertexBuffer(gl, {
        data: instanceScale,
        size: 3,
      }),
      INSTANCE_COLOR: new VertexBuffer(gl, {
        data: instanceColor,
        size: 2,
      }),
      INSTANCE_UV: new VertexBuffer(gl, {
        data: instanceUV,
        size: 2,
      }),
    };
    this.geometries.main = elementsToInstanced(
      this.geometries.shared as ElementsBufferGeometry,
      {
        instanceCount,
        instanceBuffers: this.instanceBuffers,
      }
    );
    this.shaderPrograms.main = options.mainProgram;
    this.shaderPrograms.loading = options.loadingProgram;
    this.shaderPrograms.instancedShadows = options.instancedShadowsProgram;
  }
  create(): void {
    super.create();
    // load texture
    if (this.isFavorites) {
      // Load individual textures for favorites
      this.textures = new Array(this.boxes.length).fill(null);
      this.boxes.forEach((box, index) => {
        if (box.image_id) {
          // const imageUrl = this.stateManager.getImageURL(box.image_id);
          const imageUrl = this.stateManager.getProductImageURL(box.image_id);
          console.log("imageUrl", imageUrl)
          this.textureCache
            .loadURL(imageUrl)
            .then(texture => {
              this.textures[index] = texture;
            })
            .catch(() => {
              // Load fallback texture on error
              this.textureCache
                .loadURL("https://t3.ftcdn.net/jpg/03/34/83/22/360_F_334832255_IMxvzYRygjd20VlSaIAFZrQWjozQH6BQ.jpg")
                .then(texture => {
                  this.textures[index] = texture;
                });
            });
        }
      });
    } else {
      // Load single cluster texture
      this.textureCache
        .loadURL(
          clusterImagePath(
            this.stateManager.state.sceneId || "default",
            `${this.sectorIndex}_${this.clusterIndex}`
          )
        )
        .then(texture => (this.mainTexture = texture));
    }
  }
  render(x?: UniformValues): void {
    super.render();
    if (!this.created) return;
    const gl = this.gl;

    const elapsedTime = this.rl.time - this.startTime;
    const animationProgress = Math.min(1, elapsedTime / BOX_GROW_TIME);
    const instancePosition = this.instanceBuffers.INSTANCE_POSITION;
    gl.bindBuffer(gl.ARRAY_BUFFER, instancePosition.glBuffer);
    const posData = new Float32Array(this.boxes.length * 3);
    for (let i = 0; i < this.boxes.length; i++) {
      posData[i * 3 + 0] = this.boxes[i].x;
      posData[i * 3 + 1] = this.boxes[i].h * animationProgress;
      posData[i * 3 + 2] = this.boxes[i].y;
    }
    gl.bufferSubData(gl.ARRAY_BUFFER, 0, posData);

    mat2.fromRotation(this.rotationMatrix, this.rl.time / 1000);
    const uniforms = {
      projectionMatrix: this.camera.projectionMatrix,
      viewMatrix: this.camera.viewMatrix,
      N: this.N,
      time: this.rl.time,
      rotationMatrix: this.rotationMatrix,
      ...x,
    };

    if (!isClusterActive(this.stateManager.state, this.clusterIndex, this.sectorIndex)) {
      return;
    }

    if (this.isFavorites) {
      // Render individual boxes with their textures
      this.boxes.forEach((_, index) => {
        const texture = this.textures[index];
        if (texture) {
          // Modify instanceUV for individual texture
          const instanceUV = this.instanceBuffers.INSTANCE_UV;
          gl.bindBuffer(gl.ARRAY_BUFFER, instanceUV.glBuffer);
          const uvData = new Float32Array(2);
          uvData[0] = 0;
          uvData[1] = 0;
          gl.bufferSubData(gl.ARRAY_BUFFER, index * 8, uvData);

          this.geometries.main.drawCall(this.shaderPrograms.main, {
            u_texture: texture,
            ...uniforms,
          });
        } else {
          this.geometries.main.drawCall(this.shaderPrograms.loading, uniforms);
        }
      });
    } else {
      // Render with single cluster texture
      if (this.mainTexture) {
        this.geometries.main.drawCall(this.shaderPrograms.main, {
          u_texture: this.mainTexture,
          ...uniforms,
        });
      } else {
        this.geometries.main.drawCall(this.shaderPrograms.loading, uniforms);
      }
    }
  }
}