import { rotationTranslationScaleMatrix } from "features/draw-call/core/geometry/array-transforms";
import { GLContext } from "features/draw-call/core/gl-utils";
import { RenderLoop } from "features/draw-call/core/render-loop";
import {
  NestedRenderTaskOptions,
  NestedRenderTask,
} from "features/draw-call/core/render-task/nested-render-task";
import { UniformValues } from "features/draw-call/core/types";
import { OrbitCamera } from "features/draw-call/ext/orbit-camera";
import { vec3, mat4 } from "gl-matrix";
import { clamp, invlerp } from "math";

// this nested render task makes children gradually fade in and out when the `visible` property changes

export interface ConditionalEmergeOptions extends NestedRenderTaskOptions {
  visible?: boolean;
  duration?: number;
  rl: RenderLoop;
  dontSkipInvisibleRender?: boolean;
}

export class ConditionalEmerge extends NestedRenderTask {
  visible: boolean;
  rl: RenderLoop;
  duration: number;
  state: number;
  dontSkipInvisibleRender: boolean;
  constructor(gl: GLContext, options: ConditionalEmergeOptions) {
    super(gl, options);
    this.duration = options.duration ?? 0;
    this.rl = options.rl;
    this.visible = options.visible ?? true;
    this.dontSkipInvisibleRender = options.dontSkipInvisibleRender ?? false;
    this.state = Number(!this.visible);
  }
  render(u?: UniformValues): void {
    if (this.state === 1 && !this.visible && !this.dontSkipInvisibleRender)
      return;
    super.render();
    this.state = clamp(
      this.state -
        ((2 * Number(this.visible) - 1) * this.rl.delta) / this.duration
    );
    this.renderChildren({
      ...u,
      transparency: this.state,
    });
  }
  free(): void {
    super.free();
  }
}
