export const textureFragmentShader = `
precision highp float;

varying vec2 v_uv;
uniform sampler2D texture;

void main() {
    gl_FragColor = texture2D(texture, v_uv);
}
`;

export const textureFragmentShader300 = `#version 300 es
precision highp float;

in vec2 v_uv;

out vec4 FragColor;

uniform sampler2D u_texture;
uniform float transparency;

void main() {

    vec4 color = texture(u_texture, v_uv);
    color.rgb /= color.a; // undo alpha premultiplication
    color.a = color.a * (1.0 - transparency); // set transparency
    FragColor = color;
}
`;
